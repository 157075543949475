import React, {useEffect} from "react";
import Header from '../component/common/header';
import Footer from '../component/common/footer';
import Club from "../component/clubs/club";

const ClubPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return (
        <>
            <Header />
            <Club />
            <Footer />
        </>
    )
}

export default ClubPage;