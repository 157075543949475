import React, { useEffect, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation , useNavigate } from "react-router-dom";


const AddMembers = (props) => {
    const navigate =useNavigate();
    const location = useLocation();
    console.log(location && location ,'locaton');

    const [formdata , setFormdata] = useState({
        name :  '',
        designation : '',
        description : '',
        role : ''  ,
        id          : ''
    })
    const [img, setImg] = useState();
    const [error , setError] = useState();

    const handleimg = (e) => {
        console.log();
        setImg(e.target.files[0])
        console.log(e.target.files[0],'sdsd');
    }

    const handlechange = (e) => {
        
        setFormdata({
            ...formdata,
            [e.target.name] : e.target.value
        })
    }

    const hanldeSubmit = () => {

        if(formdata.name == ''){
            setError({
                name : 'Name Cannot be Empty'
            })
            return;
        }
        if(formdata.designation == ''){
            setError({
                designation : 'Designation Cannot be Empty'
            })
            return;
        }
        if(formdata.description == ''){
            setError({
                description : 'Description Cannot be Empty'
            })
            return;
        }
        if(formdata.role == ''){
            setError({
                role : 'Role Cannot be Empty'
            })
            return;
        }

        const formData2 = new FormData();
            formData2.append('name' , formdata.name);
            {
                formdata.id && formData2.append('id' , formdata.id)
            }
            formData2.append('designation' , formdata.designation);
            formData2.append('description' , formdata.description);
            formData2.append('role' , formdata.role);
            formData2.append('img' , img);


            axios.post(`${process.env.REACT_APP_MAIN_API}/editMembers`, formData2)
            .then(res => {
                console.log(res,'check res');
                toast.success(`Member ${location.state ? 'Edited' : 'Added'} succesfully`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }).catch(err => {
                console.log(err,'check res');
            })
    }

    useEffect(() => {
        setFormdata({
            ...formdata,
            name : location.state?.name,
            designation : location.state?.designation,
            description : location.state?.description,
            role : location.state?.role,
            id      : location.state?.id
        })
        console.log(location.state ,'formdata');
    },[location])

    return (
        <>
            <ToastContainer />
            <section  className="eventForm">
                <div className="container">
                    <h3 className="text-center"> {location.state ? 'Edit' : 'Add'} Members</h3>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="php-email-form">
                                {/* **************up*************** */}
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <label>Name of Member</label><br />
                                        <input type="text" className="form-control" name="name" onChange={handlechange}  value={formdata.name}/>
                                         {   error?.name ? <small className="text-danger">{error.name}</small> : ''}
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>What is his/her Role ?</label><br />
                                        <input type="text" className="form-control" name="designation" onChange={handlechange} value={formdata.designation} />
                                        {   error?.designation ? <small className="text-danger">{error.designation}</small> : ''}
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Picture of his/her ? ?</label><br />
                                        <input type="file" className="form-control" name="image" onChange={handleimg}  />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Role</label><br />
                                            <select  name="role" value={formdata.role} onChange={handlechange}>
                                                <option value="">--Select--</option>
                                                <option value="Member">Member</option>
                                                <option value="Core Member">Core Member</option>
                                            </select>
                                        {   error?.role ? <small className="text-danger">{error.role}</small> : ''}
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <label>Any Breif Details of his/her ?</label><br />
                                        <textarea  className="form-control"   rows={5} cols={5}  onChange={handlechange} name="description" value={formdata?.description} /> 
                                        
                                        {   error?.description ? <small className="text-danger">{error.description}</small> : ''}
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-primary   m-auto" onClick={hanldeSubmit}> Submit Form</button>
                                    <button className="btn btn-outline   m-auto" onClick={()=>navigate(-1)}> Goto  Previous Page</button>
                                </div>
                                {/* **************down*************** */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddMembers;