import React from 'react';

import HomePage from '../pages/homePage';
import Aboutpage from '../pages/aboupage';
import BlogPage from '../pages/blogPage';
import BlogSinglePage from '../pages/blogSinglePage';
import RentalPage from '../pages/rentalPage';
import MemberPage from '../pages/memberPage';
import UpcomingEvents from '../component/home/upcomingEvents';
import EventPage from '../pages/eventPage';
import SingleEventPage from '../pages/singleEventPage';
import ContactPage from '../pages/ContactPage';

import Allevents from '../component/admin/event/allevents';

import Dashboard from '../component/admin/dashboard';
import Events from '../component/admin/pages/Events';
import UpdateEvents from '../component/admin/event/updateEvents';
import Media from '../component/admin/pages/Media';
import BlogPanel from '../component/admin/pages/Blogs';
import ResearchPapers from '../component/admin/pages/ResearchPapers';
import Table from '../component/admin/pages/table';
import ViewEvents from '../component/admin/event/ViewEvents';
import AdminRentalPage from '../component/admin/pages/RentalPage';
import RentalRequest from '../component/admin/tables/rentalrequest';
import MembersList from '../component/admin/tables/members';
import AddMembers from '../component/admin/form/addMember';

import BlogTable from '../component/admin/pages/blogTable';
import Welcome from '../component/home/welcome';
import RentSection from '../component/home/rent';
import Volunteer from '../component/home/volunteer';
import ErrorPage from '../pages/errorPage';
import InternTable from '../component/admin/tables/internTable'
import ManageGallery from '../component/admin/tables/manageGallery';
import ContactUsTable from '../component/admin/tables/contactUsTable';
import RegistrationTable from '../component/admin/tables/registrationTable';

import Imagegallery from '../component/gallery/allImages';
import Gallery from '../pages/galleryPage';
import ClubPage from '../pages/ClubPage';
import ResearchDev from '../pages/RdPage';
import RdSinglePage from '../pages/rdSinglePage';
import Login from '../component/admin/form/login';
import Register from '../component/admin/form/register';
import Payment from '../component/admin/event/payment';
import Testimonial from '../component/admin/pages/testimonial';

import DashboardPage from '../component/admin/pages/Dashboard';
import AddTestimonial from '../component/admin/form/addTestimonial';
import PaymentPage from '../pages/paymentPage';

export const PAGES = {
    Home: {
        path: '/',
        Component: HomePage
    },
    Aboutpage: {
        path: '/about',
        Component: Aboutpage
    },
    BlogPage: {
        path: '/blog',
        Component: BlogPage
    },
    BlogSinglePage: {
        path: '/blog/:id',
        Component: BlogSinglePage
    },
    RentalPage: {
        path: '/rental',
        Component: RentalPage
    },
    MemberPage: {
        path: '/member',
        Component: MemberPage
    },
    SingleEventPage: {
        path: '/viewevents/:id',
        Component: SingleEventPage
    },


    EventPage: {
        path: '/events',
        Component: EventPage
    },
    UpcomingEvents: {
        path: '/UpcomingEvents',
        Component: UpcomingEvents
    },

    Welcome: {
        path: '/welcome',
        Component: Welcome
    },

    RentSection: {
        path: '/rent',
        Component: RentSection
    },

    Volunteer: {
        path: '/volunteer',
        Component: Volunteer
    },

    Imagegallery: {
        path: '/images',
        Component: Imagegallery
    },

    Gallery: {
        path: '/gallery',
        Component: Gallery
    },

    ClubPage: {
        path: '/club',
        Component: ClubPage
    },

    ResearchDev: {
        path: '/rd',
        Component: ResearchDev
    },

    RdSinglePage: {
        path: '/rd-single',
        Component: RdSinglePage
    },

    ErrorPage:{
        path:'/error',
        Component:ErrorPage
    },

    ContactPage:{
        path:'/contact',
        Component:ContactPage
    },

    /******************Admin****************** */

    Login: {
        path: 'login',
        Component: Login
    },
    Register: {
        path: 'register',
        Component: Register
    },
    Dashboard: {
        path: 'admin',
        Component: Dashboard
    },
    AddEvents: {
        path: 'events',
        Component: Events
    },
    AddEventsWithId: {
        path: 'addevents/:user_id',
        Component: Events
    },
    EditEventsWithId: {
        path: 'addevents/:edit/:user_id',
        Component: UpdateEvents
    },
    Allevents: {
        path: 'allevents',
        Component: Allevents
    },
    // Media: {
    //     path: 'media',
    //     Component: Media
    // },
    ManageGallery: {
        path: 'manageGallery',
        Component: ManageGallery
    },
    BlogPanel: {
        path: 'blogs',
        Component: BlogPanel
    },
    ResearchPapers: {
        path: 'research',
        Component: ResearchPapers
    },
    Table: {
        path: 'table',
        Component: InternTable
    },
    ContactUsTable: {
        path: 'ContactUsTable',
        Component: ContactUsTable
    },
    RegistrationTable: {
        path: 'RegistrationTable',
        Component: RegistrationTable
    },

    ViewEvents: {
        path: 'viewevent',
        Component: ViewEvents
    },
    BlogTable: {
        path: 'allblog',
        Component: BlogTable
    },

    DashboardPage: {
        path: 'dashboard',
        Component: DashboardPage
    },
    AdminRentalPage:{
        path:'rental',
        Component:AdminRentalPage
    },
    RentalRequest:{
        path:'RentalRequest',
        Component:RentalRequest
    },
    AddMembers : {
        path : 'AddMembers',
        Component : AddMembers
    },
    MembersList : {
        path : 'MembersList',
        Component : MembersList
    },
    Payment : {
        path : 'Payment',
        Component : Payment
    },
    Testimonial : {
        path : 'Testimonial',
        Component : Testimonial
    },
    AddTestimonial : {
        path : 'AddTestimonial',
        Component : AddTestimonial
    },
    PaymentPage : {
        path : 'PaymentPage/:u_id',
        Component : PaymentPage
    },
    reschedule_PaymentPage : {
        path : 'PaymentPage/r/:u_id',
        Component : PaymentPage
    },
}