import React, {useState, useEffect} from "react";
import TextField from '@mui/material/TextField';
import DatePicker from "react-datepicker";

const RentalForm = () => {

    return (
        <>
           <div className="row my-3" data-aos="fade-up">
                <div className="col-lg-12 p-5">
                    <form action="" role="form" className="php-email-form" style={{ 'background': '#ffffff' }}>
                        <div className="row">
                            <div className="col-md-6 mt-3 form-group">
                                <TextField fullWidth id="standard-basic" label="Name" variant="standard" />
                            </div>
                            <div className="col-md-6  mt-3 form-group">
                                <TextField fullWidth id="standard-basic" label="Email" variant="standard" />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6 mt-3 form-group">
                                <TextField fullWidth id="standard-basic" label="Phone No" variant="standard" />
                            </div>
                            <div className="col-md-6 mt-3 form-group">
                                <TextField fullWidth id="standard-basic"  label="Description" variant="standard" />
                            </div>
                            <div className="col-md-12  mt-3 form-group">
                                <label>Purpose Of Renting A Space</label><br /><br />
                                <select name="category">
                                    <option value="1">Theatre/Play</option>
                                    <option value="2">Music & Poetry Nights</option>
                                    <option value="3">Open Mic</option>
                                    <option value="4">Film Screenings </option>
                                    <option value="5">Talk / Lecture Series</option>
                                    <option value="6">Share Circles</option>
                                    <option value="7">Exhibitions & Art Gallery</option>
                                    <option value="8">Book readings </option>
                                    <option value="9">Practise for a play / performanc</option>
                                    <option value="10">Shoot / Document a project </option>
                                    <option value="11">Plan / Brainstorm for a project </option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-md-6  mt-3 form-group">
                                <label>Select A Date</label><br /><br />
                                <DatePicker />
                            </div>

                            <div className="col-md-6 mt-3 form-group">
                                <label>Select a time slot</label> <br /><br />
                                <select name="slot">
                                    <option value="45">45 minutes</option>
                                    <option value="90">90 minutes</option>
                                    <option value="180">180 minutes</option>
                                    <option value="1">Half Day</option>
                                    <option value="2">Full Day</option>
                                </select>
                            </div>

                            <div className="col-md-6  mt-3 form-group">
                                <label>Time From</label><br /><br />
                                <DatePicker
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                />

                            </div>

                            <div className="col-md-6  mt-3 form-group">
                                <label>Time To</label><br /><br />
                                <DatePicker
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                />

                            </div>

                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default RentalForm;