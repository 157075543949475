import React from "react";

import Reports from "../reports";

const DashboardPage = () => {
    return (
        <>
            <Reports />
        </>
    )
}

export default DashboardPage;