import React, {  useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DialogImg(props) {
    const [load, setLoad] = useState(false);
    const [test , setTesting] = useState(0);
    const [files, setFiles] = useState([]);
    const [allData, setAllData] = useState({
        category    : '',
        caption     : '',
        year         : '',
        venue       : ''
    });

    useEffect(() => {
        setAllData({
            ...allData,
            category : props.imageEdit?.category,
            caption : props.imageEdit?.caption,
            year : props.imageEdit?.img_year,
            venue : props.imageEdit?.venue,
            id : props.imageEdit?.id,
        })
    },[props])

    const handleInput = (e) => {
        setAllData({
            ...allData,
            [e.target.name]: e.target.value
        })
    }

    const handleClickOpen = () => {
        setTesting(test + 1)
        setAllData({
            ...allData ,
            category    : '',
            caption     : '',
            year         : '',
            venue       : ''
        });
        props.setOpen(true);  
        
    };

    useEffect(() => {
        setAllData({
            ...allData ,
            category    : '',
            caption     : '',
            year         : '',
            venue       : ''
        });
    },[test])

    const handleClose = () => {
        setTesting(test + 1)
        setAllData({
            ...allData ,
            category    : '',
            caption     : '',
            year         : '',
            venue       : ''
        });
        props.setOpen(false);
    };
  
    const handleFiles = (e) => {
        console.log(e.target.files[0]);
        setFiles(e.target.files[0]);
    }

    const handleDate = (e) => {
        console.log(e.target.value.length ,'lenght');
        if (e.target.value.length <= 4) {
            setAllData({...allData ,year : e.target.value})
        }
    }


    const saveGallery = () => {
        if (allData.category === '') {
            toast.error('Please Select Category', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        if (files == '') {
            toast.error('Please Select a Image', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        setLoad(true);
        const api = process.env.REACT_APP_MAIN_API + "/postGallery";
        const imgdata = new FormData();
        
        imgdata.append(`img`, files)
        imgdata.append('category', allData.category);
        imgdata.append('caption', allData.caption);
        imgdata.append('year', allData.year);
        imgdata.append('venue', allData.venue);
        imgdata.append('id', allData.id ? allData.id : '' );
        axios.post(api, imgdata)
            .then((res) => {
                console.log(res);
                // window.location.reload();
            })
            .catch((err) => {
                console.log(err.response);
            })

    }

    return (
        <div>
            <ToastContainer />
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open alert dialog
            </Button> */}
            <Button variant='contained' onClick={handleClickOpen} className='float-end'>+ Add Gallery</Button>
            <Dialog
                open={props.open}
                maxWidth='md'
                fullWidth={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Upload Gallery Images
                </DialogTitle>
                <DialogContent>

                    <div>
                        <div className="row">
                            <div className="col-12 p-2">
                                <label htmlFor="">Select Images</label>
                                <input type="file" onChange={handleFiles} className='form-control' />
                            </div>

                            <div className="col-6 p-2">
                                <label htmlFor="">Category</label>
                                <select name="category" id="" className='form-control' onChange={handleInput} value={allData.category}>
                                    <option value="">-Select-</option>
                                    <option value="event">Event</option>
                                    <option value="rental">Rental</option>
                                    <option value="clubs">Clubs</option>
                                </select>
                            </div>
                            <div className="col-6 p-2">
                                <label htmlFor="">Can you mention the year ?</label>
                                <input type="number" onChange={handleDate} className='form-control' name='year' placeholder="YYYY" value={allData.year} />
                            </div>
                            <div className="col-12 p-2">
                                <label htmlFor="">Caption for Image</label>
                                <input type="text" onChange={handleInput} className='form-control' name='caption' value={allData.caption} />
                            </div>
                            <div className="col-12 p-2">
                                <label htmlFor="">Venue</label>
                                <input type="text" onChange={handleInput} className='form-control' name='venue' value={allData.venue} />
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    {/* <Button onClick={saveGallery} disabled={load ? true : false} autoFocus> */}
                    <Button onClick={saveGallery} autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}