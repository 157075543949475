
import axios from 'axios';
// import AuthUser from './token';
// 
// const {token} = AuthUser();

const API = axios.create({
    // baseURL : "http://127.0.0.1:8000/api/",
    baseURL : "https://agorathespace.com/backend/public/api/",





    // baseURL :  process.env.REACT_APP_API_URL
   
    headers :{
        "Content-Type": "application/json",
        // "Authorization" : `Bearer ${token}`
    }
});


export default API;
