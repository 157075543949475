import React from "react";

const RdSingle = () => {
    return (
        <>
            <section className="rd light-bg" id="rd">
                <div className="text-center" data-aos="fade-up">
                    <h5 className='sub-title'>OUR ROBUST ADVANCES</h5>
                    <h1> Research Materials </h1>
                </div>

                <div className="container py-3" data-aos="fade-up">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <article className="entry col-lg-12 mt-4 mt-md-0 mx-3" style={{'background':'#ffffff'}}>

                                    <h2 className="entry-title mx-2">
                                        <a href="/rd-single">MHPSS TOOLKIT FOR THE FACILITATORS OF ABITA TEA COMMUNITY WELFARE INITIATIVE</a>
                                    </h2>

                                    <div className="rdMeta">
                                        <ul className="d-flex align-items-center">
                                            <li className="d-flex align-items-center">
                                                <a href="/rd-single"><i className="bi bi-calendar-heart-fill"></i>&nbsp; July 1, 2023</a>
                                            </li>
                                            <li className="d-flex align-items-center mx-3">
                                                <a href="/rd-single"><i className="bi bi-person-heart"></i> &nbsp;Dr. Sangeeta Goswami & Team</a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="rdContent py-3" style={{'textAlign':'justify'}}>
                                        <p className="px-3"><i className="bx bxs-quote-alt-left quote-icon-left"></i>&nbsp;
                                            In today’s world psychosocial health has assumed great importance as an overall determinant of community wellbeing. Being compounded by the COVID 19 pandemic, existing healthcare and social welfare systems, are now realizing the importance of mental health and psychosocial support services (MHPSS) for people from all walks of life at the primary level.<br/><br/>

                                            UNICEF Assam in partnership with MIND India has embarked on a path for application of the MHPSS model to promote and bring about the much needed positive health and wellbeing seeking behaviour within members of the community through SAMARTHYA, a programme to develop, institutionalize & capacitate community based mental health and psychosocial support structures.<br/><br/>

                                            This programme will aim at capacitating the facilitator group under ABITA tea community welfare initiative and the peer leaders of the adolescent collectives within the tea estates in eight tea districts of Assam on MHPSS.<br/><br/>

                                            It is envisioned that the tools provided in this toolkit would allow for the growth of resilience amongst the target group by promoting mental health awareness and assist in the development of a framework for psychosocial support at the grassroot level and would thereby enable formation of pathways for referral.<br/><br/>

                                            The content is designed as a two pronged strategy which on one hand will enable the facilitators to address MHPSS needs, as and when needed. On the other hand, it will capacitate the peer leaders of the adolescent groups in establishing a community based MHPSS model. This would help ongoing efforts to bring about the desired social and behavioural changes in so far as building a protective and positive environment for children and adolescents, is concerned. The peer leaders will be called the MON XOKHIs which in the Assamese language means MON= mind, XOKHI=friend or confidante.
                                            &nbsp;<i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                    </div>

                                </article>

                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>

            <section id="member" className="member">
                <div className="container" data-aos="fade-up">

                    <div className="text-center">
                        <h5 className="sub-title">MATERIALS</h5>
                        <h1>Request For Research Materials</h1>
                        <img src='/underline.png' className='underlineImage' />
                    </div>

                    <div className="row my-3" data-aos="fade-up">
                        <div className="col-lg-7 memberImage">
                            <img src="assets/tabs-4.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-lg-5">
                            <form action="forms/contact.php" method="post" role="form" className="php-email-form" style={{'background':'#ffffff'}}>
                                <div className="row">
                                <div className="col form-group">
                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                                </div>
                                <div className="col form-group">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                </div>
                                </div>
                                <div className="form-group">
                                <input type="number" className="form-control" name="number" id="number" placeholder="Number" required />
                                </div>
                                <div className="form-group">
                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                                </div>
                                <div className="form-group">
                                <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                                </div>
                                <div className="my-3">
                                <div className="loading">Loading</div>
                                <div className="error-message"></div>
                                <div className="sent-message">Your message has been sent. Thank you!</div>
                                </div>
                                <div className="text-center" style={{'paddingBottom':'15px'}}><button type="submit">Send Message</button></div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RdSingle;