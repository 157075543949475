import React, {useEffect} from "react";
import Header from '../component/common/header';
import Footer from '../component/common/footer';
import RdSingle from "../component/research/rdSingle";

const RdSinglePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return (
        <>
            <Header />
            <RdSingle />
            <Footer />
        </>
    )
}

export default RdSinglePage;