import React, {useEffect} from 'react'

import About from '../component/about/about';
import Header from '../component/common/header';
import Footer from '../component/common/footer';

const Aboutpage =()=>{
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
    return (
        <>
            <Header />
            <About/>
            <Footer />
        </>
    )
}

export default Aboutpage;