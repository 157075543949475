import React, {useEffect} from "react";
import Header from "../component/common/header";
import Footer from '../component/common/footer';
import Rental from "../component/rental/rental";

const RentalPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return (
        <>
            <Header />
            <Rental />
            <Footer />
        </>
    )
}

export default RentalPage;