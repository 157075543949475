import React from 'react';

const Club = () => {
    return (
        <>
            <section className='tabs light-bg' id='tabs'>
                <div className="text-center">
                    <h1>Clubs At Agora</h1>
                </div>

                <div className='container' data-aos="fade-up">
                    <div className='row justify-content-center'>
                        <ul className="nav nav-tabs row d-flex">
                            <li className="nav-item col-3">
                                <a className="link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
                                <i className="bi bi-camera2"></i>
                                <h5 className="d-none d-lg-block">Photography Club</h5>
                                </a>
                            </li>
                            <li className="nav-item col-3">
                                <a className="link" data-bs-toggle="tab" data-bs-target="#tab-2">
                                <i className="bi bi-building-fill-gear"></i>
                                <h5 className="d-none d-lg-block">Theatre Club</h5>
                                </a>
                            </li>
                            <li className="nav-item col-3">
                                <a className="link" data-bs-toggle="tab" data-bs-target="#tab-3">
                                <i className="bi bi-film"></i>
                                <h5 className="d-none d-lg-block">Films & Cinema</h5>
                                </a>
                            </li>
                            <li className="nav-item col-3">
                                <a className="link" data-bs-toggle="tab" data-bs-target="#tab-4">
                                <i className="bi bi-book-half"></i>
                                <h5 className="d-none d-lg-block">Literature Club</h5>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className='tab-content'>
                        <div className="tab-pane active show" id="tab-1">
                            <div className='row'>
                                <div className="col-lg-12 order-2 order-lg-1 mt-3 mt-lg-0 px-3 py-3" 
                                    data-aos="fade-left" data-aos-delay="100">
                                        <p  style={{'fontSize':'larger', 'lineHeight':2, 'textAlign':'justify'}}>
                                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>&nbsp;
                                                The photography club is looking for members who are passionate about photography and would like to take up the reigns to have a inclusive space to share their love and experiences with the medium through this club. If you are interested in joining or organising an activity through the club, please get in touch.&nbsp;
                                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane" id="tab-2">
                            <div className='row'>
                                <div className="col-lg-12 order-2 order-lg-1 mt-3 mt-lg-0 px-5 py-3" 
                                    data-aos="fade-left" data-aos-delay="100">
                                        <p style={{'fontSize':'larger', 'lineHeight':2,'textAlign':'justify'}}>
                                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>&nbsp;
                                                Theatre@AGORA, our drama club brings together thespians and theatre enthusiasts alike. The club meet ups once a month to read, adapt, discuss and ideate on theatre lines. It’s more than just a rehearsal space, it is a space that engages with drama and enable various conversations around different aspects of theatre which may translate into conversations about life as well. Through our club, we have had three original productions since 2019- Sentenced, Coupling and Performed Pieces.<br/><br/>

                                                We have also ventured into street theatre with Baator Natok, an awareness campaign for RGVN. Our most recent activity included Maanch, an evening of performances and discussions to celebrate world theatre day which saw the Assamese theatre fraternity come together and send out a message of hope under the guidance of our chief guest Sri Dulal Roy. Through Maanch, we hoped to encourage a sustained interaction among the members along theatre lines and create a community based solely on the love for theatre, and continue to work towards contributing to the theatre community positively.&nbsp;
                                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                </div>
                            </div>
                        </div>

                        <div className='tab-pane' id='tab-3'>
                            <div className='row'>
                                <div className="col-lg-12 order-2 order-lg-1 mt-3 mt-lg-0 px-5 py-3" 
                                   data-aos="fade-left" data-aos-delay="100">
                                        <p style={{'fontSize':'larger', 'lineHeight':2,'textAlign':'justify'}}>
                                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>&nbsp;
                                                Satyajit Ray, Roberto Benini, Mrinal Sen, Guiseppe Tornatore, Majid Majdi, Jahnu Baruah do these names ring a bell?  Steven Spielberg, Rakesh Mehra, Hrishikeh Mukherjee do these ring a bell?  Do you love watching movies from all around the world and would like to be in a group of like minded people who love doing the same? Then you must be looking for Agora's film club.<br/><br/>

                                                Film Club at Agora screens, discusses and critically analyzes movies. We plan to have workshops, guest lectures in the future, discussion of techniques, tools. In the past we had screened Cinema Paradiso for our members. North East India's has always been in the forefront of film movement in India. We had stalwarts such as Jyoti Prasad Agarwal and Pramathesh Baruah who by their sheer will kindled the film making industry in this part of India. The club is a collective that aims to keep that creative <br/><br/>

                                                At Agora's film club we are always looking for enthusiastic members and would like to be more prolific in terms of events such as screenings, discussions, workshops.<br/><br/>

                                                Please join our film club and plunge into the world of eclectic cinema. Contact us by filling the form below or email us. The address is in the address bar.&nbsp;
                                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                </div>
                            </div>
                        </div>

                        <div className='tab-pane' id='tab-4'>
                            <div className='row'>
                                <div className="col-lg-12 order-2 order-lg-1 mt-3 mt-lg-0 px-5 py-3" 
                                    data-aos="fade-left" data-aos-delay="100">
                                        <p style={{'fontSize':'larger', 'lineHeight':2,'textAlign':'justify'}}>
                                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>&nbsp;
                                                Literature club at Agora aims to bring together Book lovers to discuss and read myriads of books. We aim to bring together a diverse group of people with the aim of creating a safe space where people can discuss books they have read or want to read, express their opinions and critique unabashedly. Language is no barrier , the more diverse the merrier we will be. Remember variety is the spice of life.<br/><br/>

                                                We also aim to establish a writer's lab in the near future.<br/><br/>

                                                We intend to call it a laboratory because we want to assemble a group of writers who perceive the act of writing as a science and writers as- literary scientists or “LIT scientists''. On a more serious note, the writer’s lab is to be created with the purpose of publishing both short and long form pieces of writing; fiction and non-fiction. <br/><br/>

                                                The scientists could write about anything and everything- even about the weird personalities of their neighbourhood dog or the experience of their first root canal. <br/><br/>However, 

                                                we are looking for pieces which are: 

                                                <li>1. Political and not necessarily in the "conventional sense of the word" because we believe- personal is political. (Like the politics of a neighborhood dog) </li>

                                                <li>2. Cultural- something that catches the fluctuations of our times and arranges these cultural data into a colourful yet accurate pie chart (not literally!) </li>

                                                <li>3. Satirical- we would appreciate pieces which laugh at people/objects/incidents and sometimes, pieces which make fun of the writer or the reader. </li><br/>

                                                And if it's neither of the above, it should be INTERESTING and it would qualify. Because to be honest, we will be looking for a unique depth of prose (or poetry) and style. Or LIT orgasms. Okay, enough of the same joke. <br/><br/>

                                                If you are interested in joining our book club please contact us via the form below or email us. Email address is in the footer.
                                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='member'>
                <div className='container' data-aos="fade-up">
                    <div className="text-center">
                        <h1>Become A Member</h1>
                    </div>

                    <div className="row my-3" data-aos="fade-up">
                        <div className="col-lg-7 memberImage my-2">
                            <img src="member.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-lg-5 my-2">
                            <form action="forms/contact.php" method="post" role="form" className="php-email-form" style={{'background':'#ffffff'}}>
                                <div className="row">
                                <div className="col form-group">
                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                                </div>
                                <div className="col form-group">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                </div>
                                </div>
                                <div className="form-group">
                                <input type="number" className="form-control" name="number" id="number" placeholder="Number" required />
                                </div>
                                <div className="form-group">
                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                                </div>
                                <div className="form-group">
                                <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                                </div>
                                <div className="my-3">
                                <div className="loading">Loading</div>
                                <div className="error-message"></div>
                                <div className="sent-message">Your message has been sent. Thank you!</div>
                                </div>
                                <div className="text-center" style={{'paddingBottom':'15px'}}><button type="submit">Send Message</button></div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Club;