import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "../../../API/api";

const AddEventForm = (props) => {


    const [readonly, setReadonly] = useState(false);
    const [ticketData, setTicketData] = useState();

    useEffect(() => {
        getTicketData();
    }, []);
    
    const getTicketData = () => {
        api.getTicketData().then((res) => {
            console.log(res);
            setTicketData(res.data.ticket)
        })
    }

    useEffect(() => {
        if (props.user_id) {
            setReadonly(true)
        }
    }, []);

    


    return (
        <>
            <ToastContainer />
            <section id="eventForm" className="eventForm">
                <div className="container">
                    <div className="row">
                        {props.value &&
                            <div className="col-md-12">
                                <div className="php-email-form">
                                    {/* **************up*************** */}

                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <label>User Name</label><br />
                                            <input type="text" className="form-control" name="user_name" value={props.value.user_name} onChange={props.data} id="name" />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>User Id</label><br />
                                            <input type="text" className="form-control" name="user_id" value={props.value.user_id} onChange={props.data} id="name" readOnly={true} />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>Email</label><br />
                                            <input type="text" className="form-control" name="email" value={props.value.email} onChange={props.data} id="name" />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>Purpose</label><br />
                                            {readonly &&
                                                <input type="text" className="form-control" name="purpose" value={props.value.purpose} onChange={props.data} id="name" />
                                            }
                                            {!readonly &&
                                                <select value={props.value.purpose} onChange={props.data} name="purpose">
                                                    <option value="">--Select--</option>
                                                    <option value="Theatre/Play">Theatre/Play</option>
                                                    <option value="Music & Poetry Nights">Music & Poetry Nights</option>
                                                    <option value="Open Mic">Open Mic</option>
                                                    <option value="Film Screenings">Film Screenings </option>
                                                    <option value="Talk / Lecture Series">Talk / Lecture Series</option>
                                                    <option value="Share Circles">Share Circles</option>
                                                    <option value="Exhibitions & Art Gallery">Exhibitions & Art Gallery</option>
                                                    <option value="Book readings">Book readings </option>
                                                    <option value="Practise for a play / performanc">Practise for a play / performanc</option>
                                                    <option value="Shoot / Document a project">Shoot / Document a project </option>
                                                    <option value="Plan / Brainstorm for a project">Plan / Brainstorm for a project </option>
                                                    <option value="Other">Other </option>
                                                </select>
                                            }
                                        </div>


                                        <div className="col-md-4 form-group">
                                            <label>Date selected</label> <br />
                                            <input type="date" className="form-control" id="date1" name="date" value={props.value.date} onChange={props.data} />
                                        </div>

                                        <div className="col-md-4 form-group">
                                            <label>Time from</label> <br />
                                            <input type="time" className="form-control" id="appt" name="from_time" value={props.value.from_time} onChange={props.data} />
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <label>Time To</label> <br />
                                            <input type="time" className="form-control" id="appt" name="to_time" value={props.value.to_time} onChange={props.data} />
                                        </div>
                                    </div>
                                    {/* **************down*************** */}
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <label>Event Name</label><br />
                                            <input type="text" className="form-control" name="event" value={props.value.event} onChange={props.data} id="name" />
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-md-6 form-group">
                                            <label>Venue</label> <br />
                                            <input type="text" className="form-control" name="location" value={props.value.location} onChange={props.data} id="location" />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>Audience participation</label> <br />

                                            <select value={props.value.audience_participation} onChange={props.data} name="audience_participation">
                                                <option value="">--Select--</option>
                                                <option value="1">Purchase tickets</option>
                                                <option value="2">Walk-in</option>
                                                <option value="3">Registration</option>
                                                <option value="4">Close / private</option>
                                            </select>
                                        </div>

                                        {props.value.audience_participation == 1 &&
                                            <>
                                                <div className="col-md-4 form-group">
                                                    <label>Ticket Platform</label> <br />
                                                    <select className="form-control" name="ticketPlatform" value={props.value.ticketPlatform} onChange={props.data} placeholder="BookMyShow">
                                                        <option value="">-Select-</option>
                                                        {ticketData && ticketData.map((t) =>
                                                        (
                                                            <option value={t.platform}>{t.platform}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="col-md-4 form-group">
                                                    <label>Ticket Link</label> <br />
                                                    <input type="text" className="form-control" name="ticketLink" value={props.value.ticketLink} onChange={props.data} />
                                                </div>
                                                <div className="col-md-4 form-group">
                                                    <label>Minimum Ticket Price</label> <br />
                                                    <input type="text" className="form-control" name="minPrice" value={props.value.minPrice} onChange={props.data} />
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label>Enter Your Description</label><br />
                                        <textarea className="form-control" name="description" value={props.value.description} onChange={props.data} rows="5" placeholder="Description" ></textarea>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddEventForm;