import React, { useState } from "react";

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import api from "../../API/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';

import CategoryImg from "../gallery/categoryImg";

const Member = () => {

    const [FormData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        address: '',
        specialization: '',
        clubs: [],
    });


    const handleInput = (e) => {
        console.log(e.target.value);
        setFormData({
            ...FormData,
            [e.target.name]: e.target.value
        })
    }

    const handleClub = (e, value) => {
        console.log(value);
        setFormData({
            ...FormData,
            clubs: value
        })
    }

    const handleSubmit = () => {
        console.log(FormData);

        api.internForm(FormData)
            .then((res) => {
                console.log(res);

                toast.success(res.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });


            })
            .catch((err) => {
                console.log(err.response);
            })
    }




    const category = [
        { title: 'Theatre/Play', id: 1 },
        { title: 'Music & Poetry Nights', id: 2 },
        { title: 'Open Mic', id: 3 },

    ];

    return (
        <>
            <ToastContainer />
            <section id="volunteer" className="light-bg">
                <div className="container p-5'">
                    <div className="row justify-content-center" data-aos="fade-up">
                        <div className="col-md-12 text-center">
                            <h1> Application Form For  Interns & Volunteers </h1>
                        </div>

                        <div className="row">
                            <div className="col-md-6 my-2 p-0">

                                <div className="php-email-form bg-light" >
                                    <div className="row">

                                        <div className="col form-group">
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& > :not(style)': { p: 1, width: '100%' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <TextField id="outlined-basic" name="name" value={FormData.name} onChange={handleInput} label="Full name" variant="outlined" />
                                            </Box>

                                        </div>
                                        <div className="col form-group">
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& > :not(style)': { p: 1, width: '100%' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <TextField type="number" id="outlined-basic" name="phone" value={FormData.phone} onChange={handleInput} label="Phone" variant="outlined" />
                                            </Box>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { p: 1, width: '100%' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField id="outlined-basic" name="email" value={FormData.email} onChange={handleInput} label="Email" variant="outlined" />
                                        </Box>
                                    </div>
                                    <div className="form-group">
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { p: 1, width: '100%' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField id="outlined-basic" name="address" value={FormData.address} onChange={handleInput} label="Address" variant="outlined" />
                                        </Box>
                                    </div>
                                    <div className="form-group">
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { p: 1, width: '100%' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField id="outlined-basic" name="specialization" value={FormData.specialization} onChange={handleInput} label="Purpose of joining" variant="outlined" />
                                        </Box>
                                    </div>

                                    {/* <div className="form-group">
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { p: 1, width: '100%' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <Autocomplete
                                                multiple
                                                id="tags-outlined"
                                                options={category}
                                                getOptionLabel={(option) => option.title}
                                                // defaultValue={[category[13]]}
                                                onChange={(e, value) => handleClub(e.target, value)}

                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // variant="standard"
                                                        label="Select Clubs"
                                                        placeholder="Clubs"

                                                    />
                                                )}
                                            />
                                        </Box>
                                    </div> */}

                                    <div className="text-center pt-3"><button type="submit" onClick={handleSubmit} style={{ 'width': '100%' }}>Join Now</button></div>
                                </div>

                            </div>

                            <div className="col-md-6 my-2 p-0">
                                <img src="new.jfif" alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="member">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="text-center">
                                <h3>Roles & Responsibilities Of Members</h3>
                            </div>
                                 <div className="tab-pane my-3">
                                    <ul className="mx-4">
                                        <li><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAgRJREFUSEu1Ve1VGzEQnKkAUkFMBUAFcSpAqQBSQaCCW6WBmAowFbB0ABUAFcR04FSwyerkO/ks+yA86737cfrY2Z0djYg9D+45PkYBrAkXAL4AOMmf5/ScPqPy5939riS3ApiEAMMvAJORKhcgriiqtX1VAJMwg+HHO+mbMerV8MwGQCX4HxAOqIzq1MCacALCK7wEcNAFJa4p6nPFVPGTabkrVm8BXFJ0WavGJBwCmMNwVpz5VtK1VoFJWMDwOW0mbinqDUYGdsqmOdADiEjRh7yuBciCUY9WgB1AVstNXnBaJp65SfAMz6v9aEEkVWJYdHQRXRU9QBmoP+g895TVZfLVKzEJAkMzrL6swBt4nGOcekOtCU6B3wEfjyASZTDMy3lGnabGA0957zOjnrZM52FNsI63qGm+nANxRFGnwXsygeH3rv3MMd4D8Gmlpv8FGKPIlfM9U+RiaBVF3FM0DCh6YVSnrKBo701uvWelmGXm3GXaq2OoonWZek/84nnamzLNzesvGjBn1ESJSZjCIANFSfWiEa8U7QxyeJOHup9np9xuFQbvR+iKK7Jf60En100nXcIwS80szc79h8nsWlraaLvNbgfIkP3N/0rwagUFiJftNt2a37ZBvGbHffuDU8ZKTuocW3oyV1byAqZnU7e9ZD1r48V/aMfoo/+h6P8O/wVJHxkoBYby8AAAAABJRU5ErkJggg=="/>&nbsp;
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry</li>
                                        <li><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAgRJREFUSEu1Ve1VGzEQnKkAUkFMBUAFcSpAqQBSQaCCW6WBmAowFbB0ABUAFcR04FSwyerkO/ks+yA86737cfrY2Z0djYg9D+45PkYBrAkXAL4AOMmf5/ScPqPy5939riS3ApiEAMMvAJORKhcgriiqtX1VAJMwg+HHO+mbMerV8MwGQCX4HxAOqIzq1MCacALCK7wEcNAFJa4p6nPFVPGTabkrVm8BXFJ0WavGJBwCmMNwVpz5VtK1VoFJWMDwOW0mbinqDUYGdsqmOdADiEjRh7yuBciCUY9WgB1AVstNXnBaJp65SfAMz6v9aEEkVWJYdHQRXRU9QBmoP+g895TVZfLVKzEJAkMzrL6swBt4nGOcekOtCU6B3wEfjyASZTDMy3lGnabGA0957zOjnrZM52FNsI63qGm+nANxRFGnwXsygeH3rv3MMd4D8Gmlpv8FGKPIlfM9U+RiaBVF3FM0DCh6YVSnrKBo701uvWelmGXm3GXaq2OoonWZek/84nnamzLNzesvGjBn1ESJSZjCIANFSfWiEa8U7QxyeJOHup9np9xuFQbvR+iKK7Jf60En100nXcIwS80szc79h8nsWlraaLvNbgfIkP3N/0rwagUFiJftNt2a37ZBvGbHffuDU8ZKTuocW3oyV1byAqZnU7e9ZD1r48V/aMfoo/+h6P8O/wVJHxkoBYby8AAAAABJRU5ErkJggg=="/>&nbsp;
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry</li>
                                        <li><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAgRJREFUSEu1Ve1VGzEQnKkAUkFMBUAFcSpAqQBSQaCCW6WBmAowFbB0ABUAFcR04FSwyerkO/ks+yA86737cfrY2Z0djYg9D+45PkYBrAkXAL4AOMmf5/ScPqPy5939riS3ApiEAMMvAJORKhcgriiqtX1VAJMwg+HHO+mbMerV8MwGQCX4HxAOqIzq1MCacALCK7wEcNAFJa4p6nPFVPGTabkrVm8BXFJ0WavGJBwCmMNwVpz5VtK1VoFJWMDwOW0mbinqDUYGdsqmOdADiEjRh7yuBciCUY9WgB1AVstNXnBaJp65SfAMz6v9aEEkVWJYdHQRXRU9QBmoP+g895TVZfLVKzEJAkMzrL6swBt4nGOcekOtCU6B3wEfjyASZTDMy3lGnabGA0957zOjnrZM52FNsI63qGm+nANxRFGnwXsygeH3rv3MMd4D8Gmlpv8FGKPIlfM9U+RiaBVF3FM0DCh6YVSnrKBo701uvWelmGXm3GXaq2OoonWZek/84nnamzLNzesvGjBn1ESJSZjCIANFSfWiEa8U7QxyeJOHup9np9xuFQbvR+iKK7Jf60En100nXcIwS80szc79h8nsWlraaLvNbgfIkP3N/0rwagUFiJftNt2a37ZBvGbHffuDU8ZKTuocW3oyV1byAqZnU7e9ZD1r48V/aMfoo/+h6P8O/wVJHxkoBYby8AAAAABJRU5ErkJggg=="/>&nbsp;
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry</li>
                                        <li><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAgRJREFUSEu1Ve1VGzEQnKkAUkFMBUAFcSpAqQBSQaCCW6WBmAowFbB0ABUAFcR04FSwyerkO/ks+yA86737cfrY2Z0djYg9D+45PkYBrAkXAL4AOMmf5/ScPqPy5939riS3ApiEAMMvAJORKhcgriiqtX1VAJMwg+HHO+mbMerV8MwGQCX4HxAOqIzq1MCacALCK7wEcNAFJa4p6nPFVPGTabkrVm8BXFJ0WavGJBwCmMNwVpz5VtK1VoFJWMDwOW0mbinqDUYGdsqmOdADiEjRh7yuBciCUY9WgB1AVstNXnBaJp65SfAMz6v9aEEkVWJYdHQRXRU9QBmoP+g895TVZfLVKzEJAkMzrL6swBt4nGOcekOtCU6B3wEfjyASZTDMy3lGnabGA0957zOjnrZM52FNsI63qGm+nANxRFGnwXsygeH3rv3MMd4D8Gmlpv8FGKPIlfM9U+RiaBVF3FM0DCh6YVSnrKBo701uvWelmGXm3GXaq2OoonWZek/84nnamzLNzesvGjBn1ESJSZjCIANFSfWiEa8U7QxyeJOHup9np9xuFQbvR+iKK7Jf60En100nXcIwS80szc79h8nsWlraaLvNbgfIkP3N/0rwagUFiJftNt2a37ZBvGbHffuDU8ZKTuocW3oyV1byAqZnU7e9ZD1r48V/aMfoo/+h6P8O/wVJHxkoBYby8AAAAABJRU5ErkJggg=="/>&nbsp;
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry</li>
                                        <li><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAgRJREFUSEu1Ve1VGzEQnKkAUkFMBUAFcSpAqQBSQaCCW6WBmAowFbB0ABUAFcR04FSwyerkO/ks+yA86737cfrY2Z0djYg9D+45PkYBrAkXAL4AOMmf5/ScPqPy5939riS3ApiEAMMvAJORKhcgriiqtX1VAJMwg+HHO+mbMerV8MwGQCX4HxAOqIzq1MCacALCK7wEcNAFJa4p6nPFVPGTabkrVm8BXFJ0WavGJBwCmMNwVpz5VtK1VoFJWMDwOW0mbinqDUYGdsqmOdADiEjRh7yuBciCUY9WgB1AVstNXnBaJp65SfAMz6v9aEEkVWJYdHQRXRU9QBmoP+g895TVZfLVKzEJAkMzrL6swBt4nGOcekOtCU6B3wEfjyASZTDMy3lGnabGA0957zOjnrZM52FNsI63qGm+nANxRFGnwXsygeH3rv3MMd4D8Gmlpv8FGKPIlfM9U+RiaBVF3FM0DCh6YVSnrKBo701uvWelmGXm3GXaq2OoonWZek/84nnamzLNzesvGjBn1ESJSZjCIANFSfWiEa8U7QxyeJOHup9np9xuFQbvR+iKK7Jf60En100nXcIwS80szc79h8nsWlraaLvNbgfIkP3N/0rwagUFiJftNt2a37ZBvGbHffuDU8ZKTuocW3oyV1byAqZnU7e9ZD1r48V/aMfoo/+h6P8O/wVJHxkoBYby8AAAAABJRU5ErkJggg=="/>&nbsp;
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry</li>
                                    </ul>
                                </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-center">
                                <h3>Benefits & Perks Of Interning With Agora</h3>
                            </div>
                           
                            <div className="tab-pane my-3">
                                    <ul className="mx-4">
                                        <li><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAgRJREFUSEu1Ve1VGzEQnKkAUkFMBUAFcSpAqQBSQaCCW6WBmAowFbB0ABUAFcR04FSwyerkO/ks+yA86737cfrY2Z0djYg9D+45PkYBrAkXAL4AOMmf5/ScPqPy5939riS3ApiEAMMvAJORKhcgriiqtX1VAJMwg+HHO+mbMerV8MwGQCX4HxAOqIzq1MCacALCK7wEcNAFJa4p6nPFVPGTabkrVm8BXFJ0WavGJBwCmMNwVpz5VtK1VoFJWMDwOW0mbinqDUYGdsqmOdADiEjRh7yuBciCUY9WgB1AVstNXnBaJp65SfAMz6v9aEEkVWJYdHQRXRU9QBmoP+g895TVZfLVKzEJAkMzrL6swBt4nGOcekOtCU6B3wEfjyASZTDMy3lGnabGA0957zOjnrZM52FNsI63qGm+nANxRFGnwXsygeH3rv3MMd4D8Gmlpv8FGKPIlfM9U+RiaBVF3FM0DCh6YVSnrKBo701uvWelmGXm3GXaq2OoonWZek/84nnamzLNzesvGjBn1ESJSZjCIANFSfWiEa8U7QxyeJOHup9np9xuFQbvR+iKK7Jf60En100nXcIwS80szc79h8nsWlraaLvNbgfIkP3N/0rwagUFiJftNt2a37ZBvGbHffuDU8ZKTuocW3oyV1byAqZnU7e9ZD1r48V/aMfoo/+h6P8O/wVJHxkoBYby8AAAAABJRU5ErkJggg=="/>&nbsp;
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry</li>
                                        <li><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAgRJREFUSEu1Ve1VGzEQnKkAUkFMBUAFcSpAqQBSQaCCW6WBmAowFbB0ABUAFcR04FSwyerkO/ks+yA86737cfrY2Z0djYg9D+45PkYBrAkXAL4AOMmf5/ScPqPy5939riS3ApiEAMMvAJORKhcgriiqtX1VAJMwg+HHO+mbMerV8MwGQCX4HxAOqIzq1MCacALCK7wEcNAFJa4p6nPFVPGTabkrVm8BXFJ0WavGJBwCmMNwVpz5VtK1VoFJWMDwOW0mbinqDUYGdsqmOdADiEjRh7yuBciCUY9WgB1AVstNXnBaJp65SfAMz6v9aEEkVWJYdHQRXRU9QBmoP+g895TVZfLVKzEJAkMzrL6swBt4nGOcekOtCU6B3wEfjyASZTDMy3lGnabGA0957zOjnrZM52FNsI63qGm+nANxRFGnwXsygeH3rv3MMd4D8Gmlpv8FGKPIlfM9U+RiaBVF3FM0DCh6YVSnrKBo701uvWelmGXm3GXaq2OoonWZek/84nnamzLNzesvGjBn1ESJSZjCIANFSfWiEa8U7QxyeJOHup9np9xuFQbvR+iKK7Jf60En100nXcIwS80szc79h8nsWlraaLvNbgfIkP3N/0rwagUFiJftNt2a37ZBvGbHffuDU8ZKTuocW3oyV1byAqZnU7e9ZD1r48V/aMfoo/+h6P8O/wVJHxkoBYby8AAAAABJRU5ErkJggg=="/>&nbsp;
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry</li>
                                        <li><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAgRJREFUSEu1Ve1VGzEQnKkAUkFMBUAFcSpAqQBSQaCCW6WBmAowFbB0ABUAFcR04FSwyerkO/ks+yA86737cfrY2Z0djYg9D+45PkYBrAkXAL4AOMmf5/ScPqPy5939riS3ApiEAMMvAJORKhcgriiqtX1VAJMwg+HHO+mbMerV8MwGQCX4HxAOqIzq1MCacALCK7wEcNAFJa4p6nPFVPGTabkrVm8BXFJ0WavGJBwCmMNwVpz5VtK1VoFJWMDwOW0mbinqDUYGdsqmOdADiEjRh7yuBciCUY9WgB1AVstNXnBaJp65SfAMz6v9aEEkVWJYdHQRXRU9QBmoP+g895TVZfLVKzEJAkMzrL6swBt4nGOcekOtCU6B3wEfjyASZTDMy3lGnabGA0957zOjnrZM52FNsI63qGm+nANxRFGnwXsygeH3rv3MMd4D8Gmlpv8FGKPIlfM9U+RiaBVF3FM0DCh6YVSnrKBo701uvWelmGXm3GXaq2OoonWZek/84nnamzLNzesvGjBn1ESJSZjCIANFSfWiEa8U7QxyeJOHup9np9xuFQbvR+iKK7Jf60En100nXcIwS80szc79h8nsWlraaLvNbgfIkP3N/0rwagUFiJftNt2a37ZBvGbHffuDU8ZKTuocW3oyV1byAqZnU7e9ZD1r48V/aMfoo/+h6P8O/wVJHxkoBYby8AAAAABJRU5ErkJggg=="/>&nbsp;
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry</li>
                                        <li><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAgRJREFUSEu1Ve1VGzEQnKkAUkFMBUAFcSpAqQBSQaCCW6WBmAowFbB0ABUAFcR04FSwyerkO/ks+yA86737cfrY2Z0djYg9D+45PkYBrAkXAL4AOMmf5/ScPqPy5939riS3ApiEAMMvAJORKhcgriiqtX1VAJMwg+HHO+mbMerV8MwGQCX4HxAOqIzq1MCacALCK7wEcNAFJa4p6nPFVPGTabkrVm8BXFJ0WavGJBwCmMNwVpz5VtK1VoFJWMDwOW0mbinqDUYGdsqmOdADiEjRh7yuBciCUY9WgB1AVstNXnBaJp65SfAMz6v9aEEkVWJYdHQRXRU9QBmoP+g895TVZfLVKzEJAkMzrL6swBt4nGOcekOtCU6B3wEfjyASZTDMy3lGnabGA0957zOjnrZM52FNsI63qGm+nANxRFGnwXsygeH3rv3MMd4D8Gmlpv8FGKPIlfM9U+RiaBVF3FM0DCh6YVSnrKBo701uvWelmGXm3GXaq2OoonWZek/84nnamzLNzesvGjBn1ESJSZjCIANFSfWiEa8U7QxyeJOHup9np9xuFQbvR+iKK7Jf60En100nXcIwS80szc79h8nsWlraaLvNbgfIkP3N/0rwagUFiJftNt2a37ZBvGbHffuDU8ZKTuocW3oyV1byAqZnU7e9ZD1r48V/aMfoo/+h6P8O/wVJHxkoBYby8AAAAABJRU5ErkJggg=="/>&nbsp;
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry</li>
                                        <li><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAgRJREFUSEu1Ve1VGzEQnKkAUkFMBUAFcSpAqQBSQaCCW6WBmAowFbB0ABUAFcR04FSwyerkO/ks+yA86737cfrY2Z0djYg9D+45PkYBrAkXAL4AOMmf5/ScPqPy5939riS3ApiEAMMvAJORKhcgriiqtX1VAJMwg+HHO+mbMerV8MwGQCX4HxAOqIzq1MCacALCK7wEcNAFJa4p6nPFVPGTabkrVm8BXFJ0WavGJBwCmMNwVpz5VtK1VoFJWMDwOW0mbinqDUYGdsqmOdADiEjRh7yuBciCUY9WgB1AVstNXnBaJp65SfAMz6v9aEEkVWJYdHQRXRU9QBmoP+g895TVZfLVKzEJAkMzrL6swBt4nGOcekOtCU6B3wEfjyASZTDMy3lGnabGA0957zOjnrZM52FNsI63qGm+nANxRFGnwXsygeH3rv3MMd4D8Gmlpv8FGKPIlfM9U+RiaBVF3FM0DCh6YVSnrKBo701uvWelmGXm3GXaq2OoonWZek/84nnamzLNzesvGjBn1ESJSZjCIANFSfWiEa8U7QxyeJOHup9np9xuFQbvR+iKK7Jf60En100nXcIwS80szc79h8nsWlraaLvNbgfIkP3N/0rwagUFiJftNt2a37ZBvGbHffuDU8ZKTuocW3oyV1byAqZnU7e9ZD1r48V/aMfoo/+h6P8O/wVJHxkoBYby8AAAAABJRU5ErkJggg=="/>&nbsp;
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry</li>
                                    </ul>
                                </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="">
                {/* <section className="light-bg"> */}
                <CategoryImg type="clubs" />
            </section>
        </>
    )
}

export default Member;