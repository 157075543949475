import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import api from '../../../API/api';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'

export default function TestimonialTable() {
    const navigate = useNavigate();
    const [rows, setRows] = useState();

    useEffect(() => {
        getTestimonial();
    }, []);

    const getTestimonial=()=>{
        api.getTestimonial().then((res)=>{
            console.log("res", res);
            setRows(res.data.data)
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    const handleDelete = (row) => {

        Swal.fire({
            title: 'Delay?',
            text: "You can reschedule at any time",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                rows.filter(obj => {
                    if (obj.id != row.id) {
                        api.deleteTestimonial(row).then(
                            res => {
                                console.log(res);
                                getTestimonial();
                                toast.success(` Deleted succesfully`, {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                });
                            }
                        ).catch(err => {
                            console.log(err);
                        })
                        return;
                    }
                })
            }
        })




        
       
    }

    return (
        <>
            <div className='col-12 d-flex justify-content-between pb-3'>
            <ToastContainer />
                <div>
                    <h4 className='text-center'>Testimonial List</h4>
                </div>
                <button  type='button' className='btn btn-primary' onClick={() => navigate("/admin/AddTestimonial")}>+ Add Testimonial</button>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow className='bg-warning'>
                            <TableCell>Name</TableCell>
                            <TableCell>description</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows && rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell> <img className='py-1' src={`${process.env.REACT_APP_BACKEND}testimonial/img/${row.img} `}  height={50} /> </TableCell>
                                <TableCell>
                                    {/* <button onClick={()=>handleEdit(row)} className='btn btn-sm btn-info mx-2'>Edit</button> */}
                                   
                                    <button onClick={()=>handleDelete(row)} className='btn btn-sm btn-danger'> Delete</button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}