import React, {useEffect} from "react";

import Header from "../component/common/header";
import Footer from "../component/common/footer";
import Contact from "../component/Contact/contact";

const ContactPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return (
           <>
                <Header />
                <Contact />
                <Footer />
           </>
    )
}

export default ContactPage;