import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import api from '../../../API/api';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


export default function MembersList() {
    const navigate = useNavigate();
    const [testing ,settesting] = useState(0);
    const [rows, setRows] = useState();

    useEffect(() => {
        getMembers()
    }, []);

    const getMembers = () => {
        api.getMembers().then((res) => {
            console.log(res,'check dasdkn');
            setRows(res.data.data)
        })
        .catch((err) => {
            console.log(err.response);
        })
    }

    const handleEdit = (row) => {
        navigate("/admin/AddMembers" , {state : row})
    }
    const handleDelete = (row) => {
        rows.filter(obj => {
            if (obj.id != row.id) {
                api.deleteMembers(row).then(
                    res => {
                        console.log(res);
                        getMembers();
                    }
                ).catch(err => {
                    console.log(err);
                })
                return;
            }
        })
        toast.success(`Member Deleted succesfully`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    return (
        <>
            <div className='col-12 d-flex justify-content-between pb-3'>
            <ToastContainer />
                <div>
                    <h4 className='text-center'>Members List</h4>
                </div>
                <button  type='button' className='btn btn-primary' onClick={() => navigate("/admin/AddMembers")}>+ Add Members</button>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow className='bg-warning'>
                            <TableCell>Name</TableCell>
                            <TableCell>description</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Event</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows && rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>{row.role}</TableCell>
                                <TableCell>{row.designation}</TableCell>
                                <TableCell> <img className='py-1' src={`${process.env.REACT_APP_BACKEND}members/img/${row.img} `}  height={50} /> </TableCell>
                                <TableCell>
                                    <button onClick={()=>handleEdit(row)} className='btn btn-sm btn-info mx-2'>Edit</button>
                                   
                                    <button onClick={()=>handleDelete(row)} className='btn btn-sm btn-danger'> Delete</button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}