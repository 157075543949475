import React from "react";
import { useNavigate } from "react-router-dom";


const RentSection = () => {
    const navigate = useNavigate();
    return (
        <>

            <section className="rent " id="rent">
                <div className="bg-1-left"></div>
                <div className="container">
                    <div className="row align-items-center" data-aos="fade-up">

                        <div className="col-md-6 border-right ">
                            <div className="tab-content" data-aos="fade-up">
                                <div className="">
                                    <div className="row px-md-3">
                                        <div className="col-md-12 text-center px-5 ">
                                            <p>Agora is a multipurpose art space used for a number creative processes such as theatre shows as a part of Theatre@AGORA, music and poetry nights, film screenings etc.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6  d-flex justify-content-center " >
                            <div className="row" style={{ height: '200px' }}>
                                <div className="col-5 p-0 d-flex align-items-center justify-content-end">
                                    <a className="btn rentBtn active" onClick={() => navigate('rental')}>RENT A SPACE</a>
                                </div>
                                <div className="col-2 d-flex align-items-center">
                                    <div class="three"></div>
                                </div>
                                <div className="col-5 p-0 d-flex align-items-center">
                                    <a className="btn rentBtn" onClick={() => navigate('member')}>BECOME MEMBER</a>
                                </div>
                            </div>


                        </div>


                    </div>


                </div>
            </section>




            {/* <section className="rent " id="rent">
                <div className="container">
                    <div className="row" data-aos="fade-up">


                        <div className="row rentRow text-center">
                            <div className="col p-4 border1">
                                <a className="btn rentBtn active" onClick={() => navigate('rental')} style={{ 'width': '220px' }}>RENT A SPACE</a>
                            </div>
                            <div className="col p-4 border2">
                                <a className="btn rentBtn" onClick={() => navigate('member')} style={{ 'width': '220px' }}>BECOME A MEMBER</a>
                            </div>

                            <div className="w-100"></div>
                            <div className="col p-4 border3">
                                <a className="btn rentBtn" onClick={() => navigate('club')} style={{ 'width': '220px' }}>OUT-REACH</a>
                            </div>
                            <div className="col p-4">
                                <a className="btn rentBtn active" style={{ 'width': '220px' }}> Co-create</a>
                            </div>
                        </div>


                    </div>

                    <div className="tab-content" data-aos="fade-up">
                        <div className="tab-pane active show" id="tab-1">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <p style={{ 'padding': '0 142px' }}>Agora is a multipurpose art space used for a number reative processes such as theatre shows as a part of Theatre@AGORA, music and poetry nights, film screenings etc.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

        </>
    )
}

export default RentSection;