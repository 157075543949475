import React, {useState} from "react";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const RentalFormDtail = () => {

    const [inputList, setInputList] = useState([{ firstName: "", lastName: "" }]);
 
    // handle input change
    const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    };
   
    // handle click event of the Remove button
    const handleRemoveClick = index => {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    };
   
    // handle click event of the Add button
    const handleAddClick = () => {
      setInputList([...inputList, { cast: "" }]);
    };
   
    return (
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {inputList.map((x, i) => {
                return (
                  <div className="box d-flex">
                    <TextField fullWidth id="standard-basic" label="Cast" variant="standard" value={x.cast}
                      onChange={e => handleInputChange(e, i)}/>
                    <div className="btn-box d-flex">
                      {inputList.length !== 1 && <Button
                        className="mr10"
                        onClick={() => handleRemoveClick(i)}><RemoveCircleOutlineIcon /></Button>}
                      {inputList.length - 1 === i && <Button onClick={handleAddClick}><AddCircleOutlineIcon /></Button>}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-md-12 mt-3">
              <TextField fullWidth id="standard-basic" label="Production Crew" variant="standard" />
            </div>

            <div className="col-md-12 mt-3">
              <TextField fullWidth id="standard-basic" label="Sponsor / Promoter (logo)" variant="standard" />
            </div>
          </div>
        </div>
      </section>
    );
  }


export default RentalFormDtail;