import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const EventSkenton = () => {
    return (
        <>
            <div className='container p-5'>
                <div className='row'>
                    <div className="pb-2 text-center">
                        <Skeleton inline={true} width={150} height={10} />
                    </div>
                    <div className="pb-5 text-center">
                        <Skeleton inline={true} width={250} height={50} />
                    </div>

                    <div className='col-md-8'>
                        <div className="border p-4">


                            <div className="pb-2">
                                <Skeleton inline={true} width={150} height={30} />
                            </div>

                            <div className="pb-2">
                                <Skeleton height={40} />
                            </div>
                            <div>
                                <Skeleton height={35} count={10} />
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className="border p-4">

                            <div className="pb-2">
                                <Skeleton inline={true} width={150} height={30} />
                            </div>

                            <div className="pb-2">
                                <Skeleton height={200} />
                                <Skeleton height={60} />
                            </div>
                            <div>
                                <Skeleton height={25} count={6} />
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}



export default EventSkenton