import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../API/api";



const BlogSingle = () => {

    const params = useParams();

    const [blog, setBlog] = useState();
    useEffect(() => {
        console.log(params);
        getSingleBlog();
    }, []);


    const getSingleBlog = () => {
        api.getSingleBlog(params.id)
            .then((res) => {
                console.log(res);
                setBlog(res.data.blog);
            })
            .catch((err) => {
                console.log(err.response);
            })
    }




    return (
        <>
            <main id="main">

                <section id="blog" className="blog">
                    <div className="container" data-aos="fade-up">
                        <div className="row">
                            {blog &&
                                <div className="col-lg-8 entries">

                                    <article className="entry entry-single">

                                        <div className="entry-img">
                                            <img src={`${process.env.REACT_APP_BACKEND}blog/${blog.cover}`} alt="" className="img-fluid" />
                                        </div>

                                        <h2 className="entry-title">
                                            <a href="">{blog.title}</a>
                                        </h2>

                                        <div className="entry-meta">
                                            <ul>
                                                <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="">{blog.author}</a></li>
                                                <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href=""><time dateTime="2020-01-01">{blog.date}</time></a></li>

                                            </ul>
                                        </div>

                                        <div className="entry-content">

                                            <div dangerouslySetInnerHTML={{ __html: blog.details }} />

                                        </div>

                                        <div className="entry-footer">
                                            {/* <i className="bi bi-folder"></i>
                                            <ul className="cats">
                                                <li><a href="#">Business</a></li>
                                            </ul> */}

                                            <i className="bi bi-tags"></i>
                                            <ul className="tags">
                                                {blog.hashtag}
                                            </ul>
                                        </div>

                                    </article>

                                    <div className="blog-author d-flex align-items-center">
                                        <img src="assets/blog/blog-author.jpg" className="rounded-circle float-left" alt="" />
                                        <div>
                                            <h4>{blog.author}</h4>
                                            {/* <div className="social-links">
                                                <a href="https://twitters.com/#"><i className="bi bi-twitter"></i></a>
                                                <a href="https://facebook.com/#"><i className="bi bi-facebook"></i></a>
                                                <a href="https://instagram.com/#"><i className="biu bi-instagram"></i></a>
                                            </div>
                                            <p>
                                                Itaque quidem optio quia voluptatibus dolorem dolor. Modi eum sed possimus accusantium. Quas repellat voluptatem officia numquam sint aspernatur voluptas. Esse et accusantium ut unde voluptas.
                                            </p> */}
                                        </div>
                                    </div>



                                </div>
                            }


                            <div className="col-lg-4">
                                <div className="sidebar">

                                    <h3 className="sidebar-title">Search</h3>
                                    <div className="sidebar-item search-form">
                                        <form action="">
                                            <input type="text" />
                                            <button type="submit"><i className="bi bi-search"></i></button>
                                        </form>
                                    </div>

                                    <h3 className="sidebar-title">Categories</h3>
                                    <div className="sidebar-item categories">
                                        <ul>
                                            <li><a href="#">Events <span></span></a></li>
                                        </ul>
                                    </div>

                                    {/* <h3 className="sidebar-title">Recent Posts</h3>
                                <div className="sidebar-item recent-posts">
                                    <div className="post-item clearfix">
                                        <img src="assets/blog/blog-recent-1.jpg" alt="" />
                                        <h4><a href={`/blog/${b.id}`}>Nihil blanditiis at in nihil autem</a></h4>
                                        <time dateTime="2020-01-01">Jan 1, 2020</time>
                                    </div>

                                    <div className="post-item clearfix">
                                        <img src="assets/blog/blog-recent-2.jpg" alt="" />
                                        <h4><a href={`/blog/${b.id}`}>Quidem autem et impedit</a></h4>
                                        <time dateTime="2020-01-01">Jan 1, 2020</time>
                                    </div>

                                    <div className="post-item clearfix">
                                        <img src="assets/blog/blog-recent-3.jpg" alt="" />
                                        <h4><a href={`/blog/${b.id}`}>Id quia et et ut maxime similique occaecati ut</a></h4>
                                        <time dateTime="2020-01-01">Jan 1, 2020</time>
                                    </div>

                                    <div className="post-item clearfix">
                                        <img src="assets/blog/blog-recent-4.jpg" alt="" />
                                        <h4><a href={`/blog/${b.id}`}>Laborum corporis quo dara net para</a></h4>
                                        <time dateTime="2020-01-01">Jan 1, 2020</time>
                                    </div>

                                    <div className="post-item clearfix">
                                        <img src="assets/blog/blog-recent-5.jpg" alt="" />
                                        <h4><a href={`/blog/${b.id}`}>Et dolores corrupti quae illo quod dolor</a></h4>
                                        <time dateTime="2020-01-01">Jan 1, 2020</time>
                                    </div>

                                </div> */}

                                    <h3 className="sidebar-title">Tags</h3>
                                    <div className="sidebar-item tags">
                                        <ul>
                                            <li><a href="#">Events</a></li>
                                            <li><a href="#">Drama</a></li>
                                            <li><a href="#">Play</a></li>
                                            <li><a href="#">Practice</a></li>
                                            <li><a href="#">Stage</a></li>
                                            <li><a href="#">Smart</a></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </>
    )
}

export default BlogSingle;