import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper";
import "swiper/css/autoplay";
import "swiper/css";
import api from "../../API/api";
import { Link } from "react-router-dom";
import PinDropIcon from "@mui/icons-material/PinDrop";
import DateRangeIcon from "@mui/icons-material/DateRange";

// let event = require('./dammyData/upcomingEvents.json');

const UpcomingEvents = () => {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    // setEvents(event.events);
    // console.log(event.events);

    upcomingEvents();
  }, []);

  const upcomingEvents = () => {
    api
      .getEvents()
      .then((res) => {
        console.log(res);
        setEvents(res.data.events);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <>
      <section className="bg-custom-light">
        <div className="container p-5">
          <div className="text-center py-3">
            <h1>Upcoming Events </h1>
          </div>

          <div className="row">
            <div className="col-1 p-0 d-flex justify-content-end">
              <i className="bi bi-caret-left-fill review-swiper-button-prev"></i>
            </div>

            <div className="col-10 p-0">
              <div className="p-0">
                <Swiper
                  // slidesPerView={3}
                  // pagination={{ clickable: true }}
                  spaceBetween={10}
                  loop={false}
                  autoplay={false}
                  navigation={{
                    clickable: true,
                    nextEl: ".review-swiper-button-next",
                    prevEl: ".review-swiper-button-prev",
                  }}
                  modules={[Autoplay, Pagination, Navigation, Scrollbar]}
                  className="testimonials-slider swiper"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  breakpoints={{
                    // when window width is >= 640px
                    460: {
                      //   width: 640,
                      slidesPerView: 2,
                    },
                    // when window width is >= 768px
                    768: {
                      //   width: 768,
                      slidesPerView: 3,
                    },
                    968: {
                      //   width: 768,
                      slidesPerView: 4,
                    },
                  }}
                >
                  <div className="swiper-wrapper">
                    {events.slice(0, 10).map((data) => (
                      <>
                        {data.isShow == 1 && (
                          <SwiperSlide key={data.id}>
                            <div
                              className="border bg-white"
                              style={{ height: "350px" }}
                            >
                              <div
                                className="member-img"
                                style={{ height: "200px", overflow: "hidden" }}
                              >
                                {data.cover === "no image" ? (
                                  <img
                                    src="/assets/defaultEvent.jpg"
                                    alt="no image"
                                    className={`img-fluid ${
                                      new Date(data.date) < new Date() &&
                                      "grayscale"
                                    }`}
                                  />
                                ) : (
                                  <>
                                    {data.cover.slice(
                                      ((data.cover.lastIndexOf(".") - 1) >>>
                                        0) +
                                        2
                                    ) === "mp4" ? (
                                      <video
                                        autoplay="autoplay"
                                        loop="loop"
                                        muted
                                        className={`img-fluid ${
                                          new Date(data.date) < new Date() &&
                                          "grayscale"
                                        }`}
                                        style={{ tintColor: "gray" }}
                                      >
                                        <source
                                          src={`${process.env.REACT_APP_BACKEND}events/cover/${data.cover}`}
                                          type="video/mp4"
                                        />
                                      </video>
                                    ) : (
                                      <img
                                        src={`${process.env.REACT_APP_BACKEND}events/cover/${data.cover}`}
                                        alt={data.event}
                                        className={`img-fluid ${
                                          new Date(data.date) < new Date() &&
                                          "grayscale"
                                        }`}
                                        style={{ tintColor: "gray" }}
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                              {new Date(data.date) > new Date() ? (
                                <div class="my-badge">Upcoming</div>
                              ) : (
                                <div class="my-badge badge-disabled">
                                  Recent
                                </div>
                              )}

                              <div className="text-center pt-0">
                                <h5 className="event-name px-2">
                                  <strong>
                                    <Link to={`/viewevents/${data.id}`}>
                                      {data.event}
                                    </Link>
                                  </strong>{" "}
                                </h5>

                                <div
                                  className="text-center text-secondary"
                                  style={{ fontSize: "small" }}
                                >
                                  <PinDropIcon fontSize="small" />{" "}
                                  {data.location ? (
                                    <> {data.location} </>
                                  ) : (
                                    <> AGORA the SPACE </>
                                  )}
                                </div>
                              </div>
                              <div className="home-event">
                                <div className="d-flex justify-content-between  px-2">
                                  <div className="evnt">
                                    <div
                                      className="text-center text-secondary d-flex align-items-center"
                                      style={{ fontSize: "small" }}
                                    >
                                      {" "}
                                      <DateRangeIcon fontSize="small" />{" "}
                                      {new Date(data.date).getDate() +
                                        ", " +
                                        new Date(data.date).toLocaleString(
                                          "default",
                                          { month: "short" }
                                        )}
                                    </div>
                                  </div>

                                  <div className="text-center">
                                    <Link
                                      to={`/viewevents/${data.id}`}
                                      className="text-secondary"
                                      style={{
                                        borderBottom: "1px solid #F1AE55",
                                        fontSize: "12px",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      {" "}
                                      VIEW EVENT
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        )}
                      </>
                    ))}
                  </div>
                  {/* <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div> */}
                </Swiper>
              </div>
            </div>

            <div className="col-1 p-0 d-flex justify-content-start">
              <i className="bi bi-caret-right-fill review-swiper-button-next"></i>
            </div>
          </div>

          <div className="text-center m-2">
            <Link to="/events"> View All Events </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpcomingEvents;
