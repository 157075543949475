import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { Paper } from "@mui/material";



const AllEvents = (props) => {

    const navigate = useNavigate();

    const [events, setEvents] = useState([]);
    const [filterEvent, setFilterEvent] = useState([]);


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        publicEvents(0, 5)
        console.log("child", props.events);

    }, [props.events]);


    const publicEvents = (s, e) => {

        var events = [];
        props.events.map((el) => {
            if (el.isShow === 1) {
                events.push(el)
            }
        })
        setEvents(events);
        setFilterEvent(events.slice(s, e));
    }


    const [i, setI] = useState(5);
    const next = (s, e) => {
        var start = i + s;
        var end = i + e;
        setFilterEvent(events.slice(start, end));
        setI(i + 5)

    }
    const previous = (s, e) => {
        var start = i - 10;
        var end = i - 5;
        setFilterEvent(events.slice(start, end));
        setI(i - 5)

    }




    const eventList = () => {
        return (
            <Paper>
                {/* <Divider sx={{ backgroundColor: 'black' }} /> */}
                {filterEvent && filterEvent.map((e) =>
                (


                    <div className="p-3">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="text-secondary">
                                    <strong>
                                        <span>

                                            {
                                                new Date(e.date).getDate() + ' ' +
                                                new Date(e.date).toLocaleString("default", { month: "short", })
                                                + "-" +
                                                new Date(e.date).getFullYear()
                                            }
                                        </span>
                                        <span>  {e.from_time && <> {e.from_time} - {e.to_time} </>}</span>
                                    </strong>
                                </div>
                                <div className="text-secondary">
                                    {new Date(e.date).toLocaleString('en-us', { weekday: 'long' })}
                                </div>
                                {
                                    new Date(e.date) > new Date() &&
                                    <div>
                                        <Button variant="contained" color="error" size="small">Check Now</Button>
                                    </div>
                                }



                            </div>
                            <div className="col-md-10 pb-2">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <div>
                                            <h4>
                                                <Link to={`/viewevents/${e.id}`}> {e.event} </Link>
                                            </h4>
                                        </div>
                                        {/* <div>
                                            {e.audience_participation == 1 ? 'Tickets' : ''}
                                            {e.audience_participation == 2 ? 'Walk-in' : ''}
                                            {e.audience_participation == 3 ? 'Registration' : ''}
                                            {e.audience_participation == 4 ? 'Close / private' : ''}
                                        </div> */}
                                        <div>
                                            <span className="text-secondary"> Category : </span> {e.purpose}
                                        </div>
                                    </div>


                                    <div>
                                        <Link to={`/viewevents/${e.id}`}>
                                            {
                                                e.cover !== 'no image' ?
                                                    <img src={`${process.env.REACT_APP_BACKEND}events/cover/${e.row.cover}`} width="80" style={{ margin: '15px' }} />
                                                    :
                                                    <>
                                                        <img src="./assets/defaultEvent.jpg" width="80" style={{ margin: '15px' }} />
                                                    </>
                                            }
                                        </Link>

                                    </div>
                                </div>




                            </div>



                        </div>
                        <Divider sx={{ backgroundColor: 'black' }} />
                    </div>

                ))}

                <div>

                    <div className="d-flex justify-content-between">

                        <Button disabled={i == 5 ? true : false} onClick={() => previous(0, 5)}>
                            Previous
                        </Button>
                        <Button disabled={!filterEvent.length ? true : false} onClick={() => next(0, 5)}>
                            Next
                        </Button>
                    </div>

                </div>



            </Paper>
        )
    }



    return (
        <>
            {eventList()}
            {/* <div className="row">
                <DataGrid
                    autoHeight
                    rowHeight={75}
                    rows={events}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[9]}
                />
            </div> */}
        </>
    )


}

export default AllEvents;