import React, { useEffect, useState } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import api from "../../API/api";


const About = () => {

  const [content, setShowContent] = useState(false);
  const [coremembers , setCoreMembers] = useState([]);
  const [members , setMembers] = useState([]);
  const [test, setTest] = useState(0);
  const [flip , setFlip] = useState(false);

  const ClickPra = () => {
    console.log("ee");
    setShowContent(true);

  }

  const ClickLess = () => {
    setShowContent(false);
  }

  const getMembers = async () => {
    await api.getMembers().then(res => {
      console.log(res);
      res.data.data.map(obj => {
        if (obj.role == 'Core Member') {
          coremembers.push({...obj ,flip : false})
        }
        if (obj.role == 'Member') {
          members.push({...obj , flip : false});
        }
        setTest(test + 1)
      })
    }).catch(err => {
      console.log(err);
    })
  }
  const handleFlip = (item) => {
    for(const obj of coremembers){
      if (obj.id == item.id) {
        obj.flip = true;
        console.log(obj);
        setTest(test + 1)
        break;
      }
    }
    for(const obj of members){
      if (obj.id == item.id) {
        obj.flip = true;
        console.log(obj);
        setTest(test + 1)
        break;
      }
    }
  }

  useEffect(() => {
    getMembers();
    console.log(coremembers
      ,members,'dj');
  },[])

  const Para = () => {
    return (
      <p>Our mission is to form a network dedicated to providing and utilizing a space, where artists and communities come together, share their interests and passion for art and culture in an inclusive, dynamic way that enhances wellbeing for all. It is where people have the opportunity to be creative, built connections and are able to maintain a positive wellbeing by actively engaging with various forms of art and culture.
        <br /><br />

        Agora started as an open floor space to be used as one saw fit. It saw many creative expressions from book readings to theatre meets but took the shape that it holds today with its first major initiative in the form of the eclectic art exhibition, 'Porisoy' on 26th April 2019. Since then, it has consistently altered its form to fit the role assigned to it as an art gallery, drama stage, and cozy listening room for live music or simply as a rehearsal space that breathes creativity. During the pandemic, it celebrated its one-year of being alive alone, without the community that made it what it was, exactly a year ago; it missed the bustle of vibrant voices, whispered trusts, enthusiastic cries of enthusiasm and the emotional sighs of seeing art come alive within its premised. During this time, it also realised the potential it holds to be much more than a passive space. Thus, as the journey of Agora continues into the years to come, it hopes to be a support beam for the new-age art community and a place for the participants, audiences, fellow facilitators to breathe life into their visions and inspire many to embrace the arts and dive in to find creative mediums of expression and communication for their thoughts, ideas and perceptions.</p>
    )
  }

  return (
    <>
      <section className="light-bg" data-aos="fade-up">
        <div className="text-center" data-aos="fade-up">
          <h1>About Us</h1>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-8  px-5 py-5 aboutText">
            <p>
            Agora translates to “Meeting Space” for people, is an eclectic art and cultural space focusing on the interaction of art, culture and positive well being.
              <br /><br />
              Performance Space for designers, artists and talents from various cultural and social research fields to mould it as per their conception and conduct workshops, exhibitions, discussions and events.

              Our mission is to form a network dedicated to providing and utilizing a space, where artists and communities come together, share their interests and passion for art and culture in an inclusive, dynamic way that enhances wellbeing for all. It is where people have the opportunity to be creative, build connections and are able to maintain a positive wellbeing by actively engaging with various forms of art and culture.

              Agora started as an open floor space to be used as one saw fit. It saw many creative expressions from book readings to theatre meets but took the shape that it holds today with its first major initiative in the form of the eclectic art exhibition, 'Porisoy' on 26th April 2019. Since then, it has consistently altered its form to fit the role assigned to it as an art gallery, drama stage, and cozy listening room for live music or simply as a rehearsal space that breathes creativity. During the pandemic, it celebrated its one-year of being alive alone, without the community that made it what it was, exactly a year ago; it missed the bustle of vibrant voices, whispered trusts, enthusiastic cries of enthusiasm and the emotional sighs of seeing art come alive within its premises. During this time, it also realized the potential it holds to be much more than a passive space. Thus, as the journey of Agora continues into the years to come, it hopes to be a support beam for the new-age art community and a place for the participants, audiences, fellow facilitators to breathe life into their visions and inspire many to embrace the arts and dive in to find creative mediums of expression and communication for their thoughts, ideas and perceptions.

              <br /><br />

              {content ? <Para /> : null}
              <div>
                {!content ? <span className="ReadBtn" style={{ 'cursor': 'pointer' }} onClick={ClickPra}>Read More...</span> : <span className="ReadBtn" style={{ 'cursor': 'pointer' }} onClick={ClickLess}>Read Less...</span>}


              </div>
            </p>
          </div>
        </div>
      </section>

      <section id="tabs" className="tabs about">
        <div className="container" data-aos="fade-up">

          <ul className="nav nav-tabs row d-flex">
            <li className="nav-item col-md-4 col-12">
              <a className="link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
                <h4 >VISION<ArrowDropDownIcon fontSize="large"/></h4>
              </a>
            </li>
            <li className="nav-item col-md-4 col-12">
              <a className="link" data-bs-toggle="tab" data-bs-target="#tab-2">
                <h4 >FOCUS AREA<ArrowDropDownIcon fontSize="large"/></h4>
              </a>
            </li>
            <li className="nav-item col-md-4 col-12">
              <a className="link" data-bs-toggle="tab" data-bs-target="#tab-3">
                <h4>SUPPORT TEAM<ArrowDropDownIcon fontSize="large"/></h4>
              </a>
            </li>
          </ul>

          <div className="row">
            <div className="col-lg-12 my-md-3" style={{ 'textAlign': 'center' }}>
              {/* <img src='/underline.png' className='underlineImage' /> */}
            </div>
          </div>

          <div className="tab-content">
            <div className="tab-pane active show" id="tab-1">
              <div className="row">
                <div className="col-lg-12 order-2 order-lg-1 mt-3 mt-lg-0 px-md-5 py-3"
                  data-aos="fade-up" data-aos-delay="100">

                  <p style={{ 'fontSize': 'medium', 'lineHeight': 2, 'textAlign': 'justify' }}>
                  Build a network of ARTISTS, DESIGNERS & INDIVIDUALS to share their interest and passion for art and culture and research fields belonging to various cultural and social communities in an inclusive, dynamic way to enhance positive wellbeing for all.
                    <br /><br />
                    <li> &#x2022; To enhance the wellbeing of people through arts-based education, activities on mindfulness and workshops based on creativity and technical skills.</li>

                    <li> &#x2022; To enhance social cohesion by bringing together artists and the community in a space that is open to creativity, learning and reflection.</li>

                    <li> &#x2022; To offer a platform to individual artists and groups that enables them to host arts based events and workshops.</li>
                    <br /><br />
                    AGORA emphasizes on individual capacity building on a collective level. Through its various art and cultural activities centered around well-being, we hope to contribute towards effective and holistic wellbeing of the community. At Agora, we also strive for enhancing social cohesion by bringing people together to interact along the lines of their respective social and cultural research fields.
                    <br /><br />

                    In the long run, AGORA aims to become a facilitator of art and cultural research in the region. Along with regularized workshops on different forms of art, it is imagined as an ‘incubator’ for art, design and cultural research which will enable academic works particular to North east India.
                    <br /><br />

                    Our mission is to form a network dedicated to providing and utilizing a space, where artists and communities come together, share their interests and passion for art and culture in an inclusive, dynamic way that enhances wellbeing for all.
                    <br /><br />

                    It is a performance space for designers, artists and talents from various art, cultural and social fields to curate their work, have the opportunity to conduct productive and impactful workshops, exhibitions, discussions and events which will create awareness, build connections which will in turn benefit the medium community. It is where people have the opportunity to be creative, build connections and are able to maintain a positive wellbeing by actively engaging with various forms of art and culture.

                  </p>

                </div>
              </div>
            </div>

            <div className="tab-pane" id="tab-2">
              <div className="row">
                <div className="about-sections"
                  data-aos="fade-up" data-aos-delay="100">

                  {/* <div className="col-sm-12 px-5 pt-5 d-flex justify-content-center">
                    <h1>Art & Cultural Cell</h1>
                  </div> */}

                  <div className="row sec">
                    <div className="col-md-6 text-center">
                      <img src="assets/event3.png" className="rounded" />
                    </div>
                    <div className="col-md-6">
                      <div className="col-sm-12 ">
                        {/* <span className="abtCircle2"></span> */}
                        <h1 className="sec-heading">Art & Cultural Cell</h1>
                      </div>
                      <p className="py-3" style={{ 'fontSize': 'large', 'lineHeight': 2, 'textAlign': 'justify' }}>
                      Along with our events, we also lay a special focus on skill training. We envision an open platform that allows mixed media workshops, starting from basic skill training to intermediate refresher workshops for artistes, especially for theatre. These workshops are conducted by both in-house resource persons as well as faculty of national and international importance. We facilitate advocacy and awareness through exhibitions, arts/movement - based therapy, artist/theatre clubs and events based on socio-cultural themes. We hone technical skills related to art and culture through workshops, technical residencies.

                      </p>
                      {/* <a href="#" className="rentBtn active">View Events</a> */}
                    </div>
                  </div>

                  <div className="row sec" data-aos="fade-up">
                    <div className="col-lg-1"></div>
                    <div className="col-md-4 d-flex align-items-center" >
                      <div>
                        <div className="col-sm-12 " >
                          {/* <span className="abtCircle"></span> */}
                          <h1 className="sec-heading">Event Management</h1>
                        </div>
                        <p className="py-3">Hone technical skills related to art and culture through workshops, technical residencies.</p>
                        {/* <a href="#" className="rentBtn active">View Events</a> */}
                      </div>
                    </div>

                    <div className="col-md-6 text-center">
                      <img src="assets/event1.png" className="rounded"/>
                    </div>
                  </div>

                  <div className="row sec">
                    <div className="col-md-6 text-center ">
                      <img src="assets/event2.png" className="rounded"/>
                    </div>
                    <div className="col-md-6">

                      <div className="col-sm-12" >
                        {/* <span className="abtCircle1"></span> */}
                        <h1 className="sec-heading">Research & Development</h1>
                      </div>


                      <p className="py-3" style={{ 'fontSize': 'medium', 'lineHeight': 2, 'textAlign': 'justify' }}>
                      Promote action - research, development field resources, document best practices which in turn will support the other two areas of Agora facilitates. We currently run a podcast titled ‘Living With The River’ chronicling the history of the Brahmaputra River and also highlighting various issues that explore the river through conversations with people who have been associated with it through their work in their respective fields. In 2021, we will be putting more focus on this regard as we would like to. <br /><br />
                        <li>Regularize research activities to bring forth such work from the North-East.</li>
                      </p>
                    </div>

                    <div className="col-md-12 mt-4" style={{ 'fontSize': 'medium', 'lineHeight': 2, 'textAlign': 'justify' }}>
                      {/* <i>Facilitate and promote scholars in the art and culture fields from the region as well as from the other parts of the world in their work on the region.</i>
                      <i>Undertake long-term social action-driven research projects on art/culture, mental wellbeing, and social themes etc.</i>
                      <i>Support and promote community-led initiatives for sustainability and preservation of heritage and environment etc.</i>
                      <i>Begin publishing more art-based research work in the form of articles, reviews, etc, in our blog.</i>
                      <i>Create space for audio/visual and physical documentation of art and related processes.</i> */}
                      <i>Facilitate and promote scholars in the art and cultural fields from the region as well as from the other parts of the world in their work on the region. Undertake long-term social action-driven research projects on art/culture, mental wellbeing, and social themes etc. Support and promote community-led initiatives for sustainability and preservation of heritage and environment etc.Begin publishing more art-based research work in the form of articles, reviews, etc, in our blog.Create space for audio/visual and physical documentation of art and related processes.
                    </i> 
                      <br />
                      {/* <a href="#" className="rentBtn active">View Events</a> */}
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="tab-pane" id="tab-3">
                  <div className="text-center py-3" data-aos="fade-up">
                      <h1>Core Members</h1>
                    </div>
                <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="100">
                    {
                      coremembers?.map((item,index) => {
                        return (
                          <>
                            <div key={index + 1} className={`team-card ${item.flip == true ? 'rotete' : ''}`}>
                              <div className="team-card-wrapper">
                                <div className="team-card-front">
                                  <div className="img">
                                    {
                                      item.img != null ?
                                      <img  src={`${process.env.REACT_APP_BACKEND}members/img/${item.img} `}   /> :
                                       <img  className="border border-danger" src='../../../default.jpg' /> 
                                     }
                                  </div>
                                  <div className="team-details">
                                    <h4 className="text">{item.name}</h4>
                                    <h6 className="text-white">{item.designation}</h6>
                                    <small ><button onClick={()=> handleFlip(item)}> View More</button></small>
                                  </div>
                                </div>
                                <div className='team-card-back rotete'>
                                  <div className="team-about p-2">
                                    <h4>{item.name}</h4>
                                    {
                                      item.description != 'undefined'  && item.description != null ?
                                        <p className="py-3">
                                          <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                          {item.description}
                                          &nbsp;<i className="bx bxs-quote-alt-right quote-icon-right"></i>&nbsp;
                                        </p>
                                        : <p></p>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                            </>
                        );
                      })
                    }
                </div>
                  <div className="text-center py-3" data-aos="fade-up">
                      <h1>Members</h1>
                    </div>
                <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="100">
                      {
                        members?.map((item,index) => {
                          return (
                            <>
                              <div key={index + 1} className={`team-card ${item.flip == true ? 'rotete' : ''}`}>
                                <div className="team-card-wrapper">
                                  <div className="team-card-front">
                                    {/* <div className="img mem"> */}
                                      {/* <img  src='../../../default.jpg'   />  */}
                                      <div className="img">
                                       <img  className="border border-danger" src='../../../default.jpg' /> 
                                      </div>
                                      <div className="team-details py-4">
                                        <div>
                                          <h4>{item.name}</h4>
                                          <h6>{item.designation}</h6>
                                        </div>
                                        <small ><button className="btn-underline" onClick={()=> handleFlip(item)}> View More</button></small>
                                      </div>
                                    {/* </div> */}
                                  </div>
                                  <div className="team-card-back rotete">
                                    <div className="team-about py-4">
                                    <h4>{item.name}</h4>
                                    {
                                      item.description != 'undefined' ?
                                        <p className="py-3 mb-4">
                                          <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                          {item.description}
                                          &nbsp;<i className="bx bxs-quote-alt-right quote-icon-right"></i>&nbsp;
                                        </p>
                                        : ''
                                    }
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </>
                          );
                        })
                      }
                </div>
              {/* <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="100">
                <div className="text-center py-3" data-aos="fade-up">
                  <h1>Core Members</h1>
                </div>

                <div className="team-card">
                  <div className="team-card-wrapper">
                    <div className="team-card-front">
                      <div className="img">
                        <img src="assets/m9.jpg" alt="" />
                      </div>
                      <div className="team-details text-center">
                        <h4 className="text-center">Radhika Goswami</h4>
                      </div>
                    </div>
                    <div className="team-card-back">
                      <div className="team-about py-4">
                        <h4>Radhika Goswami</h4>
                        <h6>Founder & Director</h6>
                        <p className="py-3"><i className="bx bxs-quote-alt-left quote-icon-left"></i>&nbsp;
                          Radhika Goswami is the Director of the Agora, The Space, an arts and culture facilitation centre in Guwahati, Assam, India. She had previously completed her MA in Social Anthropology from School of Oriental and African Studies, (SOAS), London. She is an alumnus of St. Stephen’s College, Delhi.<br /><br />
                          Her work entails the use of performance arts in conflict transformation which was underlined in her Master’s thesis titled ‘Intersection of Popular Culture and Protest Culture in Manipur’.<br /><br />
                          During 2016-17, She was awarded a research grant by Foundation for Social Transformation, Guwahati, Assam India to develop a manual for facilitators, ‘Theatre Techniques in Conflict Transformation for Adolescents’. The manual was based on Augusto Boal’s ‘Theatre of the Oppressed’ and Life Skills lessons used in psychology. She has been associated with theatre since school and she had been part of over 25 productions including 4 original productions written and directed by her. Her directorial venture ‘TBC’ as part of the SOAS Drama Society for the London Student Drama Competition was selected as one of the finalists and it was performed at the coveted Duchess Theatre in London. Her most recent work ‘Sentenced’ an experimental piece exploring the ideas of guilt and grief using mixed mediums received wide acclaims. Besides being a theatre practitioner, she has been associated with Institutes such as MIND India as a facilitator.
                          &nbsp;<i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="team-card">
                  <div className="team-card-wrapper">
                    <div className="team-card-front">
                      <div className="img">
                        <img src="assets/m1.jpg" alt="" />
                      </div>
                      <div className="team-details">
                        <h4 className="text-center">Nandini Handique</h4>
                      </div>
                    </div>
                    <div className="team-card-back">
                      <div className="team-about py-4">
                        <h4>Nandini Handique</h4>
                        <h6>A Movement Artist and an Actor.</h6>
                        <p className="py-3"><i className="bx bxs-quote-alt-left quote-icon-left"></i>&nbsp;She is associated with Agora 'The Space' since 2020. She performed in the devised Montage of Theatre, Music and Dance 'The Performed Pieces'. Since then she has been actively involved in all the endeavours the space has encountered.
                          <br /><br />

                          She says - ' Agora is Like Home. It is Safe and sound. It is where you can fall asleep as your thoughts awakens. One can be vulnerable, one can Find Life here, One can also create Life. '<br /><br />

                          She did her Graduation in Performing Arts (Indian Theatre) from Christ (Deemed to be University) , Bangalore and went on to do her Diploma in Film Acting from Barry John Acting Studio, Mumbai.<br /><br />

                          She is a trained Dancer and has been practicing it from an early age of 5.  In terms of Acting she has worked in and around Bangalore, Mumbai and Guwahati (Assam) primarily as a Theatre Practitioner.<br /><br />

                          She has been Awarded the Best Actor in Deccan Herald Theatre Festival, 2017,  Bangalore.
                          &nbsp;<i className="bx bxs-quote-alt-right quote-icon-right"></i>&nbsp;
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="team-card">
                  <div className="team-card-wrapper">
                    <div className="team-card-front">
                      <div className="img">
                        <img src="assets/m2.jpg" alt="" />
                      </div>
                      <div className="team-details">
                        <h4 className="text-center">Aveeva Saikia</h4>
                      </div>
                    </div>
                    <div className="team-card-back">
                      <div className="team-about py-4">
                        <h4>Aveeva Saikia</h4>
                        <h6>Graphic Designer</h6>
                        <p className="py-3"><i className="bx bxs-quote-alt-left quote-icon-left"></i>&nbsp;
                          Aveeva Saikia is the in-house Graphic Designer of Agora, The Space. She is pursuing her Bachelors in Economics from Cotton University, Guwahati. She began her career at Agora at the event ‘Porisoy,' when she was hired to create the design components for the entire event, and the rest is history. After discovering her passion for art merely at the end of her higher education, she swiftly progressed from conventional drawing to graphical illustration to graphic design, with more on the way. Otherwise, a freelancing Graphic Designer, she has worked across industries ranging from clothing brands to automobile industries.
                          &nbsp;<i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="team-card">
                  <div className="team-card-wrapper">
                    <div className="team-card-front">
                      <div className="img">
                        <img src="assets/m3.jpg" alt="" />
                      </div>
                      <div className="team-details text-center">
                        <h4 className="text-center">Raktim Gautam</h4>
                      </div>
                    </div>
                    <div className="team-card-back">
                      <div className="team-about py-4">
                        <h4>Raktim Gautam</h4>
                        <h6>Core Member</h6>
                        <p className="py-3"><i className="bx bxs-quote-alt-left quote-icon-left"></i>&nbsp;
                          Raktim Gautam is a writer/poet and an art facilitator who advocates for mental health awareness through Pink Freud, a mental health community that works towards bridging the gap between people with mental health issues and seeking therapy.<br /><br />

                          He has facilitated and curated several plays, performance showcases and spoken word platforms. He has also conducted several spoken word workshops.
                          &nbsp;<i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="team-card">
                  <div className="team-card-wrapper">
                    <div className="team-card-front">
                      <div className="img">
                        <img src="assets/m4.jpg" alt="" />
                      </div>
                      <div className="team-details text-center">
                        <h4 className="text-center">Manav Chaudhuri</h4>
                      </div>
                    </div>
                    <div className="team-card-back">
                      <div className="team-about py-4">
                        <h4>Manav Chaudhuri</h4>
                        <h6> Member</h6>
                        <p className="py-3"><i className="bx bxs-quote-alt-left quote-icon-left"></i>&nbsp;
                          Manav is a theatre and film practitioner, actor and writer and has worked on numerous theatre and film projects in Bangalore, Mumbai and Guwahati. He is currently a student of the MA Theatre Lab in the Royal Academy of Dramatic Art, London.<br /><br />


                          He has worked with Theatre at Agora, in various projects namely,

                          <li>Sentenced</li>
                          <li>Coupling in Playhouse Theatre Festival and Guwahati Theatre Festival</li>
                          <li>The Lesson  in Playhouse Theatre Festival</li>
                          <li>UNICEF Creative learning series</li>
                          <li>Performed Pieces (Devised Show)</li>
                          &nbsp;<i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="team-card">
                  <div className="team-card-wrapper">
                    <div className="team-card-front">
                      <div className="img">
                        <img src="assets/m5.jpg" alt="" />
                      </div>
                      <div className="team-details text-center">
                        <h4 className="text-center">Raina Bhattacharya</h4>
                      </div>
                    </div>
                    <div className="team-card-back">
                      <div className="team-about py-4">
                        <h4>Raina Bhattacharya</h4>
                        <h6>Core Member</h6>
                        <p className="py-3"><i className="bx bxs-quote-alt-left quote-icon-left"></i>&nbsp;
                          Raina is a development sector professional, who works primarily in the fields of gender and livelihood, LGBTQIA+, and human rights and democracy. Raina uses the arts, mostly theatre and music, as mediums to raise awareness about gender, financial literacy and women’s rights among the rural women of Assam. She also works with immersive theatre – a new age form of theatre which lets the audience be an active participant in the story and influence the plot. Raina has designed immersive experiences to raise awareness on women’s and LGBTQIA+ issues, primarily among the urban youth of Guwahati. Recently, she was one of the artistes selected globally to present her work “Democracy.Privacy.Security”, an online immersive theatre piece, at Boredom Rebellion 2021 which is an online youth art festival organized by Science Gallery, Dublin. <br /><br />

                          She has been a vocal pro-democracy and human rights activist through her art and work, and is a fellow with the Open Society Foundations, an organization that works for human rights and pro-democracy ideals globally. She is also currently working on a project, in association with Sustain+ and CInI (Centre for Integrated Livelihood, an initiative of TATA Trusts) to make renewable technology available to small and marginal women farmers in rural Assam. She was also selected as one of the top 60 youth leaders selected to attend the World Forum for Democracy, organized by the Council of Europe at Strasbourg, France. Because of her work during the Assam floods, and the rescue and relief operations that she was a part of, she has been awarded the She Inspires 2020 award, which is given annually to Indian women globally by Inspiring Indian Women, a non-profit based out of UK. The award was presented at the House of Commons, Parliament House, UK.<br /><br />

                          Raina is a part of a local art collective called East India Poets, and through that platform, has partnered with AGORA on their initiatives like Porisoy in 2019. She was also a part of “Performed Pieces” and co-facilitated “BaatorNatok”, a set of street plays organized in collaboration with RGVN at Morigaon, Assam. She was also one of the featured speakers for a podcast titled “On the Bright side”, conceived and implemented by AGORA in collaboration with KKHSOU and UNICEF.
                          &nbsp;<i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="team-card">
                  <div className="team-card-wrapper">
                    <div className="team-card-front">
                      <div className="img">
                        <img src="assets/m6.jpg" alt="" />
                      </div>
                      <div className="team-details text-center">
                        <h4 className="text-center">Mithil Raj Goswami</h4>
                      </div>
                    </div>
                    <div className="team-card-back">
                      <div className="team-about py-4">
                        <h4>Mithil Raj Goswami</h4>
                        <h6>Core Member</h6>
                        <p className="py-3"><i className="bx bxs-quote-alt-left quote-icon-left"></i>&nbsp;
                          I have been a part of Agora since 2019, just after I was done with my engineering. I have always been intrigued by the kind of vision that Agora had in making work and I was part of its realization in the first originally written theatrical production “Sentenced”. Thereafter I have been associated in doing performative readings and theatre performances in the form of cabaret. I have and always will be looking out to work in Agora where the work space is democratic and fluid and yet having a working structure that is suitable according to the production.
                          &nbsp;<i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="team-card">
                  <div className="team-card-wrapper">
                    <div className="team-card-front">
                      <div className="img">
                        <img src="assets/m7.jpg" alt="" />
                      </div>
                      <div className="team-details text-center">
                        <h4 className="text-center">Vedika Pareek</h4>
                      </div>
                    </div>
                    <div className="team-card-back">
                      <div className="team-about py-4">
                        <h4>Vedika Pareek</h4>
                        <h6>Core Member</h6>
                        <p className="py-3"><i className="bx bxs-quote-alt-left quote-icon-left"></i>&nbsp;
                          I was introduced to Agora in 2019 when I was given a chance to act in it’s theatre production, ‘Sentenced’, and then subsequently in the play ‘Coupling’ as production crew. Subsequently, I have been involved in curating the podcast- Living with the River, with Radhika Goswami.<br /><br />

                          I entered this space straight out of law school, and I had hardly done any work in theatre, yet, the space greeted me with so much warmth and energy- firstly, in encouraging me to participate in and learn about theatre and theatre production, and second, in encouraging me explore my own ideas about using the space and ruminating on its values, which led to creation of the podcast- lwtr. This supportive culture at Agora has allowed it to bring together a wonderful community of collaborators who keep expanding the contours of its work- from theatre, scriptwriting, group reading, socio-political research, making music, producing podcasts, art and animation, and photography, and I am so proud to be associated with this space.
                          &nbsp;<i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="team-card">
                  <div className="team-card-wrapper">
                    <div className="team-card-front">
                      <div className="img">
                        <img src="assets/m8.jpg" alt="" />
                      </div>
                      <div className="team-details text-center">
                        <h4 className="text-center">InJoy<br /> (Indrani and Pranjoy)</h4>
                      </div>
                    </div>
                    <div className="team-card-back">
                      <div className="team-about py-4">
                        <h4>InJoy <br />(Indrani and Pranjoy)</h4>
                        <h6>Musical Couple</h6>
                        <p className="py-3"><i className="bx bxs-quote-alt-left quote-icon-left"></i>&nbsp;
                          InJoy is a husband wife musical duo of Indrani & Pranjoy, who have been performing together since 1999. InJoy mostly do acoustic renditions of English rock and pop songs from the 80's and the 90's. They have original compositions as well. Music brought the two of them together and that is what keeps them happy, sane and alive. They love to share their happiness and to spread the goodness of music around wherever they go.<br /><br />

                          InJoy has been associated with Agora' projects and performances since 2019 through acting, songwriting, composing songs and background music. They provided the musical elements in the UNICEF and KKH project 'On The Brightside' - a video series for children on ways to cope with the COVID pandemic. They also provided the music for Agora's theatrical production 'Performed Pieces'. Indrani has acted in two of Agora's productions - 'Sentenced' and 'Coupling'.
                          &nbsp;<i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="team-card">
                  <div className="team-card-wrapper">
                    <div className="team-card-front">
                      <div className="img">
                        <img src="assets/m10.jpg" alt="" />
                      </div>
                      <div className="team-details text-center">
                        <h4 className="text-center">Kulanandini Mahanta</h4>
                      </div>
                    </div>
                    <div className="team-card-back">
                      <div className="team-about py-4">
                        <h4>Kulanandini Mahanta</h4>
                        <h6>Co-Founder</h6>
                        <p className="py-3"><i className="bx bxs-quote-alt-left quote-icon-left"></i>&nbsp;
                          Kulanandini Mahanta is one of the co founders of Agora. She is a cinematographer by profession and has also donned the cap of a director. She is one of those rare female film cinematographers and directors of North East India.<br /><br />

                          Armed with a marketing degree from Eastern Michigan University and a degree in Cinematography from the Colorado film school, Kulanandini worked in the tinsel town of hollywood for a year. In her stint in Los Angeles she had had the good fortune of working with a host of upcoming directors and producers in the Indie film circuit and also in the circuit of New York Film Academy, Los Angeles and American Film School, Los Angeles.<br /><br />

                          In the past Kulanandini has trained under an ace theater master from National School of Drama and continued her passion for the arts at Eastern Michigan University, by acting in plays such as Hotel Paradiso, where she was chosen to be one of the young daughters in the play. She was also elected to the office of the President of International Students' Association at Eastern Michigan University in the year 2012-2013, thereby expanding her horizons and leadership skills by organizing Colors in Harmony fest for university students with her cabinet of elected student leaders. She also served as one of the student leaders in the University president's student leader council. Kulanandini also volunteered to teach students at a Lakota Native American Reservation while working as a professional in Los Angeles. Her experience at the reservation was an eye openeing one after looking at the plight of people in that remote reservation of a developed country. She took the challenge to educate the children on her stride and brought her unique theatre, art and dance skills to program an educational and inspirational session for those children.<br /><br />

                          She brings the same dynamism and vision to Agora and aspires to invigorate and give back to our beloved North East India
                          &nbsp;<i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default About;