import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import AlarmIcon from '@mui/icons-material/Alarm';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import DynamicModal from './dynamicModal';
import { useNavigate } from 'react-router-dom';


const StyledMenu = styled((props) => (

 


  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function Menus(props) {

  const navigation = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleOpen = () => {
    props.handleOpen(props.data)
  }

  const [open2, setOpen2] = React.useState(false)

  const handleOpenDynamic = () => {
    setOpen2(true)
  }
  const handleCloseDynamic = () => {
    setOpen2(false)
  }

  const handleApprove = (type) => {
    // props.handleApprove(props.data);
    props.handleClickOpen(type, props.data);
    handleClose()
  }
  const handleDelay = () => {
    handleClose()
    props.handleDelay(props.data.user_id)
  }
  const handlePaymentConform = () => {
    handleClose()
    props.handlePaymentConform(props.data.user_id)
  }

  // const goto=()=>{
  //   navigation
  // }

  return (
    <>
      {props.data.status == 4 ?
        <>
          <Button
            color='warning'
            variant="contained"
            onClick={()=>navigation(`/admin/addevents/${props.data.user_id}`)}
            size='small'
            endIcon={<ArrowRightIcon />}
          >
            Add Event
          </Button>
        </> :
        <>

          <div>
            <Button
              id="demo-customized-button"
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              size='small'
            >
              Options
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              

              <MenuItem onClick={() => handleApprove('approve')} disableRipple disabled={props.data.status == 1 ? true : false}>
                <AssignmentTurnedInIcon />
                Approve
              </MenuItem>


              <MenuItem onClick={() => handleApprove('reschedule')} disableRipple disabled={props.data.status == 2 ? true : false}>
                <AirlineStopsIcon />
                Reshedule
              </MenuItem>
              <MenuItem onClick={handleDelay} disableRipple disabled={props.data.status == 3 ? true : false}>
                <AlarmOffIcon />
                Delay
              </MenuItem>
              <MenuItem onClick={handlePaymentConform} disableRipple disabled={props.data.status == 1 || props.data.status == 0  ? false : true}>
                <AssignmentTurnedInIcon />
                 Approve Payment
              </MenuItem>

              {/* <Divider sx={{ my: 0.5 }} />
              <MenuItem onClick={handleClose} disableRipple>
                <ArchiveIcon />
                Archive
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                <MoreHorizIcon />
                More
              </MenuItem> */}
            </StyledMenu>

            {/* <DynamicModal open={open2} handleClose={handleClose} dynamicForm='ABCD'/> */}
            {/* {rentalTable()} */}
          </div>

        </>
      }


    </>
  );
}
