import React from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { Paper } from '@mui/material';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';




const RecentEvents = (props) => {
    const navigation = useNavigate();
    return (
        <>
            <Paper elevation={4} sx={{ maxWidth: 670 }}>
                <div className=' p-2 pt-3'>
                    <h5> <b> Recent Events</b> </h5>
                    <hr />
                </div>

                <div className=''>

                    <Swiper

                        slidesPerView={1}
                        // pagination={{ clickable: true }}
                        // spaceBetween={10}
                        loop={true}
                        autoplay={true}
                        // navigation={{ clickable: true }}
                        modules={[Autoplay, Pagination, Scrollbar]}
                        className="testimonials-slider swiper"
                        data-aos="fade-up"
                        data-aos-delay="100"
                    >

                        {props.events && props.events.splice(0, 5).map((ev) => (
                            <>

                                {ev.isShow == 1 &&
                                    <SwiperSlide>

                                        <CardMedia
                                            sx={{ height: 240 }}
                                            image="/assets/defaultEvent.jpg"
                                            title="green iguana"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {ev.event}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {ev.description.substring(0, 150)} ...
                                            </Typography>
                                        </CardContent>
                                        <CardActions className='text-center'>

                                            <Button onClick={() => navigation(`/viewevents/${ev.id}`)} size="small" variant='contained' color='error'>View</Button>

                                        </CardActions>
                                    </SwiperSlide>
                                }
                            </>
                        ))}


                    </Swiper>
                </div>

            </Paper>
        </>
    )
}

export default RecentEvents