import React, { useState, useEffect } from 'react'


const EventPeople = (props) => {


    // cast
//     const [castinputFields, setCastInputFields] = useState([{
//         fullName: '',
//     }]);
// 
//     const castaddInputField = () => {
//         setCastInputFields([...castinputFields, {
//             fullName: '',
//         }])
// 
//     }
//     const castremoveInputFields = (index) => {
//         const rows = [...castinputFields];
//         rows.splice(index, 1);
//         setCastInputFields(rows);
//     }
//     const casthandleChange = (index, evnt) => {
//         const { name, value } = evnt.target;
//         const list = [...castinputFields];
//         list[index][name] = value;
//         setCastInputFields(list);
// 
//         console.log(castinputFields);
//     }


    // crew 
//     const [crewinputFields, setCrewInputFields] = useState([{
//         fullName: '',
//     }]);
// 
//     const crewaddInputField = () => {
//         setCrewInputFields([...crewinputFields, {
//             fullName: '',
//         }])
// 
//     }
//     const crewremoveInputFields = (index) => {
//         const rows = [...crewinputFields];
//         rows.splice(index, 1);
//         setCrewInputFields(rows);
//     }
//     const crewhandleChange = (index, evnt) => {
//         const { name, value } = evnt.target;
//         const list = [...crewinputFields];
//         list[index][name] = value;
//         setCrewInputFields(list);
// 
//         console.log(crewinputFields);
//     }


    // guest 
//     const [guest, setGuest] = useState();
// 
//     const handleguest = (e) => {
//         setGuest(e.target.value);
//     }

const [firstLabel, setFirstlabel] = useState('');
const [secondLabel, setSecondlabel] = useState('');

useEffect(()=>{
    firstLabelConditions();
}, [])

const firstLabelConditions = ()=>{
    if(props.formData.purpose == 'Theatre/Play'){
        setFirstlabel('Casts');
        setSecondlabel('Production Crew')
        return;
    }
    if(props.formData.purpose == 'Film Screenings'){
        setFirstlabel('Casts');
        setSecondlabel('Production Crew')
        return;
    }

    if(props.formData.purpose == 'Music & Poetry Nights'){
        setFirstlabel('Artist Lineup');
        setSecondlabel('Featuring Artist')
        return;
    }
    if(props.formData.purpose == 'Open Mic'){
        setFirstlabel('Artist Lineup');
        setSecondlabel('Featuring Artist')
        return;
    }
    
    if(props.formData.purpose == 'Talk / Lecture Series'){
        setFirstlabel('Resource Person');
        setSecondlabel('Guest of Honour')
        return;
    }
    if(props.formData.purpose == 'Share Circles'){
        setFirstlabel('Lead counsellor');
        setSecondlabel('Organizer')
        
        return;
    }
    if(props.formData.purpose == 'Exhibitions & Art Gallery'){
        setFirstlabel('Category');
        setSecondlabel('Session Breakup')
        return;
    }
    if(props.formData.purpose == 'Book readings'){
        setFirstlabel('Genre');

        setSecondlabel('Author Name')
        return;
    }
    if(props.formData.purpose == 'Practise for a play / performanc'){
        setFirstlabel('Artist Lineup');

        setSecondlabel('Organizer')
        return;
    }
    if(props.formData.purpose == 'Shoot / Document a project'){
        setFirstlabel('Resource Person');

        setSecondlabel('Organizer')
        return;
    }
    if(props.formData.purpose == 'Plan / Brainstorm for a project'){
        setFirstlabel('Resource Person');

        setSecondlabel('Organizer')
        return;
    }
    else{
        setFirstlabel('Cast');

        setSecondlabel('Organizer')
        return;
    }
}

    return (

        <section id="eventForm" className="eventForm">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="php-email-form">

                            {/* for cast  */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className='col-2'>
                                        <label>{firstLabel}</label>
                                    </div>
                                    {
                                        props.castinputFields.map((data, index) => {
                                            const { fullName, emailAddress, salary } = data;
                                            return (
                                                <div className="row my-3" key={index}>

                                                    <div className="col-11">
                                                        <div className="form-group">
                                                            <input type="text" onChange={(evnt) => props.casthandleChange(index, evnt)} value={fullName} name="fullName" className="form-control" placeholder='Full Name' />
                                                            <button className="btn btn-outline-success " onClick={props.castaddInputField}  style={{'marginTop':'5px', 'fontSize':'12px'}}>Add More</button>
                                                        </div>
                                                    </div>

                                                    <div className="col-1 text-end">
                                                        {(props.castinputFields.length !== 1) ? <button className="btn btn-outline-danger" onClick={props.castremoveInputFields}>x</button> : ''}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-sm-4">
                            </div>

                            {/* for production crew  */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className='col-2'>
                                        <label>{secondLabel}</label>
                                    </div>
                                    {
                                        props.crewinputFields.map((data, index) => {
                                            const { fullName, emailAddress, salary } = data;
                                            return (
                                                <div className="row my-3" key={index}>

                                                    <div className="col-11">
                                                        <div className="form-group">
                                                            <input type="text" onChange={(evnt) => props.crewhandleChange(index, evnt)} value={fullName} name="fullName" className="form-control" />
                                                            <button className="btn btn-outline-success " onClick={props.crewaddInputField} style={{'marginTop':'5px', 'fontSize':'12px'}}>Add More</button> <br/>
                                                        </div>
                                                    </div>

                                                    <div className="col-1 text-end">
                                                        {(props.crewinputFields.length !== 1) ? <button className="btn btn-outline-danger" onClick={props.crewremoveInputFields}>x</button> : ''}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }


                                </div>
                            </div>


                            <div className="col-11">
                                <div className=''>
                                    <label>Special Guest</label>
                                </div>
                                <div className="form-group">
                                    <input type="text" name="fullName" value={props.guest} onChange={props.handleguest} className="form-control" />
                                </div>
                            </div>
                            
                            <div className="col-11">
                                <div className=''>
                                    <label>Sponser/Promoter</label>
                                </div>
                                <div className="form-group">
                                    <input type="text" name="fullName" value={props.sponser} onChange={props.handlesponser} className="form-control" />
                                </div>
                            </div>



                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}

export default EventPeople;