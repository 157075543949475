import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import api from "../../API/api";


const Testimonial = () => {

    const [rows, setRows] = useState();

    const getTestimonial = () => {
        api.getTestimonial().then((res) => {
            console.log("res", res);
            setRows(res.data.data)
        })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getTestimonial();
    }, [])


    return (
        <section id="testimonials" className="testimonials light-bg">
            <div className="container" data-aos="fade-up">

                <div className="text-center">
                    <h5 className='sub-title'>SEE WHAT OUR CLIENTS SAY</h5>
                    <h1>Testimonial</h1>
                </div>

                {rows &&
                    <Swiper slidesPerView={1}
                        pagination={{ clickable: true }}
                        loop={true}
                        navigation={{ clickable: true }}
                        modules={[Autoplay, Pagination, Navigation]}
                        data-aos="fade-up"
                        autoplay={true}
                        data-aos-delay="100"
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            // when window width is >= 480px
                            480: {
                                slidesPerView: 1,
                            },
                            // when window width is >= 640px
                            640: {
                                slidesPerView: 1,
                            }
                        }}
                    >
                        <div className="swiper-wrapper">
                            {rows && rows.map((e) => (
                                <SwiperSlide >
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item text-center">
                                            {/* <img src="assets/testimonials/default.png" className="testimonial-img" alt="" /> */}
                                            <h5> <InsertEmoticonIcon /> <strong> {e.name}</strong></h5>
                                            <p >
                                                {e.description}
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>

                            ))}


                        </div>
                    </Swiper>
                }
            </div>
        </section>
    )
}

export default Testimonial;