import React, { useState, useEffect } from "react";

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import api from "../../API/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        address: '',
        message: '',
    })
    const [load, setLoad] = useState(false)

    const handleInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        console.log(formData);
        setLoad(true);
        api.postCountactUs(formData).then((res) => {
            console.log(res);
            setLoad(false)
            toast.success('Your Request Submitted Successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        })
            .catch((err) => {
                console.log(err.response);
            })
    }

    return (
        <>
            <ToastContainer />
            <section className="light-bg">
                <div className="container">
                    <div className="row">
                        <div className="text-center">
                            <h1>Contact Us</h1>
                        </div>
                        <div className="col-lg-6">
                            <div className="php-email-form bg-light p-md-5" >
                                <div className="row">

                                    <div className="col form-group">
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { p: 1, width: '100%' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField id="outlined-basic" name="name" label="Full name" value={formData.name} onChange={handleInput} variant="outlined" />
                                        </Box>

                                    </div>
                                    <div className="col form-group">
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { p: 1, width: '100%' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField type="number" id="outlined-basic" name="phone" value={formData.phone} onChange={handleInput} label="Phone" variant="outlined" />
                                        </Box>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { p: 1, width: '100%' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <TextField id="outlined-basic" name="email" value={formData.email} onChange={handleInput} label="Email" variant="outlined" />
                                    </Box>
                                </div>
                                <div className="form-group">
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { p: 1, width: '100%' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <TextField id="outlined-basic" name="address" value={formData.address} onChange={handleInput} label="Address" variant="outlined" />
                                    </Box>
                                </div>
                                <div className="form-group">
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { p: 1, width: '100%' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <TextField id="outlined-basic" name="message" value={formData.message} onChange={handleInput} label="Your Message" variant="outlined" />
                                    </Box>
                                </div>
                                <div className="text-center pt-3"><button type="submit" onClick={handleSubmit} disabled={load? true: false}>Join Now</button></div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="my-5">
                                <h3><i className="bi bi-geo-alt-fill"></i> &nbsp;Location</h3>
                                <p>2nd Floor, Anuradha Cineplex, Commercial Complex East, Bamunimaidan, Guwahati, Assam 781021</p>
                            </div>
                            <div className="my-4">
                                <h3><i className="bi bi-envelope-at-fill"></i>&nbsp;Email</h3>
                                <a href="https://agorathespace.com/mailTo:agorathespace@gmail.com">agorathespace@gmail.com</a>
                            </div>
                            <div className="my-3">
                                <h3><i className="bi bi-telephone-plus-fill"></i>&nbsp;Call</h3>
                                <a href="https://agorathespace.com/tel:+919711905346">+91 9711905346</a>
                            </div>
                        </div>
                        <div className="text-center my-4">
                            <h1>Find Us Easily</h1>
                        </div>
                        <div className="col-lg-12">
                            <div className="mapouter my-3 text-center">
                                <div className="gmap_canvas">
                                    <iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=agora the space guwa&t=&z=10&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Contact;