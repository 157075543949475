import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';
import { useNavigate } from "react-router-dom";


const HeroSection = () => {

    const navigate = useNavigate();


    return (
        <Swiper slidesPerView={1}
            loop={true}
            modules={[Autoplay, Scrollbar, Pagination]}
            //autoplay={false}
            autoplay={{
                delay: 8000,
                disableOnInteraction: false,
            }}
            speed={1500}
            pagination={{ clickable: true }}
        >
            {/* <SwiperSlide>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container" data-aos="zoom-out" data-aos-delay="100">
                        <div className="row">
                            <div className="col-xl-12 text-center">
                                <h1 className="font-weight-light" style={{'lineHeight':2}}>Art . Culture . Wellbeing . </h1>
                                <a href="#" className="btn-get-started scrollto">Get Started</a>
                            </div>
                        </div>
                    </div>
                </section>
            </SwiperSlide> */}

            <SwiperSlide>
                <section id="hero1" className="d-flex align-items-center">
                    <div className="container" data-aos="zoom-out" data-aos-delay="100">
                        <div className="row">
                            <div className="col-xl-12 text-center">
                                <h1 className="font-weight-light" style={{'lineHeight':2}}>Art . Culture . Wellbeing . </h1>
                                <bttton onClick={()=>navigate('events')} className="btn-get-started scrollto">Explore events</bttton>
                            </div>
                        </div>
                    </div>
                </section>
            </SwiperSlide>

            {/* <SwiperSlide>
                <section id="hero2" className="d-flex align-items-center">
                    <div className="container" data-aos="zoom-out" data-aos-delay="100">
                        <div className="row">
                            <div className="col-xl-12 text-center">
                                <h1 className="font-weight-light" style={{'lineHeight':2}}>Art . Culture . Wellbeing . </h1>
                                <a href="#" className="btn-get-started scrollto">Get Started</a>
                            </div>
                        </div>
                    </div>
                </section>
            </SwiperSlide> */}
        </Swiper>
    )
}

export default HeroSection;