import React from "react";

const Footer = () => {
    return (
        <>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row ">

                            <div className="col-lg-6 footer-contact ">
                                <a href="/" className="logo me-auto text-white"><img src="white-logo.png" alt="" height={70} /></a>
                                <p>Agora is a multipurpose art space used for a <br/>
                                    number of creative processes such as theatre <br/>
                                    shows, music and poetry nights, film <br/>
                                    screening and many more
                                </p>
                            </div>

                            <div className="col-lg-2 col-md-3 footer-links">
                                <h4>QUICK LINKS</h4>
                                <ul>
                                <li><i className="bx bx-chevron-right"></i> <a href="/">HOME</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="/about">ABOUT</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="/rental">RENTAL</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="/events">EVENTS</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="/club">CLUBS</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-2 col-md-3 footer-links">
                                <h4>QUICK LINKS</h4>
                                <ul>
                                <li><i className="bx bx-chevron-right"></i> <a href="/member">BECOME A MEMBER</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="/blog">BLOG</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="/gallery">MEDIA & GALLERY</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="/rd">RESEARCH & DEV</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-2 col-md-3 footer-links" >
                                <h4>GET IN TOUCH</h4>
                                <ul>
                                    <li><a href="https://agorathespace.com/tel:+919711905346">+91 9711905346</a></li>
                                    <li><a href="https://agorathespace.com/mailTo:agorathespace@gmail.com">agorathespace@gmail.com</a></li>
                                </ul>
                                <br/>

                                <h4>FOLLOW US</h4>
                                <ul>
                                    <a href="https://facebook.com/agorathespace" className="facebook"><i className="bx bxl-facebook"></i></a>
                                    <a href="https://www.instagram.com/agora_thespace/" className="instagram"><i className="bx bxl-instagram"></i></a>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container d-md-flex">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="copyright mb-4">
                                &copy; Copyright <strong><span><a className="text-white" href="/">Agora</a></span></strong>. All Rights Reserved
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;