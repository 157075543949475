import React, {useEffect} from "react";
// import Header from "../component/header";
import Header from "../component/common/header";
import Footer from "../component/common/footer";
import Member from "../component/member/member";

const MemberPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return (
        <>
            <Header />
            <Member />
            <Footer />
        </>
    )
}

export default MemberPage;