import React, {useEffect} from "react";
import Header from '../component/common/header';
import Footer from '../component/common/footer';
import Imagegallery from "../component/gallery/allImages";

const Gallery = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return (
        <>
            <Header />
            <Imagegallery />
            <Footer />
        </>
    )
}

export default Gallery;