import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import api from '../../../API/api';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import LinearProgress from '@mui/material/LinearProgress';

import CreateIcon from '@mui/icons-material/Create';




import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';







const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const RentalRequest = () => {
  const navigate = useNavigate();

  const [lineloader, setLineLoader] = useState(false);


  const [events, setEvents] = useState([]);
  const [rentals, setRentals] = useState([]);
  useEffect(() => {
    getEvents()
  }, []);


  const getEvents = () => {
    setLineLoader(true);
    api.getEvents()
      .then((res) => {
        console.log(res);
        setEvents(res.data.events);
        setLineLoader(false);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const handleFeature = (id, isShow) => {
    console.log(id);

    if (isShow === 1) {
      var msg = 'Are you sure want to make it Private?'
    }
    else {
      var msg = 'Are you sure want to make it Public'
    }

    Swal.fire({
      title: msg,
      text: "It will work for Public view",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {

        api.enableEvent(id)
          .then((res) => {
            console.log(res);
            getEvents();
          })
          .catch((err) => {
            console.log(err.response);
          })



      }
    })





  }


  const columns = [
    { field: 'user_id', headerName: 'ID', width: 150 },
    // {
    //   field: 'cover', headerName: 'Cover', width: 230,
    //   renderCell: (field) => (
    //     <img src={`${process.env.REACT_APP_BACKEND}events/cover/${field.row.cover}`} width="100" />
    //   ),
    // },
    { field: 'event', headerName: 'Event', width: 350 },
    { field: 'purpose', headerName: 'Purpose', width: 130 },

    {
      field: 'date', headerName: 'Date And Time', width: 210,
      renderCell: (field) => (

        <span> {field.row.date} {field.row.from_time && <> ( {field.row.from_time} - {field.row.to_time} ) </>}</span>
      ),
    },

    {
      field: 'isShow', headerName: 'Action', width: 300,
      renderCell: (field) => (
        <>
          <Button
            variant="contained"
            size="small"
            color={field.row.isShow === 1 ? 'success' : 'secondary'}
            onClick={() => handleFeature(field.row.id, field.row.isShow)}
          >
            {field.row.isShow === 1 ? 'Public' : 'Private'}

          </Button>
        </>
      ),
    },

  ];


  const table = () => {
    return (
      <>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead className='bg-dark'>
              <TableRow >
                <TableCell className='text-white'>User Id</TableCell>
                <TableCell className='text-white' >Event Name</TableCell>
                <TableCell className='text-white' >Purpose</TableCell>
                <TableCell className='text-white' >Date And Time</TableCell>
                <TableCell className='text-white' align="right">Visibility</TableCell>
                <TableCell className='text-white' align="right">Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events && events.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.user_id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.event}
                  </TableCell>
                  <TableCell >{row.purpose}</TableCell>
                  <TableCell >{row.date}</TableCell>
                  <TableCell align="right">

                    <Button
                      variant="contained"
                      size="small"
                      color={row.isShow === 1 ? 'success' : 'secondary'}
                      onClick={() => handleFeature(row.id, row.isShow)}
                    >
                      {row.isShow === 1 ? 'Public' : 'Private'}

                    </Button>
                  </TableCell>

                  <TableCell > <Button
                    variant="contained"
                    size="small"
                    color='success'
                    onClick={() => navigate(`/admin/addevents/edit/${row.id}`)}

                  >
                    <CreateIcon />
                  </Button></TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }


  return (
    <>
      {lineloader &&
        <LinearProgress />
      }
      {table()}

      {/* <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={events}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[9]}
        />
      </div> */}

    </>
  );

}

export default RentalRequest;