import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';


const UserForm = (props) => {
    return (
        <>
            <div className="col-lg-6 my-2">
                <div role="form" className="php-email-form mt-4" style={{ 'background': '#ffffff' }}>
                    <div className="row">
                        {props.BackData}
                        <div className="col-md-6 mt-3 form-group">
                            <TextField fullWidth id="standard-basic" name="name" value={props.FormData.name} onChange={props.handleInput} label="Your Full Name" variant="standard" />


                        </div>
                        <div className="col-md-6  mt-3 form-group">
                            <TextField fullWidth id="standard-basic" name="email" value={props.FormData.email} onChange={props.handleInput} label="Email" variant="standard" />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mt-3 form-group">
                            <TextField fullWidth id="standard-basic" name="phone" value={props.FormData.phone} onChange={props.handleInput} label="Phone No" variant="standard" />
                        </div>
                        <div className="col-md-6 mt-3 form-group">
                            <TextField fullWidth id="standard-basic" name="description" value={props.FormData.description} onChange={props.handleInput} label="Short Event Description" variant="standard" />
                        </div>
                    </div>

                    {!props.isSuccess ?
                        <div className="text-center" onClick={props.handleSubmit} style={{ 'paddingBottom': '15px' }}><button type="submit">Send Request</button>
                        </div>
                        :

                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="success">We will get back you soon, Please Check Your email !</Alert>
                        </Stack>
                    }
                </div>

                <div className="text-center mt-4">
                    <span className="dotPage">1</span>
                    <span className="dotPage">2</span>
                    <span className="dotPage activeDot">3</span>
                </div>
            </div>
        </>
    )
}

export default UserForm;