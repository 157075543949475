import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import api from '../../../API/api';
import { Button } from '@mui/material';
import DialogImg from './galleryDialog';


export default function ManageGallery() {

    const [rows, setRows] = useState();
    const [open, setOpen] = useState(false);
    const [imageEdit , setImageEdit] = useState();
    // const handleClickOpen = () => {
    //     setOpen(true);

    // };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        getGallery()
    }, []);

    const getGallery = () => {
        api.getGallery().then((res) => {
            console.log(res);
            setRows(res.data.gallery)
        })
            .catch((err) => {
                console.log(err.response);
            })
    }


    const manageStatus = (type, value, id) => {
        console.log(type, value, id);
        api.manageGalleryStatus(type, value, id)
            .then((res) => {
                console.log(res);
                getGallery();
            })
            .catch((err) => {
                console.log(err.response);
            })

    }

    const handleDelete = (row) => {
        api.deleteGalleryImage(row).then(res => {
            getGallery();
            console.log(res , 'res');
        }).catch(err => {
            console.log(err , 'err');
        })
    }
    const handleEdit = (row) => {
        setOpen(true);
        setImageEdit(row);

    }


    return (

        <>
            <Paper>
                <DialogImg handleClose={handleClose} 
                // handleClickOpen={handleClickOpen}
                 setOpen={setOpen} open={open}  imageEdit={imageEdit}/>
                {/* <Button variant='contained' className='float-end'>+ Add Gallery</Button> */}
                {/* <Button variant='contained' onClick={handleClickOpen} className='float-end'>+ Add Gallery</Button> */}
            </Paper>
            <br />
            <br />

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow className='bg-warning'>
                            <TableCell>Image</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Caption</TableCell>
                            <TableCell>Venue</TableCell>
                            <TableCell>On Page Status</TableCell>
                            <TableCell align="right">Active Status</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows && rows.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell> <img src={`${process.env.REACT_APP_BACKEND}gallery/${row.img} `}  height={90} />  </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.category}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.caption}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.venue}
                                </TableCell>
                                <TableCell>
                                    {row.onPage === 1 ?
                                        <Button onClick={() => manageStatus('onPage', row.onPage, row.id)}>Active</Button>
                                        :
                                        <Button onClick={() => manageStatus('onPage', row.onPage, row.id)} color='error'>In Active</Button>
                                    }

                                </TableCell>
                                <TableCell align="right">
                                    {row.isActive === 1 ?
                                        <Button onClick={() => manageStatus('status', row.isActive, row.id)}>Active</Button>
                                        :
                                        <Button onClick={() => manageStatus('status', row.isActive, row.id)} color='error'>In Active</Button>
                                    }

                                </TableCell>
                                <TableCell align="right">
                                    <button onClick={() => handleEdit(row)} className='btn btn-sm btn-info mx-1'>Edit </button>
                                    <button onClick={() => handleDelete(row)} className='btn btn-sm btn-danger'>Delete </button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );
}