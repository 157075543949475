import React, { useEffect, useState } from "react";


import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';
import api from "../../API/api";

const htmlToFormattedText = require("html-to-formatted-text");

const Blog = () => {

    const [blogs, setBlogs] = useState();

    useEffect(() => {
        getBlogs();
    }, []);

    const getBlogs = () => {
        api.getBlog()
            .then((res) => {
                console.log(res);
                setBlogs(res.data.allblogs)
            })
            .catch((err) => {
                console.log(err.response);
            })
    }


    return (
        <section id="team" className="team ">
            <div className="bg-1"></div>
            <div className="container" data-aos="fade-up">
                <div className="text-center">
                    <h1>Our Blogs</h1>
                </div>

                <div className='row py-3'>

                    <div className='col-1 p-0 d-flex justify-content-end'>
                        <i className="bi bi-caret-left-fill review-swiper-button-prev"></i>
                    </div>

                    <div className='col-10 p-0'>
                        <div className='p-0'>
                            <Swiper
                                slidesPerView={4}
                                // pagination={{ clickable: true }}
                                spaceBetween={10}
                                loop={false}
                                autoplay={false}
                                navigation={{
                                    clickable: true,
                                    nextEl: '.review-swiper-button-next',
                                    prevEl: '.review-swiper-button-prev',
                                }}
                                modules={[Autoplay, Pagination, Navigation, Scrollbar]}
                                className="testimonials-slider swiper"
                                data-aos="fade-up"
                                data-aos-delay="100"
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                    },
                                    // when window width is >= 640px
                                    460: {
                                        //   width: 640,
                                        slidesPerView: 2,
                                    },
                                    // when window width is >= 768px
                                    768: {
                                        //   width: 768,
                                        slidesPerView: 2,
                                    },
                                    968: {
                                        //   width: 768,
                                        slidesPerView: 4,
                                    },

                                }}
                            >

                                <div className="swiper-wrapper">

                                    {blogs && blogs.map((b) => (


                                        <SwiperSlide>
                                            <div className='border bg-white' style={{ 'height': '444px' }}>
                                                <div className='member-img' >
                                                    <img src={`${process.env.REACT_APP_BACKEND}blog/${b.cover}`} alt="" className='img-fluid' />
                                                </div>
                                                <div className="p-3" style={{ 'fontSize': '12px', 'height': '270px' }}>
                                                    <h5>{b.title.slice(0, 35)}...</h5>

                                                    <p> {htmlToFormattedText(b.details).slice(0, 190)}...</p>

                                                    <div>
                                                        <span style={{ 'marginRight': '20px' }}>By : {b.author}</span>
                                                        <span>{b.date}</span>
                                                    </div>

                                                    <div className='text-center p-3 fixed-bottom'>
                                                        <a href={`/blog/${b.id}`} className='' style={{ 'borderBottom': '1px solid #F1AE55', 'fontSize': '12px', 'marginBottom': '0px' }}>READ MORE</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}




                                </div>

                            </Swiper>
                        </div>
                    </div>

                    <div className='col-1 p-0 d-flex justify-content-start'>
                        <i className="bi bi-caret-right-fill review-swiper-button-next"></i>
                    </div>

                </div>
            </div>
        </section>

    )
}

export default Blog;