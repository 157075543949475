import React, {useEffect} from "react";
import Header from "../component/common/header";
import Footer from "../component/common/footer";
import Event from "../component/events/event";


const EventPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return (
        <>
            <Header />
            <Event/>
            <Footer />
        </>
    )
}

export default EventPage;