import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoodIcon from '@mui/icons-material/Mood';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Lottie from "lottie-react";
import Error from './error.json';
import Success from './success.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import AddEventForm from "../form/addEventForm";
import AddImageForm from "../form/addImageForm";
import EventPeople from "../form/eventPeople";
import CircularProgress from '@mui/material/CircularProgress';
import { useDropzone } from 'react-dropzone';
import { Link, useParams } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import api from "../../../API/api";

const steps = [
    {
        label: 'Enter your information',

    },
    {
        label: 'Upload single/multiple images',

    },
    {
        label: 'Submit Your Data',

    }
];

const AddEvents = () => {
    const { user_id } = useParams();
    const { edit } = useParams();
    const params = useParams();

    const [formData, setFormdata] = useState({
        user_name: '',
        user_id: '',
        email: '',
        purpose: '',
        date: '',
        from_time: '',
        to_time: '',

        audience_participation: '',

        event: '',
        from: '',
        to: '',
        time: '',
        location: '',
        description: '',
        img: []

    });

    const [cover, setCover] = useState(); //done
    const [coverpreview, setCoverpreview] = useState();

    const [portrait, setPortrait] = useState();
    const [portraitpreview, setPortraitpreview] = useState();
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [lineloader, setLineLoader] = useState(false);



    // cast
    const [castinputFields, setCastInputFields] = useState([{
        fullName: '',
    }]);

    const castaddInputField = () => {
        setCastInputFields([...castinputFields, {
            fullName: '',
        }])

    }
    const castremoveInputFields = (index) => {
        const rows = [...castinputFields];
        rows.splice(index, 1);
        setCastInputFields(rows);
    }
    const casthandleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...castinputFields];
        list[index][name] = value;
        setCastInputFields(list);

        console.log(castinputFields);
    }

    // crew 
    const [crewinputFields, setCrewInputFields] = useState([{
        fullName: '',
    }]);
    const crewaddInputField = () => {
        setCrewInputFields([...crewinputFields, {
            fullName: '',
        }])

    }
    const crewremoveInputFields = (index) => {
        const rows = [...crewinputFields];
        rows.splice(index, 1);
        setCrewInputFields(rows);
    }
    const crewhandleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...crewinputFields];
        list[index][name] = value;
        setCrewInputFields(list);

        console.log(crewinputFields);
    }

    // guest 
    const [guest, setGuest] = useState();

    const handleguest = (e) => {
        console.log(e.target.value);
        setGuest(e.target.value);
    }

    //sponser
    const [sponser, setSponser] = useState('');

    const handlesponser = (e) => {
        console.log(e.target.value);
        setSponser(e.target.value);
    }






    useEffect(() => {

        if (edit) {
            console.log(params);
            getEventData();
        }
        else if (user_id) {
            console.log("dhorsiiiii", params);
            setLineLoader(true);
            getIndivisualRetalRequest()
        }
        else {
            console.log("ee");
            setFormdata({
                ...formData,
                'user_id': new Date().getTime()
            })
        }
    }, [params]);

    const handleCoverImg = (e) => {
        console.log("okkk");
        setCover(e.target.files[0]);
        setCoverpreview(URL.createObjectURL(e.target.files[0]))
    }

    const handlePortraitImg = (e) => {
        console.log("Portrait");
        setPortrait(e.target.files[0]);
        setPortraitpreview(URL.createObjectURL(e.target.files[0]))
    }

    const getEventData = () => {
        // here user_id is id 
        api.getEventData(params.user_id).then((res) => {
            console.log("->>", res.data.event.user_name);
            setFormdata({
                user_name: res.data.event.user_name,
                user_id: res.data.event.user_id,
                email: res.data.event.email,
                purpose: res.data.event.purpose,
                date: res.data.user_data.date,
                from_time: res.data.user_data.from_time,
                to_time: res.data.user_data.to_time,

                event: '',
                from: '',
                to: '',
                time: '',
                location: '',
                description: '',
                img: []
            })


        })
            .catch((err) => {
                console.log(err.response);
            })
    }





    const getIndivisualRetalRequest = () => {
        api.getIndivisualRetalRequest(user_id)
            .then((res) => {
                setLineLoader(false);
                console.log("==>>>", user_id);
                console.log("==>>>>", res.data);
                console.log("==>>", res.data.user_data);
                setFormdata({
                    user_name: res.data.user_data.user_name,
                    user_id: res.data.user_data.user_id,
                    email: res.data.user_data.email,
                    purpose: res.data.user_data.purpose,
                    date: res.data.user_data.date,
                    from_time: res.data.user_data.from_time,
                    to_time: res.data.user_data.to_time,

                    ticketPlatform: '',
                    ticketLink: '',
                    minPrice:'',

                    event: '',
                    from: '',
                    to: '',
                    time: '',
                    location: '',
                    description: '',
                    img: []
                })

                console.log("restore", formData);

            })
            .catch((err) => {
                console.log(err);
            })

    }

    const handleInput = (e) => {
        console.log(e.target.value);
        setFormdata({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const [fileList, setFileList] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            setFileList(
                acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
            )
        }
    })

    // const handleFileChange = (e) => {
    //     setFileList(e.target.files);
    // };

    const handleUploadClick = () => {
      
        setLoader(true);

        const api = process.env.REACT_APP_MAIN_API;

        // const url = api + "/postEventImg";
        const url = api + "/createEvent";
        console.log("===>", fileList.length);
        const imgdata = new FormData();

        for (let i = 0; i < fileList.length; i++) {
            imgdata.append(`img_${i}`, fileList[i])
        }
        imgdata.append('lenght', fileList.length);

        imgdata.append('event', formData.event)
        imgdata.append('date', formData.date)
        imgdata.append('from_time', formData.from_time)
        imgdata.append('to_time', formData.to_time)
        imgdata.append('description', formData.description)
        imgdata.append('location', formData.location)
        
        imgdata.append('ticketPlatform', formData.ticketPlatform)
        imgdata.append('ticketLink', formData.ticketLink)
        imgdata.append('minPrice', formData.minPrice)


        imgdata.append('user_id', formData.user_id)
        imgdata.append('user_name', formData.user_name)
        imgdata.append('purpose', formData.purpose)
        imgdata.append('audience_participation', formData.audience_participation)

        imgdata.append('cover', cover)
        imgdata.append('portrait', portrait)
        imgdata.append('guest', guest)
        imgdata.append('sponser', sponser)


        axios.post(url, imgdata)
            .then((res) => {
                setLoader(false);

                console.log(res.status);
                console.log("response", res);
                if (res.status === 200) {
                    setSuccess(true);

                    toast.success('Successfully Added', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else {
                    setError(true);
                    toast.error('Something went wrong !', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });


                }
            })
            .catch((err) => {
                setLoader(false);
                setError(true);
                console.log(err.response);
                toast.error('Opps Something went wrong !', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })

    };



    const thirdPage = () => {
        return (
            <div className="text-center">
                <h3 className="my-5">Preview of all data</h3>

                <table className="table table-striped table-bordered table-hover">
                    <thead style={{ 'textAlign': 'left' }}>
                        <tr className="light-bg">
                            <th scope="col">Event Name</th>
                            <td>{formData.event}</td>
                        </tr>
                        <tr>
                            <th scope="col">Date</th>
                            <td>{formData.from}</td>
                        </tr>
                        <tr className="light-bg">
                            <th scope="col">Time</th>
                            <td>{formData.time}</td>
                        </tr>
                        <tr>
                            <th scope="col">Location</th>
                            <td>{formData.location}</td>
                        </tr>
                        <tr className="light-bg">
                            <th scope="col">Description</th>
                            <td>{formData.description}</td>
                        </tr>
                    </thead>
                </table>
            </div>
        )
    }




    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);

        if (activeStep === 2) {
            console.log("Last step");

            handleUploadClick();

        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };



    return (
        <Box sx={{ width: '100%' }}>
            {lineloader &&
                <LinearProgress />
            }
            <ToastContainer />
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label.label} {...stepProps}>
                            <StepLabel {...labelProps}>{label.label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        {/* All steps completed - you&apos;re finished */}


                        {loader &&
                            <div className="success">
                                <div className="text-center mt-3 pt-4">
                                    <CircularProgress disableShrink />
                                </div>
                                <div className="text-center">
                                    Please Wait ...
                                </div>
                            </div>
                        }





                    </Typography>

                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                        <div className="text-center">
                            <Box>
                                {error &&
                                    <div className="error d-flex justify-content-center flex-nowrap align-items-center" style={{ 'flexDirection': 'column' }}>
                                        <div style={{ 'width': '300px', 'height': '300px' }}>
                                            <Lottie animationData={Error} />
                                        </div>
                                        <div class="alert alert-danger">
                                            <CancelIcon />
                                            <strong className="px-2">Oops! Something went wrong.</strong>
                                            <p className="py-1"> <SentimentVeryDissatisfiedIcon /> &nbsp;Data Upload Failed</p>
                                        </div>
                                    </div>
                                }

                                {success &&

                                    <div className="success d-flex justify-content-center flex-nowrap align-items-center" style={{ 'flexDirection': 'column' }}>
                                        <div style={{ 'width': '300px', 'height': '300px' }}>
                                            <Lottie animationData={Success} />
                                        </div>

                                        <div class="alert alert-success">
                                            <CheckCircleIcon />
                                            <strong className="px-2">Congratulations!</strong>
                                            <p className="py-1"> <MoodIcon /> &nbsp;Data Uploaded Successfully</p>
                                        </div>
                                    </div>
                                }
                            </Box>
                        </div>
                    </>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {/* {activeStep === 0 && firstPage()} */}
                    {activeStep === 0 && <AddEventForm data={handleInput} value={formData} user_id={user_id} />}


                    {activeStep === 1 && <AddImageForm datafile={fileList} getInput={getInputProps()} getRoot={getRootProps()} handleCoverImg={handleCoverImg} coverpreview={coverpreview} handlePortraitImg={handlePortraitImg} portraitpreview={portraitpreview} />}


                    {activeStep === 2 && <EventPeople castinputFields={castinputFields} castaddInputField={castaddInputField} castremoveInputFields={castremoveInputFields} casthandleChange={casthandleChange} crewinputFields={crewinputFields} crewaddInputField={crewaddInputField} crewremoveInputFields={crewremoveInputFields} crewhandleChange={crewhandleChange} guest={guest} handleguest={handleguest} formData={formData} handlesponser={handlesponser} sponser={sponser} />}


                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )}

                        <Button variant="outlined" onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Submit and Finish' : 'Next'}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}

export default AddEvents;