import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import api from "../../API/api";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';



const CategoryImg = (props) => {


    const [photos, setPhotos] = useState();

    useEffect(() => {
        getGalleryWithCat(props.type);
    }, []);

    const getGalleryWithCat = (type) => {
        api.getGalleryWithCat(type).then((res) => {
            console.log(res);
            setPhotos(res.data.gallery);
        })
            .catch((err) => {
                console.log(err.response);
            })
    }


    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="text-center">
                        <h1 className="py-3">Some Snaps At Agora</h1>
                    </div>
                    <div className="col-md-10">
                        <Swiper
                            loop={true}
                            spaceBetween={10}
                            modules={[Autoplay]}
                            data-aos="fade-up"
                            autoplay={true}
                            data-aos-delay="100"
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                },
                                // when window width is >= 480px
                                480: {
                                    slidesPerView: 2,
                                },
                                // when window width is >= 640px
                                640: {
                                    slidesPerView: 4,
                                }
                            }}
                        >


                            {photos && photos.map((p) => (
                                <>
                                    {p.onPage === 1 &&

                                        <SwiperSlide>
                                            <div className="EvntImage">
                                                <img src={`${process.env.REACT_APP_BACKEND}gallery/${p.img} `} alt="img" className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                    }
                                </>
                            ))}


                        </Swiper>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoryImg;