import React, { useState, useEffect } from "react";

import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Link } from "react-router-dom";
import api from "../../API/api";


const Reports = () => {

    const [count, setCount] = useState();

    useEffect(() => {
        countData()
    }, [])

    const countData = () => {
        api.countData()
            .then((res) => {
                console.log(res);
                setCount(res.data)
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    return (
        <section className="dash m-0 p-0">
            <div className="container">
                <div className="row" data-aos="fade-up">
                    <div className="col-md-3">
                        <div className="reports rp d-flex ">
                            <span>
                                {/* <strong>207</strong> */}
                                <br />
                                Subscribers
                            </span>
                            <span className="icon"><i class="bi bi-collection-play"></i></span>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="reports rp d-flex ">
                            <span>
                                {/* <strong>300</strong> */}
                                <br />
                                Followers
                            </span>
                            <span className="icon"><i class="bi bi-person-hearts"></i></span>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="reports rp d-flex ">
                            <span>
                                {/* <strong>100</strong> */}
                                <br />
                                Posts
                            </span>
                            <span className="icon"><i class="bi bi-vector-pen"></i></span>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="reports rp d-flex ">
                            <span>
                                {/* <strong>70</strong> */}
                                <br />
                                Articles
                            </span>
                            <span className="icon"><i class="bi bi-book-half"></i></span>
                        </div>
                    </div>
                </div>
                <div className="row my-5" data-aos="fade-up">
                    <div className="col-md-6">
                        <div className="reports">
                            <h5 className="p-3">Our Forms</h5>
                            <div className="d-flex" style={{ 'flexWrap': 'wrap', 'padding': '59px 0' }}>
                                <div className="col-md-12">
                                    <div className="text-center m-2">
                                        <Link to="/admin/table">
                                            <Button color="success" size="large" variant="contained" endIcon={<SendIcon />} style={{ 'width': '100%' }}>
                                                VOLUNTEER FORM
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-12 py-3">
                                    <div className="text-center m-2">
                                        <Link to="/admin/RentalRequest">
                                            <Button color="info" size="large" variant="contained" endIcon={<SendIcon />} style={{ 'width': '100%' }}>
                                                RENTAL FORM
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="text-center m-2">
                                        <Link to="/admin/table">
                                            <Button color="secondary" size="large" endIcon={<SendIcon />} variant="contained" style={{ 'width': '100%' }}>
                                                INTERNSHIP FORM
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-12 py-3">
                                    <div className="text-center m-2">
                                        <Link to="/admin/ContactUsTable">
                                            <Button color="error" size="large" variant="contained" endIcon={<SendIcon />} style={{ 'width': '100%' }}>
                                                CONTACT US DATA
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="reports">
                            <h5 className="p-3">Stats Reports</h5>
                            <div className="d-flex p-3" style={{ 'flexWrap': 'wrap' }}>
                                <div className="col-md-6">
                                    <div className="event text-center m-2">
                                        <i class="bi bi-music-note-list"></i><br />
                                        <h6 className="py-2"><strong>EVENTS</strong></h6>
                                        <p>{count && count.event}+</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="blogs text-center m-2">
                                        <i class="bi bi-pen-fill"></i><br />
                                        <h6 className="py-2"><strong>BLOGS</strong></h6>
                                        <p>{count && count.blogs}+</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="media text-center m-2">
                                        <i class="bi bi-play-circle-fill"></i><br />
                                        <h6 className="py-2"><strong>MEDIA</strong></h6>
                                        <p>{count && count.gallery}+</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="paper text-center m-2">
                                        <i class="bi bi-journal-richtext"></i><br />
                                        <h6 className="py-2"><strong>INTERN REQUEST</strong></h6>
                                        <p>{count && count.interns}+</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Reports;