import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';
import { Button } from "@mui/material";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

import { useParams } from 'react-router-dom';
import FormDialog from "../mui/modal";
import api from "../../API/api";




const SingleEvents = () => {
    const params = useParams();


    const [event, setEvent] = useState([]);
    const [img, setImg] = useState([]);

    const [loading, setLoading] = useState(false);

    const [ticketData, setTicketData] = useState();

    useEffect(() => {
        getTicketData();
    }, []);

    const getTicketData = () => {
        api.getTicketData().then((res) => {
            console.log(res);
            setTicketData(res.data.ticket)
        })
    }


    useEffect(() => {
        console.log(params.id);
        getEventData();
        setLoading(true);
    }, []);


    const getEventData = () => {
        api.getEventData(params.id)
            .then((res) => {
                console.log(res);
                setEvent(res.data.event);
                setImg(res.data.img);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const Loading = () => {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className='col-md-8'>
                        <div className="border p-4">


                            <div className="pb-2 text-center">
                                <Skeleton inline={true} width={150} height={30} />
                            </div>

                            <div className="pb-2 text-center">
                                <Skeleton inline={true} width={220} height={30} />
                            </div>

                            <div className="pb-2 text-center">
                                <Skeleton inline={true} width={300} height={30} />
                            </div>

                            <div className="pb-2">
                                <Skeleton height={40} />
                            </div>
                            <div>
                                <Skeleton height={35} count={10} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    return (
        <>
            {loading ? <Loading /> :

                <section id="singleEvent">
                    <div className="container" data-aos="zoom-out" data-aos-delay="100">
                        <div className="row pb-3">
                            <Swiper
                                slidesPerView={1}
                                loop={true}
                                modules={[Autoplay, Pagination, Scrollbar]}
                                autoplay={{
                                    delay: 8000,
                                    disableOnInteraction: false,
                                }}
                                speed={1500}
                            >

                                <SwiperSlide>
                                    <div className="col-xl-12">
                                        {event.portrait !== 'no image' &&
                                            <div className="event-portrait">
                                                <img src={`${process.env.REACT_APP_BACKEND}events/cover/${event.portrait}`} alt="" className=" w-100" id="eventImage" />
                                            </div>
                                        }
                                    </div>
                                </SwiperSlide>

                            </Swiper>
                        </div>
                        <br />
                        <br />
                        <div className="row p-1 ">
                            <div className="col-md-12 text-center  z-i-99" data-aos="fade-up">
                                <div className="viewEvent text-center px-5 py-5 light-bg"
                                    style={{ 'marginTop': '-10%' }}>
                                    <h1>{event.event}</h1>
                                    <ul className="d-flex justify-content-center">
                                        <li><i className="bi bi-calendar2-heart-fill"></i>&nbsp;<strong>{new Date(event.date).toDateString()} ||</strong></li>
                                        {event.from_time &&
                                            <li className="mx-3"><i className="bi bi-hourglass-top"></i>&nbsp;<strong>
                                                {event.from_time} to {event.to_time}
                                            </strong></li>
                                        }
                                    </ul>

                                    <p>
                                        <strong>Share : </strong>&nbsp;
                                        <a href="/" className="fb"><i className="bx bxl-facebook"></i></a>
                                        <a href="/" className="insta mx-3"><i className="bx bxl-instagram"></i></a>
                                        <a href="/" className="twt"><i className="bx bxl-twitter"></i></a>
                                        <a href="/" className="g-plus mx-3"><i className="bx bxl-skype"></i></a>
                                        <a href="/" className="link"><i className="bx bxl-linkedin"></i></a>
                                    </p>

                                    <p>
                                        <i className="bi bi-geo-alt-fill"></i>&nbsp;<strong>Location :</strong> {event.location} | Assam 2nd Floor, Commercial Complex East, Anuradha Cineplex Bamonimaidan 26
                                    </p>

                                    <p className="px-3">
                                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>&nbsp;

                                        {event.description}

                                        .&nbsp;
                                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                    </p>
                                </div>


                                {new Date(event.date) > new Date() &&

                                    <div className="d-flex justify-content-center m-3">

                                        {event.audience_participation == 1 &&
                                            <>
                                                {ticketData && ticketData.map((t) =>
                                                    <>
                                                        {t.platform == event.ticketPlatform &&
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <a href={`${event.ticketLink}`} target="_blank" className="text-primary border px-3"> <b> Book Ticket <img src={`${process.env.REACT_APP_BACKEND}events/payment/${t.logo}`} alt="" height={60} /></b> </a>
                                                            </div>
                                                        }
                                                    </>
                                                )}
                                            </>
                                        }
                                        <div className="px-2">
                                            {
                                                // event.audience_participation == 2 && 
                                                <a className="btn btn-warning" href={`http://www.google.com/calendar/event?action=TEMPLATE&text=${event.event}&dates=${new Date(event.date).getFullYear() + '' + ('0' + (new Date(event.date).getMonth() + 1)).slice(-2) + '' + ('0' + new Date(event.date).getDate()).slice(-2)}/${new Date(event.date).getFullYear() + '' + ('0' + (new Date(event.date).getMonth() + 1)).slice(-2) + '' + ('0' + new Date(event.date).getDate()).slice(-2)}&details=${event.event}&location=2nd Floor, Anuradha Cineplex, Commercial Complex East, Bamunimaidan, Guwahati, Assam 781021&trp=false&sprop=&sprop=name:`} target="_blank" rel="nofollow"> <EventAvailableIcon /> Add to my calendar</a>
                                            }
                                        </div>


                                        {
                                            event.audience_participation == 3 &&
                                            <> <FormDialog event={event} /></>
                                        }

                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </section>

            }

        </>
    )
}

export default SingleEvents;