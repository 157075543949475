import React from "react";

const Error = () => {
    return (
       <section>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="text-center">
                            <h1 class="display-1 fw-bold">404</h1>
                            <p class="fs-3"> <span class="text-danger">Opps!</span> Page not found.</p>
                            <p class="lead">
                                The page you're looking for doesn't exist.
                            </p>
                            <div className="py-3">
                                <a href="/" class="rentBtn active">Go Home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </section>
    )
}

export default Error;