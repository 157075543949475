import React, {useEffect} from 'react';

import Header from '../component/common/header';
import UpcomingEvents from '../component/home/upcomingEvents';
import Testimonial from '../component/home/testimonial';
import Footer from '../component/common/footer';
import HeroSection from '../component/home/home';
import Blog from '../component/home/blogSection';
import Welcome from '../component/home/welcome';
import RentSection from '../component/home/rent';
import Volunteer from '../component/home/volunteer';

const HomePage=()=>{
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return (
        <>
        <Header/>
        <HeroSection />
        <Welcome />
        <UpcomingEvents />
        <RentSection/>
        <Volunteer />
        <Blog />
        <Testimonial />
        <Footer/>
        </>
    )
}

export default HomePage;