import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import api from '../../API/api';
import Swal from 'sweetalert2'
import EventNoteIcon from '@mui/icons-material/EventNote';

export default function FormDialog(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [formData, setFormData] = useState({
        event: props.event.event,
        event_id: props.event.id
    });

    const handleInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        console.log(formData);

        api.saveRegistration(formData).then((res) => {
            console.log(res);
            handleClose()
            Swal.fire({
                title: 'Thank You',
                text: "Your Registration successfull ",
                icon: 'success',
            })
        })
            .catch((err) => {
                console.log(err.response);
            })
    }

    return (
        <div>
            <Button variant="contained"  onClick={handleClickOpen}>
               <EventNoteIcon /> Regiter
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{props.event.event}</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>

                    <div className='m-2'>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <TextField
                                    autoFocus
                                    value={formData.name}
                                    name='name'
                                    onChange={handleInput}
                                    margin="dense"
                                    id="name"
                                    label="Your Name"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <TextField
                                    value={formData.email}
                                    name='email'
                                    onChange={handleInput}
                                    margin="dense"
                                    id="name"
                                    label="Email"
                                    type="email"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <TextField
                                    value={formData.phone}
                                    name='phone'
                                    onChange={handleInput}
                                    margin="dense"
                                    id="name"
                                    label="Phonr Number"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                        </div>



                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Register</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
