import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import api from '../../../API/api';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import LinearProgress from '@mui/material/LinearProgress';
import PaidIcon from '@mui/icons-material/Paid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Menus from '../../mui/menuRental';
import DynamicModal from '../../mui/dynamicModal';
import JoditEditor from 'jodit-react';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const RentalRequest = () => {
    const navigate = useNavigate();
    const editor = useRef(null);

    const [lineloader, setLineLoader] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [approve, setApprove] = React.useState(false);
    const [delay, setDelay] = React.useState(false);
    const [reschedule, setReschedule] = React.useState(false);

    const [approveData, setApproveData] = useState({});
    const [rescheduleForm, setRescheduleForm] = useState({
        estimated_hours: '',
        rescheduleTimeFrom: '',
    });
    const [timeslot , setTimeSlot] = useState(true);
    const [user, setUser] = useState({});

    const [rentals, setRentals] = useState([]);
    useEffect(() => {
        getRental()
    }, []);

    const handleOpen = (type, data) => {
        setUser(data);
        if (type == 'approve') {
            setApprove(true)
        }
        if (type == 'reschedule') {
            setReschedule(true)
        }
        if (type == 'delay') {
            setDelay(true)
        }
        setOpen(true)
    }
    const handleClose = () => {
        setDelay(false)
        setOpen(false)
        setApprove(false)
        setReschedule(false)
    }

    const getRental = () => {
        setLineLoader(true);
        api.getRental()
            .then((res) => {
                setLineLoader(false);
                console.log("rent", res.data.rentals);
                setRentals(res.data.rentals)
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const handleJodit=(e)=>{
        setApproveData({...approveData, paymentlink: e})

    }

    const [resheduleMsg, setRescheduleMsg] = useState('');
    const handleJodit2=(e)=>{
        // setRescheduleForm({...rescheduleForm, paymentlink: e})
        setRescheduleMsg(e)

    }

    const config = {
        uploader: {
            insertImageAsBase64URI: true
        },
        height: 320,
        triggerChangeEvent: true,
        readonly: false,
    }

    const Jodit = () => {
        return useMemo(() => (
            <>
                
                <JoditEditor
                ref={editor}
                config={config}
                // value={details && details}
                onChange={handleJodit}
                /> 
            </>
        ), [])  
    }


    const Jodit2 = () => {
        return useMemo(() => (
            <>
                
                <JoditEditor
                ref={editor}
                config={config}
                // value={details && details}
                onChange={handleJodit2}
                /> 
            </>
        ), [])  
    }

    const rentalTable = () => {
        return (
            <>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead className='bg-dark'>
                            <TableRow>
                                <TableCell className='text-white'>Name/User Id</TableCell>
                                {/* <TableCell className='text-white'>User Id</TableCell> */}
                                <TableCell className='text-white'>Purpose</TableCell>
                                <TableCell className='text-white'>Date</TableCell>
                                <TableCell className='text-white'>Phone</TableCell>
                                <TableCell className='text-white'>Email</TableCell>
                                <TableCell className='text-white' align='center'>Status</TableCell>
                                <TableCell className='text-white' align='center'>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rentals.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" className={row.status == 4 ? 'text-success' : ''}>
                                        <b>
                                            {row.name}
                                        </b>
                                        <div>
                                            {row.user_id}
                                        </div>
                                    </TableCell>
                                    {/* <TableCell>{row.user_id}</TableCell> */}
                                    <TableCell className={row.status == 4 ? 'text-success' : ''}>
                                        <b> {row.purpose} </b>
                                    </TableCell>
                                    <TableCell className={row.status == 4 ? 'text-success' : ''}>
                                        <> 
                                        {row.date}
                                        <br />
                                            {   Number(row.slot) === Number(100) ?
                                                <>
                                                    {/* ({row.from_time} - {row.to_time}) */}
                                                    ( from {row.from_time} for {row.estimated_hours} Hours)
                                                </> : <strong>Full Day</strong>
                                            }
                                        </>
                                    </TableCell>
                                    <TableCell className={row.status == 4 ? 'text-success' : ''}>{row.phone}</TableCell>
                                    <TableCell className={row.status == 4 ? 'text-success' : ''}>{row.email}</TableCell>
                                    <TableCell align='center' className='tinny-font'>
                                        {row.status == 0 ? <span className="px-1"> <a className='btn btn-success btn-sm' href={`${process.env.REACT_APP_BACKEND}payment/screenshoot/${row.screenShoot}`} target='_blank'>View</a> </span> : ''}
                                        {row.status == 1 ? <span className="px-1 text-white bg-primary border rounded">Payment Pending</span> : ''}

                                        {row.status == 2 ? <span className="px-1 text-white bg-warning border rounded">Resheduled</span> : ''}
                                        {row.status == 3 ? <span className="px-1 text-white bg-danger border rounded">Delayed</span> : ''}
                                        {row.status == 4 ? <span className="px-1 text-white bg-success border rounded" style={{ fontSize: '12px' }}>Approved</span> : ''}
                                    </TableCell>
                                    <TableCell align='center'><Menus handleOpen={handleOpen} handleClickOpen={handleOpen} handleClose={handleClose} data={row} handleDelay={handleDelay} handlePaymentConform={handlePaymentConform} /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }


   

    const handleApproveForm = (e) => {
        setApproveData({
            ...approveData,
            [e.target.name]: e.target.value
        })
    }

    const handleRescheduleForm = (e) => {
        setRescheduleForm({
            ...rescheduleForm,
            [e.target.name]: e.target.value
        })
    }

    const handleDelay = (user_id) => {
        console.log(user_id);
        Swal.fire({
            title: 'Delay?',
            text: "You can reschedule at any time",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                // delayApproval(user_id)
                api.delayApproval(user_id)
                    .then((res) => {
                        console.log(res);

                        getRental()
                        if (res.data.status == 200) {
                            Swal.fire(
                                // 'Approved!',
                                'Success'
                            )
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    })



            }
        })

    }

    const handleReschedule = () => {
        console.log("=>>", rescheduleForm);
        console.log("resheduleMsg=>>", resheduleMsg);
        console.log(user);

        // return;

        api.rescheduleRental(user, rescheduleForm, resheduleMsg)
            .then((res) => {
                console.log(res);
                if (res.data.status == 200) {
                    getRental()
                    handleClose()

                    if (res.data.status == 200) {
                        Swal.fire(
                            'Rescheduled!',
                            'A message will be sent to the user through email.'
                        )
                    }

                }
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const handleApprove = () => {

        console.log("here", approveData);
        if(!approveData.amount){
            setApprove(false)
            Swal.fire({
                title: 'Re-enter',
                text: "Please re-enter the amount",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (result.isConfirmed) {
                    setApprove(true)
                }
            })
        }
        if(!approveData.due_date){
            setApprove(false)
            Swal.fire({
                title: 'Re-enter',
                text: "Please re-enter the due date",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (result.isConfirmed) {
                    setApprove(true)
                }
            })
        }

        // return ;

        api.acceptApproval(user, approveData)
            .then((res) => {
                console.log(res);
                getRental()
                handleClose()

                if (res.data.status == 200) {
                    Swal.fire(
                        'Approved!',
                        'A payment link will be sent to the user through email.'
                    )
                }
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const approvalForm = (data) => {
        return (
            <>

                <div className="row">
                    <div>
                        <strong>User Name: {user && user.name}</strong>
                    </div>

                    <div className="col-md-12 my-3">
                        <label htmlFor=""> Payment Information </label>
                        {Jodit()}
                        {/* <input type="text" className='form-control' name="paymentlink" value={approveData.paymentlink} onChange={handleApproveForm} id="" /> */}
                    </div>


                    <div className="col-md-6 my-3">
                        <label htmlFor=""> Amount (INR) </label>
                        <input type="number" className='form-control' name="amount" value={approveData.amount} onChange={handleApproveForm} id="" />
                    </div>
                    <div className="col-md-6 my-3">
                        <label htmlFor="">Due Date</label>
                        <input type="date" className='form-control' name="due_date" value={approveData.due_date} onChange={handleApproveForm} id="" />
                    </div>
                    <div className='my-2'>
                        <small>User will get a mail with all the informations and payment link.<br />
                            {/* <i> <a href="">View Mail Context</a></i> */}
                        </small>
                    </div>
                    <div className="col-12 text-center mt-3 form-group">
                        <Button
                            variant="contained"
                            size="small"
                            color='warning'
                            // onClick={() => handleApprove(data)}
                            onClick={handleApprove}
                        >
                            Accept and Send Mail
                        </Button>
                    </div>


                </div>

            </>
        )
    }

    const handleTimeSlot = (e) => {
        console.log(e.target.value);
        if (e.target.value == 600) {
            setTimeSlot(true)
            setRescheduleForm({
                ...rescheduleForm,
                [e.target.name]: e.target.value
            })
        }else {
            setTimeSlot(false)
            setRescheduleForm({
                ...rescheduleForm,
                [e.target.name]: e.target.value
            })
        }
    }


    const resheduleForm = (user) => {
        return (
            <>
                <div role="form" className="php-email-form" style={{ 'background': '#ffffff' }}>

                    <div className="row">
                        <div className="col-md-4  mt-3 form-group">
                            <label>Date</label><br />
                            <input type="date" className='form-control' name="rescheduleDate" value={rescheduleForm.rescheduleDate} onChange={handleRescheduleForm} id="" />
                        </div>
                       
                        <div className="col-md-4 mt-3 form-group">
                            <label>Select a time slot</label> 
                            <select  className='form-select' onChange={handleTimeSlot}  name='slot' value={rescheduleForm.slot}>
                                <option value="" disabled selected>--select--</option>
                                <option value="600">Full day</option>
                                <option value="100">Hourly </option>
                            </select>
                        </div>

                        {
                            timeslot === false ?
                            <>
                                <div className="col-md-4  mt-3 form-group">
                                    <label>Time From</label><br />
                                    <input type="time" className='form-control' name='rescheduleTimeFrom' value={rescheduleForm.rescheduleTimeFrom} onChange={handleRescheduleForm} />
                                </div>
                                <div className="col-md-4 mt-3 form-group">
                                    <label>Select Estimated Hours</label>
                                    <select name="estimated_hours" value={rescheduleForm.estimated_hours}  onChange={handleRescheduleForm} > 
                                        <option value="" disabled selected>--select--</option>
                                        <option value="1">1 Hour</option>
                                        <option value="2">2 Hours </option>
                                        <option value="3">3 Hours </option>
                                        <option value="4">4 Hours </option>
                                        <option value="5">5 Hours </option>
                                        <option value="6">6 Hours </option>
                                        <option value="7">7 Hours </option>
                                        <option value="8">8 Hours </option>
                                        <option value="9">9 Hours </option>
                                        <option value="10">10 Hours </option>
                                        <option value="11">11 Hours </option>
                                        <option value="12">12 Hours </option>
                                    </select>
                                </div>
                                {/* <div className="col-md-4  mt-3 form-group">
                                    <label>Time To</label><br />
                                    <input type="time" className='form-control' name='rescheduleTimeTo' value={rescheduleForm.rescheduleTimeTo} onChange={handleRescheduleForm} />
                                </div> */}
                            </>
                        : ''
                        }

                        <div className="col-md-4 my-3">
                            <label htmlFor=""> Amount (INR) </label>
                            <input type="number" className='form-control' name="amount" value={rescheduleForm.amount} onChange={handleRescheduleForm} id="" />
                        </div>
                        <div className="col-md-4 my-3">
                            <label htmlFor="">Payment Due Date </label>
                            <input type="date" className='form-control' name="due_date" value={rescheduleForm.due_date} onChange={handleRescheduleForm} id="" />
                        </div>
                        <div className="col-md-12 my-3">
                            <label htmlFor=""> Payment Information </label>
                            {Jodit2()}
                            <input type="text" className='form-control' name="paymentlink" value={rescheduleForm.paymentlink} onChange={handleRescheduleForm} id="" />
                        </div>
                        <div className="col-12 text-center mt-3 form-group">
                            <Button
                                variant="contained"
                                size="small"
                                color='warning'
                                onClick={handleReschedule}
                            // onClick={handleReschedule}
                            >
                                Reshedule
                            </Button>
                        </div>

                    </div>
                </div>

            </>
        )
    }

    const handlePaymentConform = (user_id) => {
        console.log(user_id);
        Swal.fire({
            title: 'Confirm?',
            text: "Can you confirm that the payment has been received?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                // delayApproval(user_id)
                api.confirmPayment(user_id)
                    .then((res) => {
                        console.log(res);

                        getRental()
                        if (res.data.status == 200) {
                            Swal.fire(
                                // 'Approved!',
                                'Confirmed'
                            )
                        }
                    })
                    .catch((err) => {
                        console.log(err.response);
                    })
            }
        })

    }
    return (
        <>
            {lineloader &&
                <LinearProgress />
            }
            {rentalTable()}
            <Modal
                keepMounted
                open={approve}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Approve -
                    </Typography>
                    <>
                        <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                            {approvalForm()}
                        </Typography>
                    </>
                </Box>
            </Modal>
            <Modal
                keepMounted
                open={delay}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Delay -
                    </Typography>
                    <>
                        <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                            <div role="form" className="php-email-form" style={{ 'background': '#ffffff' }}>
                                <div className="row">
                                    <div className="col-md-12 my-4">
                                        <label htmlFor=""> Payment Link </label>
                                        <input type="text" className='form-control' name="paymentlink" id="" />
                                    </div>
                                    <div className="col-md-12 my-4">
                                        <label htmlFor=""> Approve Mail </label>
                                        <textarea name="message" className='form-control' id="" rows="5"></textarea>
                                    </div>
                                    <div className="col-12 text-center mt-3 form-group">
                                        <Button
                                            variant="contained"
                                            size="small"
                                            color='warning'
                                        // onClick={() => handleApprove2(user_id)}
                                        >
                                            Approve
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Typography>
                    </>
                </Box>
            </Modal>
            <Modal
                keepMounted
                open={reschedule}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Reshedule -
                    </Typography>
                    <>
                        {resheduleForm()}
                    </>
                </Box>
            </Modal>
        </>
    );
}

export default RentalRequest;