import React, { useState, useEffect } from 'react';
import PhotoAlbum from "react-photo-album";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button ,Typography } from '@mui/material';
import api from '../../API/api';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
};

const Imagegallery = () => {

    const [photos, setPhotos] = useState();
    const [filterdPhotos , setFilterdPhotos] = useState();
    const [years , setYears]  = useState([]);
    const [test , setTest]      = useState('');

    useEffect(() => {
        getGallery();
    }, []);

    const getGallery = () => {
        api.getGallery().then((res) => {
            console.log(res);
            setPhotos(res.data.gallery);
            setFilterdPhotos(res.data.gallery);
            let newssss = [];
            if(res.data.gallery.length > 0){
                res.data.gallery.map(item => {
                    // let checkyear = new Date(item.img_year)
                    newssss.push(item.img_year)
                })
            }
            const yearsArray = new Set(newssss);
            setYears(current => [...current, ...yearsArray]);
        }).catch((err) => {
            console.log(err.response);
        })
    }

    const findYearWiseImage = (item) => {
        setTest(item);
        api.getGallery().then((res) => {
           let getData =  res.data.gallery.filter(obj => obj.img_year == item) 
            setFilterdPhotos(getData)
           console.log(getData,'getData');
        }).catch((err) => {
            console.log(err.response);
        })
    }

    const [modalShow, setModalShow] = React.useState(false);
    const [img, setImg] = useState({
        photo   : '',
        caption : '',
        venue   : ''
    });
    
    const handleOpen = (event ,p) => {
        setModalShow(true);
        console.log(event);
        setImg({
            ...img , 
            photo: event.img,
            caption: event.caption,
            venue   : event.venue
        })

    }
    const handleClose = () => setModalShow(false);


    const Gallery = () => {
        return (
            <>
                <div className='m-3 p-3'>
                    <div className="row">
                        {/* {filterdPhotos && filterdPhotos.map((p) => */}
                        {photos && photos.map((p) =>
                        (   <>
                            <div className="col-md-3 p-2">
                                <a href='javascript:void(0)' onClick={()=> handleOpen(p)}>
                                    <img src={`${process.env.REACT_APP_BACKEND}gallery/${p.img} `} className='col-12 gallary-grid' alt="" />
                                    <h6 className='p-2'>{p.caption}</h6>
                                </a>
                            </div>
                            </>
                        ))}
                    </div>
                </div>
            </>
        )
    }


    function ModalImage(props) {
        return (
            <>
                <Modal
                    open={modalShow}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                    <img  src={`${process.env.REACT_APP_BACKEND}gallery/${img.photo} `} className='col-12 gallary-pop'/>
                        <Typography id="modal-modal-description" sx={{ mt: 2 , px : 3 }}>
                            <h4 className='p-2'>{img.caption}</h4>
                            <small>{img.venue}</small>
                        </Typography>
                    </Box>
                </Modal>
            </>
        );
      }

    return (
        <>
            <section className='tabs light-bg' id='tabs'>
                <div className='container' data-aos="fade-up">

                    <div className="text-center">
                        <h1>Some Snaps Of Events At Agora {test && test}</h1>
                    </div>

                    {/* <ul className="nav nav-tabs row d-flex mt-4">
                        <li className="nav-item col-4">
                            <a className="link active show" data-bs-toggle="tab" data-bs-target="#tab-1" onClick={() => getGalleryWithCat('event')}>
                                <h4 >EVENTS</h4>
                            </a>
                        </li>
                        <li className="nav-item col-4">
                            <a className="link" data-bs-toggle="tab" data-bs-target="#tab-2" onClick={() => getGalleryWithCat('rental')}>
                                <h4>RENTAL</h4>
                            </a>
                        </li>
                        <li className="nav-item col-4">
                            <a className="link" data-bs-toggle="tab" data-bs-target="#tab-3" onClick={() => getGalleryWithCat('clubs')}>
                                <h4>CLUBS</h4>
                            </a>
                        </li>
                    </ul> */}

                    <div className='row'>
                        <div className='col-md-12'>
                            {Gallery()}
                        </div>
                        {/* <div className='col-md-2'>
                           <h5>  Gallery Archives </h5>
                            <div className='row archives-section-btn'>
                                {
                             years.map((item , index) => {
                                    return (
                                        <button key={index} type='button' className='archives-btn' onClick={()=>findYearWiseImage(item)}>
                                            {console.log(item,' value')}
                                            {item}
                                        </button>
                                        )
                                    })
                                }
                            </div>
                        </div> */}
                    </div>
                    <ModalImage  />
                </div>
            </section>

        </>
    )
}

export default Imagegallery;