import React from "react";

const RD = () =>  {
    return(
        <>
            <section className="rd light-bg" id="rd">
                <div className="text-center" data-aos="fade-up">
                    <h5 className='sub-title'>OUR ROBUST ADVANCES</h5>
                    <h1> Research Materials </h1>
                </div>

                <div className="container py-3" data-aos="fade-up">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <article className="entry col-lg-12 mt-4 mt-md-0 mx-3" style={{'background':'#ffffff'}}>

                                    <h2 className="entry-title mx-2">
                                        <a href="/rd-single">MHPSS TOOLKIT FOR THE FACILITATORS OF ABITA TEA COMMUNITY WELFARE INITIATIVE</a>
                                    </h2>

                                    <div className="rdMeta">
                                        <ul className="d-flex align-items-center">
                                            <li className="d-flex align-items-center">
                                                <a href="/rd-single"><i className="bi bi-calendar-heart-fill"></i>&nbsp; July 1, 2023</a>
                                            </li>
                                            <li className="d-flex align-items-center mx-3">
                                                <a href="/rd-single"><i className="bi bi-person-heart"></i> &nbsp;Dr. Sangeeta Goswami & Team</a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="rdContent py-3">
                                        <i className="bi bi-tags-fill"></i>
                                        <span className="p-2 m-1">Mental Health & Psycho Social Support (Mhpss)</span>
                                        <span className="p-2 m-1">Abita Tea Community</span>
                                        <span className="p-2 m-1">Welfare Initiatives</span>
                                        <span className="p-2 m-1">Community Well-Being</span>
                                    </div>

                                </article>

                                <article className="entry col-lg-12 mt-4 mt-md-0 mx-3" style={{'background':'#ffffff'}}>

                                    <h2 className="entry-title mx-2">
                                        <a href="/rd-single">SURVEY ON PERCEPTION OF PRIMARY TEACHERS ON COMMON PROBLEM BEHAVIOURS & SKILL DEFICIT BEHAVIOUR</a>
                                    </h2>

                                    <div className="rdMeta">
                                        <ul className="d-flex align-items-center">
                                            <li className="d-flex align-items-center">
                                                <a href="/rd-single"><i className="bi bi-calendar-heart-fill"></i>&nbsp; July 1, 2023</a>
                                            </li>
                                            <li className="d-flex align-items-center mx-3">
                                                <a href="/rd-single"><i className="bi bi-person-heart"></i> &nbsp;Dr. Sangeeta Goswami & Team</a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="rdContent py-3">
                                        <i className="bi bi-tags-fill"></i>
                                        <span className="p-2 m-1">Neuroticism</span>
                                        <span className="p-2 m-1">Brain</span>
                                        <span className="p-2 m-1">Sociocultural</span>
                                        <span className="p-2 m-1">Upliftment-Of-The-Children</span>
                                    </div>

                                </article>

                                <article className="entry col-lg-12 mt-4 mt-md-0 mx-3" style={{'background':'#ffffff'}}>

                                    <h2 className="entry-title mx-2">
                                        <a href="/rd-single">PUPIL'S BEHAVIOUR RATING SCALE</a>
                                    </h2>

                                    <div className="rdMeta">
                                        <ul className="d-flex align-items-center">
                                            <li className="d-flex align-items-center">
                                                <a href="/rd-single"><i className="bi bi-calendar-heart-fill"></i>&nbsp; July 1, 2023</a>
                                            </li>
                                            <li className="d-flex align-items-center mx-3">
                                                <a href="/rd-single"><i className="bi bi-person-heart"></i> &nbsp;Dr. Sangeeta Goswami & Team</a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="rdContent py-3">
                                        <i className="bi bi-tags-fill"></i>
                                        <span className="p-2 m-1">Councelling</span>
                                        <span className="p-2 m-1">Confidential</span>
                                        <span className="p-2 m-1">Noticeable-Behaviour</span>
                                    </div>

                                </article>

                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default RD;