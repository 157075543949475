import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Header = () => {

    const [isToggle, setIsToggle] = useState(false);

    const handleToggle = event => {
        setIsToggle(current => !current);
    }

    const [fixed, setFixed] = useState(false);

    function setFix(){
        if(window.scrollY >= 100){
            setFixed(true)
        }
        else{
            setFixed(false);
        }
    }

    window.addEventListener("scroll", setFix)



    return (
        <>
            <div className="upperLogo text-center m-2"><a href="/" className="logo me-auto my-2"><img src="/black.png" /></a>
            </div>
            <header id="header" className={fixed ? 'fixed' : 'header'}>
                
                <div className="container d-flex justify-content-center ">
                    
                    {/* <img className="m-logo mt-2" src="/AgoraWhite.png"  width={80}  style={{marginLeft: '-100px'}}/> */}
                    <img className="m-logo" src="/black.png"  width={120} style={{marginLeft: '-100px', objectFit : 'contain', padding : 10}}/>
                    {/* <img className="m-logo" src="/white-logo.png"  width={120} style={{marginLeft: '-100px', objectFit : 'contain'}}/> */}
                    

                    <nav id="navbar" className={`navbar order-last order-lg-0 ${isToggle ? 'navbar-mobile' : ''}`}>
                        <ul>
                            <li><NavLink className="nav-link scrollto" to="/">HOME</NavLink></li>
                            <li><NavLink className="nav-link scrollto" to="/about">ABOUT</NavLink></li>
                            <li><NavLink className="nav-link scrollto" to="/rental">RENTAL</NavLink></li>
                            <li><NavLink className="nav-link scrollto " to="/events">EVENTS</NavLink></li>
                            <li><NavLink className="nav-link scrollto" to="/blog">BLOG</NavLink></li>
                            <li><NavLink className="nav-link scrollto" to="/gallery">MEDIA & GALLERY</NavLink></li>
                            <li><NavLink className="nav-link scrollto " to="/contact">CONTACT US</NavLink></li>
                            <li><NavLink className="nav-link scrollto" to="/member">BECOME A MEMBER</NavLink></li>
                        </ul>

                        <i className={`toggle bi mobile-nav-toggle mx-4 ${isToggle ? 'bi-x' : 'bi-list'}`} onClick={handleToggle} ></i>

                    </nav>

                    {/* <NavLink to="#" className="get-started-btn scrollto">Get Started</NavLink> */}
                </div>
                {/* <i className={`bi mobile-nav-toggle mx-4 ${isToggle ? 'bi-x' : 'bi-list'}`} onClick={handleToggle} ></i> */}
            </header>
        </>

    )
}

export default Header;