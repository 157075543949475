import React, {useEffect} from "react";
import Header from "../component/common/header";
import Footer from "../component/common/footer";
import Blogs from "../component/blogs/allBlogs";

const BlogPage=()=>{
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return (
        <>
            <Header />
            <Blogs />
            <Footer />
        </>
    )
}

export default BlogPage;