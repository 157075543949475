import React, {useEffect} from "react";
import Header from "../component/common/header";
import Footer from "../component/common/footer";
import RD from "../component/research/RD";

const ResearchDev = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return (
        <>
            <Header />
            <RD />
            <Footer />
        </>
    )
}

export default ResearchDev;