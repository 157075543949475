import React, { useState, useEffect } from "react";
import Header from "../component/common/header";
import Footer from "../component/common/footer";
import api from "../API/api";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const htmlToFormattedText = require("html-to-formatted-text");

const PaymentPage = () => {
    const params = useParams();

    console.log("params", params.u_id);
    console.log("location", window.location.pathname);

    const [data, setdata] = useState();
    const [user, setUser] = useState();

    const getPaymentInfo = () => {
        api
            .getPaymentInfo(params.u_id)
            .then((res) => {
                console.log("res", res);
                setdata(res.data.data);
                setUser(res.data.user);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getPaymentInfo();
    }, []);

    const [file, setFile] = useState();

    const handleUpload = () => {
        const api = process.env.REACT_APP_MAIN_API + "/uploadScreenshoot";
        const formdata = new FormData();
        formdata.append(`img`, file);
        formdata.append(`u_id`, params.u_id);

        axios
            .post(api, formdata)
            .then((res) => {
                console.log(res);
                Swal.fire("Uploaded!", "Your Screenshoot uploaded successfully.");
            })
            .catch((err) => {
                console.log(err.response);
            });
    };

    return (
        <>
            <Header />

            {data && (
                <div className="container p-5">
                    <div className="row">
                        <div>Hi {user?.name}!</div>

                        {window.location.pathname == `/PaymentPage/r/${params.u_id}`
                            ?

                            <div>
                                <p>
                                    we regret to inform you that your preferred date & time slots aren't available.
                                </p>
                                <p>
                                    The next available date & time slot is {data.date} | {data.from_time} .
                                </p>
                                <p>
                                    If you choose to proceed with the revised date & time, the booking amount will be {data.amount}.
                                </p>
                            </div>

                            :

                            <div>
                                <p>
                                    In response to your request for renting space at Agora on the{" "}
                                    {data.date} from {data.from_time} to {data.to_time}, we are
                                    happy to inform you that your preferred date and time slot is
                                    available!
                                </p>
                                <p>The booking amount is {data.amount}</p>
                                <p>
                                    To confirm your booking, please make the payment of{" "}
                                    {data.amount} by {data.due_date}.
                                </p>
                            </div>
                        }

                        <p>
                            <strong>Payment Information -</strong>
                        </p>

                        <p>
                            {/* {data.mail} */}
                            <div dangerouslySetInnerHTML={{ __html: data.mail }} />
                        </p>

                        <div className="my-3 py-3">
                            <div className="row">
                                <h3> Please upload your payment Screenshoot here</h3>

                                <div className="col-md-6 py-2">
                                    <input
                                        type="file"
                                        onChange={(e) => setFile(e.target.files[0])}
                                        className="form-control"
                                        name=""
                                        id=""
                                    />
                                </div>
                                <div className="col-md-6 py-2">
                                    <button className="btn btn-success" onClick={handleUpload}>
                                        Upload
                                    </button>
                                </div>
                            </div>
                        </div>

                        <p>
                            <br />
                            <br />
                            <br />
                            In case of any issue / concern regarding the payment, you may get
                            in touch with us via mail at agorathespace@gmail.com or over a
                            call at +919711905346.
                        </p>

                        <br />
                        <br />
                        <br />
                        <p>
                            Warm Regards,
                            <br />
                            Team Agora
                        </p>
                    </div>
                </div>
            )}

            <Footer />
        </>
    );
};

export default PaymentPage;
