import React, { useEffect, useState } from "react";

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import api from "../../../API/api";
import MembersList from "../tables/members";
import TestimonialTable from "../tables/testimonialTable";

const Testimonial = () => {
    return (
        <>
            <Container  maxWidth="100%" style={{'background':'#fbfbfb'}}>
                <Box style={{'padding':'30px'}}>
                    <TestimonialTable />
                </Box>
            </Container>
        </>
    )
}

export default Testimonial;