import React from "react";

const Welcome = () => {
    return (
        <>
            <section className="">
                <div className="bg-1"></div>
                <div className="container p-5">
                    <div className="row" data-aos="fade-up">
                        <div className="col-md-12 text-center">
                        <h5 className='sub-title'>WELCOME</h5>
                        <h1> Your Multipurpose Artspace  </h1>
                        <p className="py-2">‘Agora’ translates to a meeting space for people, in an eclectic art & cultural space focusing on the interaction of art, culture and positive wellbeing.</p>
                        {/* <img src='/underline.png' className="underlineImage"/> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Welcome;