import React, {useEffect} from "react";
import Header from "../component/common/header";
import Footer from "../component/common/footer";
import BlogSingle from "../component/blogs/blogSingle";

const BlogSinglePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return (
        <>
            <Header />
            <BlogSingle />
            <Footer />
        </>
    )
}

export default BlogSinglePage;