import React, { useEffect, useState } from "react";
import api from "../../API/api";

const htmlToFormattedText = require("html-to-formatted-text");


const Blogs = () => {

    const [blogs, setBlogs] = useState();

    useEffect(() => {
        getBlogs();
    }, []);

    const getBlogs = () => {
        api.getBlog()
            .then((res) => {
                console.log(res);
                setBlogs(res.data.allblogs)
            })
            .catch((err) => {
                console.log(err.response);
            })
    }


    return (
        <main id="main">
            <section id="blog" className="blog">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-lg-8 entries">
                            <div className="row">

                                {blogs && blogs.map((b) => (


                                    <article className="entry col-sm-5 mt-4 mt-md-0 mx-3">

                                        <div className="entry-img">
                                            <img src={`${process.env.REACT_APP_BACKEND}blog/${b.cover}`} alt="" className="img-fluid" />
                                        </div>

                                        <h2 className="entry-title">
                                            <a href={`/blog/${b.id}`}>{b.title} </a>
                                        </h2>

                                        <div className="entry-meta">
                                            <ul>
                                                <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href={`/blog/${b.id}`}>{b.author}</a></li>
                                                <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href={`/blog/${b.id}`}><time dateTime="2020-01-01">{b.date}</time></a></li>
                                            </ul>
                                        </div>

                                        <div className="entry-content">
                                            <p>

                                            {htmlToFormattedText(b.details).slice(0, 290)}...
                                            </p>
                                            <div className="read-more">
                                                <a href={`/blog/${b.id}`} className="rentBtn active">Read More</a>
                                            </div>
                                        </div>

                                    </article>

                                ))}

                            </div>

                            <div className="blog-pagination">
                              
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="sidebar">

                                <h3 className="sidebar-title">Search</h3>
                                <div className="sidebar-item search-form">
                                    <form action="">
                                        <input type="text" />
                                        <button type="submit"><i className="bi bi-search"></i></button>
                                    </form>
                                </div>

                                <h3 className="sidebar-title">Categories</h3>
                                <div className="sidebar-item categories">
                                    <ul>
                                        <li><a href="#">Events <span></span></a></li>
                                    </ul>
                                </div>

                                {/* <h3 className="sidebar-title">Recent Posts</h3>
                                <div className="sidebar-item recent-posts">
                                    <div className="post-item clearfix">
                                        <img src="assets/blog/blog-recent-1.jpg" alt="" />
                                        <h4><a href={`/blog/${b.id}`}>Nihil blanditiis at in nihil autem</a></h4>
                                        <time dateTime="2020-01-01">Jan 1, 2020</time>
                                    </div>

                                    <div className="post-item clearfix">
                                        <img src="assets/blog/blog-recent-2.jpg" alt="" />
                                        <h4><a href={`/blog/${b.id}`}>Quidem autem et impedit</a></h4>
                                        <time dateTime="2020-01-01">Jan 1, 2020</time>
                                    </div>

                                    <div className="post-item clearfix">
                                        <img src="assets/blog/blog-recent-3.jpg" alt="" />
                                        <h4><a href={`/blog/${b.id}`}>Id quia et et ut maxime similique occaecati ut</a></h4>
                                        <time dateTime="2020-01-01">Jan 1, 2020</time>
                                    </div>

                                    <div className="post-item clearfix">
                                        <img src="assets/blog/blog-recent-4.jpg" alt="" />
                                        <h4><a href={`/blog/${b.id}`}>Laborum corporis quo dara net para</a></h4>
                                        <time dateTime="2020-01-01">Jan 1, 2020</time>
                                    </div>

                                    <div className="post-item clearfix">
                                        <img src="assets/blog/blog-recent-5.jpg" alt="" />
                                        <h4><a href={`/blog/${b.id}`}>Et dolores corrupti quae illo quod dolor</a></h4>
                                        <time dateTime="2020-01-01">Jan 1, 2020</time>
                                    </div>

                                </div> */}

                                <h3 className="sidebar-title">Tags</h3>
                                <div className="sidebar-item tags">
                                    <ul>
                                        <li><a href="#">Events</a></li>
                                        <li><a href="#">Drama</a></li>
                                        <li><a href="#">Play</a></li>
                                        <li><a href="#">Practice</a></li>
                                        <li><a href="#">Stage</a></li>
                                        <li><a href="#">Smart</a></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}

export default Blogs;