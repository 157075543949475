import React from "react";

const RentalInfomation = (props) => {

    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="php-email-form">

                                <div className="row">
                                    <div className="col-md-6 form-group mt-3">
                                        <label>Banner Image</label><br /><br/>
                                        <input type="file" className="form-control"/>
                                    </div>

                                    <div className="col-md-6 form-group mt-3">
                                        <label>Portrait Image</label><br /><br/>
                                        <input type="file" className="form-control"/>
                                    </div>

                                    <div className="col-md-12 form-group mt-3">
                                        <label>Create A Image Gallery</label><br /><br/>
                                        <input type="file" className="form-control" multiple/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RentalInfomation;