import './App.css';
import './custom.css';
import { Routes, Route } from "react-router-dom";

import { PAGES } from './utils/routes';
import Login from './component/admin/form/login';
import AuthUser from './API/token';

function App() {
  const { getToken } = AuthUser();
  // if (!getToken()) {
  //   return <Login />
  // }


  return (
    <>

      {getToken() &&
        <Routes>

          {/* admin  */}

          <Route path={PAGES.Dashboard.path} element={<PAGES.Dashboard.Component />}>

            <Route path={PAGES.AddEvents.path} element={<PAGES.AddEvents.Component />} />
            <Route path={PAGES.AddEventsWithId.path} element={<PAGES.AddEventsWithId.Component />} />
            <Route path={PAGES.EditEventsWithId.path} element={<PAGES.EditEventsWithId.Component />} />
            {/* <Route path={PAGES.Media.path} element={<PAGES.Media.Component />} /> */}
            <Route path={PAGES.ManageGallery.path} element={<PAGES.ManageGallery.Component />} />
            <Route path={PAGES.BlogPanel.path} element={<PAGES.BlogPanel.Component />} />
            <Route path={PAGES.ResearchPapers.path} element={<PAGES.ResearchPapers.Component />} />
            <Route path={PAGES.Table.path} element={<PAGES.Table.Component />} />
            <Route path={PAGES.BlogTable.path} element={<PAGES.BlogTable.Component />} />
            <Route path={PAGES.ViewEvents.path} element={<PAGES.ViewEvents.Component />} />
            <Route path={PAGES.DashboardPage.path} element={<PAGES.DashboardPage.Component />} />
            <Route path={PAGES.AdminRentalPage.path} element={<PAGES.AdminRentalPage.Component />} />
            <Route path={PAGES.RentalRequest.path} element={<PAGES.RentalRequest.Component />} />
            <Route path={PAGES.ContactUsTable.path} element={<PAGES.ContactUsTable.Component />} />
            <Route path={PAGES.AddMembers.path} element={<PAGES.AddMembers.Component />} />
            <Route path={PAGES.MembersList.path} element={<PAGES.MembersList.Component />} />

            <Route path={PAGES.Allevents.path} element={<PAGES.Allevents.Component />} />
            <Route path={PAGES.RegistrationTable.path} element={<PAGES.RegistrationTable.Component />} />
            <Route path={PAGES.Payment.path} element={<PAGES.Payment.Component />} />
            <Route path={PAGES.Testimonial.path} element={<PAGES.Testimonial.Component />} />
            <Route path={PAGES.AddTestimonial.path} element={<PAGES.AddTestimonial.Component />} />

            {/* <Route path='/*' element={<PAGES.ErrorPage.Component />} /> */}

          </Route>


        </Routes>
      }

      {!getToken() &&
        <Routes>
          <Route path='/admin/*' element={<PAGES.Login.Component />} />

        </Routes>

      }


      <Routes>
        <Route path={PAGES.Home.path} element={<PAGES.Home.Component />} />
        <Route path={PAGES.Aboutpage.path} element={<PAGES.Aboutpage.Component />} />
        <Route path={PAGES.BlogPage.path} element={<PAGES.BlogPage.Component />} />
        <Route path={PAGES.BlogSinglePage.path} element={<PAGES.BlogSinglePage.Component />} />
        <Route path={PAGES.RentalPage.path} element={<PAGES.RentalPage.Component />} />
        <Route path={PAGES.MemberPage.path} element={<PAGES.MemberPage.Component />} />
        <Route path={PAGES.UpcomingEvents.path} element={<PAGES.UpcomingEvents.Component />} />
        <Route path={PAGES.Welcome.path} element={<PAGES.Welcome.Component />} />
        <Route path={PAGES.RentSection.path} element={<PAGES.RentSection.Component />} />
        <Route path={PAGES.Volunteer.path} element={<PAGES.Volunteer.Component />} />
        <Route path={PAGES.EventPage.path} element={<PAGES.EventPage.Component />} />
        <Route path={PAGES.SingleEventPage.path} element={<PAGES.SingleEventPage.Component />} />
        <Route path={PAGES.Imagegallery.path} element={<PAGES.Imagegallery.Component />} />
        <Route path={PAGES.Gallery.path} element={<PAGES.Gallery.Component />} />
        <Route path={PAGES.ClubPage.path} element={<PAGES.ClubPage.Component />} />
        <Route path={PAGES.ResearchDev.path} element={<PAGES.ResearchDev.Component />} />
        <Route path={PAGES.RdSinglePage.path} element={<PAGES.RdSinglePage.Component />} />
        



        <Route path={PAGES.Login.path} element={<PAGES.Login.Component />} />
        <Route path={PAGES.Register.path} element={<PAGES.Register.Component />} />
        <Route path={PAGES.ContactPage.path} element={<PAGES.ContactPage.Component />} />
        <Route path={PAGES.PaymentPage.path} element={<PAGES.PaymentPage.Component />} />
        <Route path={PAGES.reschedule_PaymentPage.path} element={<PAGES.reschedule_PaymentPage.Component />} />
        <Route path='/error' element={<PAGES.ErrorPage.Component />} />



      </Routes>










    </>
  );
}

export default App;
