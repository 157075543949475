import API from "./apiConfig";

export default {

    getEvents() {
        return API.get('/getEvents');
    },
    getEventData(id) {
        return API.get(`/getEventData/${id}`);
    },
    updateEvent(data) {
        return API.post('/updateEvent', { data });
    },
    postEvents(formData) {
        return API.post('/postEvents', { formData });
    },
    createEvent(formData) {
        return API.post('/createEvent', { formData });
    },



    getEventImg(id) {
        return API.get(`/getEventImg/${id}`);
    },
    getBlog() {
        return API.get('/getBlog');
    },

    // login 
    register(formData) {
        return API.post('/register', { formData });
    },

    login(email, password) {
        return API.post('/login', { email, password })
    },

    getRental() {
        return API.post('getRental');
    },
    RentalRequest(FormData) {
        return API.post('/RentalRequest', { FormData });
    },
    getIndivisualRetalRequest(user_id) {
        return API.post('/getIndivisualRetalRequest', { user_id });
    },

    rescheduleRental(user, form, msg) {
        return API.post('/rescheduleRental', { user, form, msg });
    },
    acceptApproval(user, form) {
        return API.post('/acceptApproval', { user, form });
    },
    delayApproval(user_id) {
        return API.post('/delayApproval', { user_id });
    },
    confirmPayment(user_id) {
        return API.post('/confirmPayment', { user_id });
    },
    enableEvent(id) {
        return API.post('/enableEvent', { id });
    },


    internForm(FormData) {
        return API.post('/internForm', { FormData });
    },
    
    getUpcomingEvents() {
        return API.get('/getUpcomingEvents');
    },
    getSingleBlog(id) {
        return API.get(`/getSingleBlog/${id}`);
    },
    deleteBlog(id) {
        return API.post(`/deleteBlog`,{id});
    },
    getIntership() {
        return API.get('/getIntership');
    },
    getGallery() {
        return API.get('/getGallery');
    },
    deleteGalleryImage(data) {
        return API.post('/deleteGalleryImage' , {data});
    },
    getGalleryWithCat(cat) {
        return API.get(`/getGalleryWithCat/${cat}`);
    },
    manageGalleryStatus(type, value, id) {
        return API.post('/manageGalleryStatus', { type, value, id });
    },

    postCountactUs(data) {
        return API.post('/postCountactUs', { data });
    },
    getContactUs() {
        return API.get('/getContactUs');
    },
    countData() {
        return API.get('/countData');
    },

    getTicketData(){
        return API.get('/getTicketData');
    },
    getRegistration(){
        return API.get('/getRegistration');
    },

    saveRegistration(data) {
        return API.post('/saveRegistration', { data });
    },
    getMembers() {
        return API.get('/getMembers')
    },
    editMembers(data) {
        return API.post('/editMembers' ,{data})
    },
    deleteMembers(data){
        return API.post('/deleteMembers' , {data})
    },
    getTestimonial() {
        return API.get('/getTestimonial')
    },
    deleteTestimonial(data){
        return API.post('/deleteTestimonial' , {data})
    },
    getPaymentInfo(data){
        return API.post('/getPaymentInfo' , {data})
    },
    
}