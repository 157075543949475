import React, { useEffect, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation , useNavigate } from "react-router-dom";


const AddTestimonial = (props) => {
    const navigate =useNavigate();
    const location = useLocation();
    console.log(location && location ,'locaton');

    const [formdata , setFormdata] = useState({
        name :  '',
        date : '',
        description : '',
        id          : ''
    })
    const [img, setImg] = useState();
    const [error , setError] = useState();

    const handleimg = (e) => {
        console.log();
        setImg(e.target.files[0])
        console.log(e.target.files[0],'sdsd');
    }

    const handlechange = (e) => {
        
        setFormdata({
            ...formdata,
            [e.target.name] : e.target.value
        })
    }

    const hanldeSubmit = () => {
        console.log(formdata);
        console.log(img);

        

        const formData2 = new FormData();
            formData2.append('name' , formdata.name);
            {
                formdata.id && formData2.append('id' , formdata.id)
            }
            formData2.append('date' , formdata.date);
            formData2.append('description' , formdata.description);
            formData2.append('img' , img);



            axios.post(`${process.env.REACT_APP_MAIN_API}/addTestimonial`, formData2)
            .then(res => {
                console.log(res,'check res');
                toast.success(`Testimonial succesfully added`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }).catch(err => {
                console.log(err,'check res');
            })
    }


    return (
        <>
            <ToastContainer />
            <section  className="eventForm">
                <div className="container">
                    <h3 className="text-center"> {location.state ? 'Edit' : 'Add'} Testimonial</h3>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="php-email-form">
                                {/* **************up*************** */}
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Name of User</label><br />
                                        <input type="text" className="form-control" name="name" onChange={handlechange}  value={formdata.name}/>
                                         {   error?.name ? <small className="text-danger">{error.name}</small> : ''}
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Date</label><br />
                                        <input type="date" className="form-control" name="date" onChange={handlechange} value={formdata.designation} />
                                        {   error?.designation ? <small className="text-danger">{error.designation}</small> : ''}
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Picture of his/her ? ?</label><br />
                                        <input type="file" className="form-control" name="image" onChange={handleimg}  />
                                    </div>

                                    <div className="col-md-12 form-group">
                                        <label> Breif </label><br />
                                        <textarea  className="form-control"   rows={5} cols={5}  onChange={handlechange} name="description" value={formdata?.description} /> 
                                        
                                        {   error?.description ? <small className="text-danger">{error.description}</small> : ''}
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-primary   m-auto" onClick={hanldeSubmit}> Submit Form</button>
                                    <button className="btn btn-outline   m-auto" onClick={()=>navigate(-1)}> Goto  Previous Page</button>
                                </div>
                                {/* **************down*************** */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddTestimonial;