import React, { useState, useEffect } from "react";

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Paper } from "@mui/material";
import api from "../../API/api";




const Volunteer = () => {

    const [FormData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        address: '',
        specialization: '',
        clubs: [],
    });


    const handleInput = (e) => {
        console.log(e.target.value);
        setFormData({
            ...FormData,
            [e.target.name]: e.target.value
        })
    }

    const handleClub = (e, value) => {
        console.log(value);
        setFormData({
            ...FormData,
            clubs: value
        })
    }

    const handleSubmit = () => {
        console.log(FormData);

        api.internForm(FormData)
            .then((res) => {
                console.log(res);
                toast.success(res.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

            })
            .catch((err) => {
                console.log(err.response);
            })
    }




    const category = [
        { title: 'Theatre/Play', id: 1 },
        { title: 'Music & Poetry Nights', id: 2 },
        { title: 'Open Mic', id: 3 },

    ];



    return (
        <>


            <ToastContainer />

            <section id="volunteer" className="bg-custom-light">
                <div className="container p-5'">
                    <div className="row justify-content-center" data-aos="fade-up">
                        <div className="col-md-12 text-center">
                            <h1> Become An Intern / Volunteers </h1>
                            <p className="py-2">Fill up the form to join as an intern</p>
                        </div>

                        <Paper elevation={3}>
                            <div className="row">
                                <div className="col-md-4 my-2 p-0">

                                    <div className="php-email-form" >
                                        <div className="row">

                                            <div className="col form-group">
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        '& > :not(style)': { p: 1, width: '100%' },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <TextField id="outlined-basic" name="name" value={FormData.name} onChange={handleInput} label="Full name" variant="outlined" />
                                                </Box>

                                            </div>
                                            <div className="col form-group">
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        '& > :not(style)': { p: 1, width: '100%' },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <TextField type="number" id="outlined-basic" name="phone" value={FormData.phone} onChange={handleInput} label="Phone" variant="outlined" />
                                                </Box>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& > :not(style)': { p: 1, width: '100%' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <TextField id="outlined-basic" name="email" value={FormData.email} onChange={handleInput} label="Email" variant="outlined" />
                                            </Box>
                                        </div>
                                        <div className="form-group">
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& > :not(style)': { p: 1, width: '100%' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <TextField id="outlined-basic" name="address" value={FormData.address} onChange={handleInput} label="Address" variant="outlined" />
                                            </Box>
                                        </div>
                                        <div className="form-group">
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& > :not(style)': { p: 1, width: '100%' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <TextField id="outlined-basic" name="specialization" value={FormData.specialization} onChange={handleInput} label="Specialization" variant="outlined" />
                                            </Box>
                                        </div>

                                        <div className="form-group">
                                            <Box
                                                component="form"
                                                sx={{
                                                    '& > :not(style)': { p: 1, width: '100%' },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <Autocomplete
                                                    multiple
                                                    id="tags-outlined"
                                                    options={category}
                                                    getOptionLabel={(option) => option.title}
                                                    // defaultValue={[category[13]]}
                                                    onChange={(e, value) => handleClub(e.target, value)}

                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            // variant="standard"
                                                            label="Select Clubs"
                                                            placeholder="Clubs"

                                                        />
                                                    )}
                                                />
                                            </Box>
                                        </div>

                                        <div className="text-center pt-3"><button type="submit" onClick={handleSubmit} style={{ 'width': '100%' }}>Join Now</button></div>
                                    </div>

                                </div>

                                <div className="col-md-8 my-2 p-0">
                                    {/* <img src="intern2.jpg" alt="" className="img-fluid" /> */}
                                    <img src="assets/1.jpg" alt="" className="img-fluid" />
                                </div>
                            </div>
                        </Paper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Volunteer;