import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import api from "../../API/api";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';

import CategoryImg from "../gallery/categoryImg";

import UserForm from "./userForm";


const Rental = () => {

    const [FormData, setFormdata] = useState({
        name: '',
        email: '',
        phone: '',
        description: '',
        purpose: '',
        date: '',
        fromTime: '',
        slot: '',
        estimated_hours: ''
    })

    useEffect(() => {
        console.log(FormData && FormData, 'FormData');
    }, [FormData])
    const [isSuccess, setIsSuccess] = useState(false);

    const [rentals, setRentals] = useState([]);
    const [bookedrental, setBookedrental] = useState([]);

    const [others, setOthers] = useState(false);
    const [from_time, setFromTime] = useState(new Date());
    const [toTime, setToTime] = useState();


    const [showResults, setShowResults] = React.useState(false);

    const handleCategory = (e) => {
        console.log(e);
        setFormdata({
            ...FormData,
            'purpose': e
        })
        setShowResults(true);
        setShowData(false);
        setShowCircle(false);
    }

    const [showData, setShowData] = useState(false);
    const clickData = () => {

        if (FormData.slot === '') {
            console.log("Slot can not be empty ");

            Swal.fire({
                title: "Slot can not be empty",
                text: "missing",
                icon: 'warning',
            })

            return;
        }

        if (FormData.fromTime === '' && others == false) {
            console.log("From Time can not be empty ");

            Swal.fire({
                title: "From Time can not be empty",
                text: "missing",
                icon: 'warning',
            })

            return;
        }
        if (FormData.estimated_hours === '' && others == false) {
            console.log("Estimated Hours can't be Empty ");

            Swal.fire({
                title: "Estimated Hours can't be Empty",
                text: "missing",
                icon: 'warning',
            })

            return;
        }

        setShowData(true);
        setShowResults(false);
        setShowCircle(false)
    }

    const [showCircle, setShowCircle] = useState(true);

    const [showBackDate, setShowBackDate] = useState(true);
    const ClickBackDate = () => {
        setShowCircle(true);
        setShowData(false);
        setShowResults(false);
        setShowBackDate(false);
    }

    const [showBackData, setShowBackData] = useState(true);
    const ClickBackData = () => {
        setShowCircle(false);
        setShowData(false);
        setShowResults(true);
        setShowBackData(false);
    }

    useEffect(() => {
        getRental()
    }, []);

    const handleInput = (e) => {
        console.log(e.target.name);
        setFormdata({
            ...FormData,
            [e.target.name]: e.target.value
        })
        if (e.target.value == 600) {
            setOthers(true);
        } else {
            setOthers(false);
        }
    }
    console.log(FormData && FormData, 'FormData');
    const handleDate = (e) => {

        setFromTime(e);

        var date = new Date(e),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        var selectedDate = [date.getFullYear(), mnth, day].join("-");
        console.log(selectedDate);

        setFormdata({
            ...FormData,
            'date': selectedDate
        })

        const findbookedRental = rentals.filter((e) => e.date == selectedDate);
        console.log(findbookedRental);

        setBookedrental(findbookedRental);
    }

    const handleTime = (date) => {
        console.log("=>", date);
        setFromTime(date);

        var t = new Date(date);
        var hour = t.getHours();
        var min = t.getMinutes();

        if (hour < 10) {
            hour = '0' + hour;
        }
        if (min < 10) {
            min = '0' + min;
        }
        var time = hour + ':' + min
        console.log(time);

        setFormdata({
            ...FormData,
            'fromTime': time
        })

        var to_time = new Date(date.getTime() + FormData.slot * 60000);

        console.log(to_time);
        setToTime(to_time);

        bookedrental.forEach((e) => {
            if (e.from_time <= time && e.to_time >= time) {

                toast.error('Time Slot is already booked', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                setFromTime('');
                setToTime('');
                setFormdata({
                    ...FormData,
                    'slot': ''
                })
            }
        })
    }


    const getRental = () => {
        api.getRental()
            .then((res) => {
                console.log("rent", res.data.rentals);
                setRentals(res.data.rentals)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleSubmit = () => {

        if (FormData.name === '') {
            console.log("Name can not be empty ");

            Swal.fire({
                title: "Name can not be empty",
                text: "missing",
                icon: 'warning',
            })

            return;
        }

        if (FormData.email === '') {
            console.log("Email can not be empty ");

            Swal.fire({
                title: "Email can not be empty",
                text: "missing",
                icon: 'warning',
            })

            return;
        }

        if (FormData.phone === '') {
            console.log("Phone No can not be empty ");

            Swal.fire({
                title: "Phone No can not be empty",
                text: "missing",
                icon: 'warning',
            })

            return;
        }

        if (FormData.description === '') {
            console.log("Description can not be empty ");

            Swal.fire({
                title: "Description can not be empty",
                text: "missing",
                icon: 'warning',
            })

            return;
        }
        if (FormData.date === '') {
            console.log("Date can not be empty ");

            Swal.fire({
                title: "Date can not be empty",
                text: "missing",
                icon: 'warning',
            })

            return;
        }


        console.log(FormData);
        api.RentalRequest(FormData)
            .then((res) => {
                console.log(res);
                if (res.data.status == 200) {
                    setIsSuccess(true)
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else {
                    toast.error("Something went wrong", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((err) => {
                console.log(err.response);
                toast.error("Something went wrong", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
    }

    const ShowCircle = () => {
        return (
            <div className="col-lg-6 my-2">
                <h1 className="text-center my-3">
                    Pick A Category
                </h1>
                <div className="row mt-4">
                    <div className="circle">
                        <button onClick={() => handleCategory('Theatre / Play')}>Theatre / Play</button>
                        <button onClick={() => handleCategory('Music / Poetry Nights')}>Poetry Nights </button>
                        <button onClick={() => handleCategory('Open Mic')}>Open Mic</button>
                        <button onClick={() => handleCategory('Talk / Lecture Series')}>Talk / Lecture Series</button>
                        <button onClick={() => handleCategory('Share Circles')}>Share Circles</button>
                        <button onClick={() => handleCategory('Exhibitions & Art Gallery')}>Exhibitions</button>
                        <button onClick={() => handleCategory('Exhibitions & Art Gallery')}>Art Gallery</button>
                        <button onClick={() => handleCategory('Book readings')}>Book Readings</button>
                        <button onClick={() => handleCategory('Film Screenings')}>Film Screenings</button>
                        <button onClick={() => handleCategory('Video Production')}>Video Production</button>
                        <button onClick={() => handleCategory('Private Event')}>Private Event</button>
                        <button onClick={() => handleCategory('Practise Session')}>Practise Session</button>
                    </div>

                    <div className="text-center">
                        <span className="dotPage activeDot">1</span>
                        <span className="dotPage">2</span>
                        <span className="dotPage">3</span>
                    </div>

                </div>
            </div>
        )
    }

    const BackDate = () => {
        return (
            <div className="back" onClick={ClickBackDate}>
                <i className="bi bi-arrow-90deg-left"></i>
            </div>
        )
    }

    const BackData = () => {
        return (
            <div className="back" onClick={ClickBackData}>
                <i className="bi bi-arrow-90deg-left"></i>
            </div>
        )
    }

    const DisplayDate = () => {
        return (
            <div className="col-lg-6 my-2 second">
                <div role="form" className="php-email-form mt-4" style={{ 'background': '#ffffff' }}>
                    <div className="row">
                        <BackDate />
                        <div className="col-md-6  mt-3 form-group">
                            <label>Select A Date</label><br /><br />
                            <DatePicker
                                selected={from_time}
                                onChange={handleDate}
                            />
                        </div>

                        {/* <div className="col-md-6 mt-3 form-group">
                            <label>Select a time slot</label> <br /><br />
                            <select onChange={handleInput} value={FormData.slot} name="slot">
                                <option value="">--select--</option>
                                <option value="45">45 minutes</option>
                                <option value="90">90 minutes</option>
                                <option value="180">180 minutes</option>
                                <option value="300">Half Day</option>
                                <option value="600">Full Day</option>
                            </select>
                        </div> */}
                        <div className="col-md-6 mt-3 form-group">
                            <label>Select a time slot</label> <br /><br />
                            <select onChange={handleInput} value={FormData.slot} name="slot" className="deep">
                                <option value="" disabled>--select--</option>
                                <option value="600">Full day</option>
                                <option value="100">Hourly </option>
                            </select>
                        </div>
                        {
                            others == true ? '' :
                                <>
                                    <div className="col-md-6  mt-3 form-group">
                                        <label>Starting Time From</label><br /><br />
                                        <DatePicker
                                            selected={from_time}
                                            onChange={handleTime}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                        />
                                    </div>
                                    <div className="col-md-6 mt-3 form-group">
                                        <label>Select Estimated Hours</label> <br /><br />
                                        <select onChange={handleInput} value={FormData.estimated_hours} name="estimated_hours" className="deep">
                                            <option value="" disabled>--select--</option>
                                            <option value="1">1 Hour</option>
                                            <option value="2">2 Hours </option>
                                            <option value="3">3 Hours </option>
                                            <option value="4">4 Hours </option>
                                            <option value="5">5 Hours </option>
                                            <option value="6">6 Hours </option>
                                            <option value="7">7 Hours </option>
                                            <option value="8">8 Hours </option>
                                            <option value="9">9 Hours </option>
                                            <option value="10">10 Hours </option>
                                            <option value="11">11 Hours </option>
                                            <option value="12">12 Hours </option>
                                        </select>
                                    </div>
                                </>
                        }

                        {/* <div className="col-md-6  mt-3 form-group">
                            <label>Time To</label><br /><br />
                            <DatePicker
                                selected={toTime}
                                disabled
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                            />
                        </div> */}

                    </div>

                    {!isSuccess ?
                        <div className="text-center" onClick={clickData} style={{ 'paddingBottom': '15px' }}><button type="submit">Next</button>
                        </div>
                        :

                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="success">We will get back you soon, Please Check Your email !</Alert>
                        </Stack>
                    }

                </div>

                <div className="text-center mt-4">
                    <span className="dotPage">1</span>
                    <span className="dotPage activeDot">2</span>
                    <span className="dotPage">3</span>
                </div>

            </div>
        )
    }

    const DisplayData = () => {
        return (
            <div className="col-lg-6 my-2">
                <div role="form" className="php-email-form mt-4" style={{ 'background': '#ffffff' }}>
                    <div className="row">
                        <BackData />
                        <div className="col-md-6 mt-3 form-group">
                            <TextField fullWidth id="standard-basic" name="name" value={FormData.name} onChange={handleInput} label="Name" variant="standard" />


                        </div>
                        <div className="col-md-6  mt-3 form-group">
                            <TextField fullWidth id="standard-basic" name="email" value={FormData.email} onChange={handleInput} label="Email" variant="standard" />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mt-3 form-group">
                            <TextField fullWidth id="standard-basic" name="phone" value={FormData.phone} onChange={handleInput} label="Phone No" variant="standard" />
                        </div>
                        <div className="col-md-6 mt-3 form-group">
                            <TextField fullWidth id="standard-basic" name="description" value={FormData.description} onChange={handleInput} label="Short Event Description" variant="standard" />
                        </div>
                    </div>

                    {!isSuccess ?
                        <div className="text-center" onClick={handleSubmit} style={{ 'paddingBottom': '15px' }}><button type="submit">Send Request</button>
                        </div>
                        :

                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="success">We will get back you soon, Please Check Your email !</Alert>
                        </Stack>
                    }
                </div>

                <div className="text-center mt-4">
                    <span className="dotPage">1</span>
                    <span className="dotPage">2</span>
                    <span className="dotPage activeDot">3</span>
                </div>
            </div>
        )
    }


    return (
        <>
            <ToastContainer />
            <section id="rental" className="rental light-bg">
                <div className="container" data-aos="fade-up">

                    <div className="text-center">
                        {/* <h5 className="sub-title">BOOKINGS</h5> */}
                        <h1>Rent our space online</h1>
                    </div>

                    <div className="row my-3" data-aos="fade-up">

                        {showCircle ? <ShowCircle /> : null}

                        {showResults ? <DisplayDate /> : null}

                        {/* { showData ? <DisplayData /> : null } */}
                        {showData ? <UserForm FormData={FormData} handleInput={handleInput} handleSubmit={handleSubmit} isSuccess={isSuccess} BackData={<BackData />} /> : null}

                        <div className="col-lg-6 rentalImage my-4">
                            <img src="drama.jpg" alt="" className="img-fluid" />
                        </div>

                    </div>
                </div>
            </section>

            <section className="rental-features">

                {/* <img src="./assets/feature.png" className="col-12" /> */}

                <div className="text-center">
                        {/* <h5 className="sub-title">BOOKINGS</h5> */}
                        <h1>Rental Features & Facilities</h1>
                    </div>

                <div className="row px-0 mx-4 px-md-5">
                    <div className="col-6 col-sm-2">
                        <div className="imgsection text-center m-3">
                            <img src="/assets/icons/1.png" alt="" className="col-12" />
                        </div>
                        <div className="text-center ">
                            <strong>
                                5000 SQ FT <br/> SPACE
                            </strong>
                        </div>
                    </div>
                    <div className="col-6 col-sm-2">
                        <div className="imgsection text-center m-3">
                            <img src="/assets/icons/2.png" alt="" className="col-12" />
                        </div>
                        <div className="text-center ">
                            <strong>
                                WIFI <br /> SERVICE
                            </strong>
                        </div>
                    </div>
                    <div className="col-6 col-sm-2">
                        <div className="imgsection text-center m-3">
                            <img src="/assets/icons/3.png" alt="" className="col-12" />
                        </div>
                        <div className="text-center ">
                            <strong>
                                SEATING <br /> ARRANGMENT
                            </strong>
                        </div>
                    </div>

                    <div className="col-6 col-sm-2">
                        <div className="imgsection text-center m-3">
                            <img src="/assets/icons/4.png" alt="" className="col-12" />
                        </div>
                        <div className="text-center ">
                            <strong>
                                PROJECTOR <br /> & SCREEN
                            </strong>
                        </div>
                    </div>
                    
                    <div className="col-6 col-sm-2">
                        <div className="imgsection text-center m-3">
                            <img src="/assets/icons/5.png" alt="" className="col-12" />
                        </div>
                        <div className="text-center ">
                            <strong>
                                DRINKING <br /> WATER
                            </strong>
                        </div>
                    </div>
                    <div className="col-6 col-sm-2">
                        <div className="imgsection text-center m-3">
                            <img src="/assets/icons/6.png" alt="" className="col-12" />
                        </div>
                        <div className="text-center ">
                            <strong>
                                AIR <br /> CONDITIONER
                            </strong>
                        </div>
                    </div>

                </div>


                {/* <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="text-center" data-aos="fade-up">
                                <h1>Rental Features</h1>
                            </div>

                            <div className="row" data-aos="fade-up" style={{ justifyContent: 'center' }}>
                                <div className="col-md-4 feature-block mx-3 my-3">
                                    <h5>Management</h5>
                                    <p >
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </p>
                                </div>

                                <div className="col-md-4 feature-block mx-3 my-3">
                                    <h5>Strategy</h5>
                                    <p >Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-5">
                            <div className="row" data-aos="fade-up" style={{ justifyContent: 'center' }}>
                                <div className="text-center" data-aos="fade-up">
                                    <h1>Rental Facilities</h1>
                                </div>

                                <div className="tab-pane">
                                    <ul className="facility my-3">
                                        <li><i className="bi bi-record-circle"></i>&nbsp;Theatre/Play</li>
                                        <li><i className="bi bi-record-circle"></i>&nbsp;Poetry Nights</li>
                                        <li><i className="bi bi-record-circle"></i>&nbsp;Open Mic</li>
                                        <li><i className="bi bi-record-circle"></i>&nbsp;Talk/Lecture Series</li>
                                        <li><i className="bi bi-record-circle"></i>&nbsp;Share Circles</li>
                                        <li><i className="bi bi-record-circle"></i>&nbsp;Exhibitions</li>
                                        <li><i className="bi bi-record-circle"></i>&nbsp;Art Gallery</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>

            <section className="light-bg">
                <CategoryImg type="rental" />
            </section>
        </>
    )
}

export default Rental;