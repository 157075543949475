import React, { useState, useEffect } from 'react';
import UpcomingEvents from '../home/upcomingEvents';

import AllEvents from '../AdminEvents/allevents';
import RecentEvents from '../AdminEvents/recentevents';


import EventSkenton from '../AdminEvents/EventSkelton';

import api from '../../API/api';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';

import CategoryImg from '../gallery/categoryImg';


const Event = () => {


    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [month, setMonth] = useState([]);



    useEffect(() => {
        getEvents();

    }, [loading]);

    const getEvents = () => {
        api.getEvents()
            .then((res) => {
                console.log('all events', res);
                setEvents(res.data.events);
                setLoading(false);
                getUniqueMonths(events);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getUniqueMonths = (events) => {
        const uniqueMonths = new Set();
        events.forEach(data => {

            var a = new Date(data.date).toLocaleString('en-us', { month: 'long', year: 'numeric', timeZone: 'UTC' });
            uniqueMonths.add(a)
        });

        console.log("uniqueeeee", uniqueMonths);

        var uni = Array.from(new Set(uniqueMonths));
        console.log("aaaa==>", uni);
        setMonth(uni);

    }


    return (
        <>
            {
                loading ? <EventSkenton /> :
                    <>
                        <div className='container p-1'>
                            <div className="text-center my-3 pt-4">
                                {/* <h5 className='sub-title my-2'>EVENTS AT AGORA</h5> */}
                                <h1> All Events </h1>
                            </div >

                            <div className='row'>
                                <div className='col-md-9'>
                                    <AllEvents events={events} />
                                </div>
                                <div className='col-md-3'>
                                    <RecentEvents events={events} />

                                    <div className='py-2'>


                                        {/* {month.map((data) => (
                                            <button key={data.id} className='btn btn-outline-secondary btn-block w-100 my-1'>

                                                {data
                                                },
                                                

                                            </button>
                                        ))} */}

                                    </div>
                                </div>
                            </div>


                        </div >

                        {/* <UpcomingEvents /> */}

                        <section className='bg-custom-light'>
                            <CategoryImg type="event" />
                        </section>
                    </>
            }
        </>
    )
}

export default Event;